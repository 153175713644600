import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { f7 } from 'framework7-react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import close_icon01 from '../images/close_icon01.png';
import logo from '../images/logo.png';
import login_link_icon_about from '../images/login_link_icon_about.png';
import facebookLogo from '../images/f_logo_RGB-White_58.png';

import { confirm, fetchPost, getLang, handleFetchError, L, passwordScore } from '../utils';
import { store } from '../Store';
import { handleSignIn, handleSignUp } from '../Services';
import { FACEBOOK_APP_ID, LANGUAGES, LANGUAGE_NAMES } from '../const';

export default function SignPage() {
    const loc = useLocation();
    const history = useHistory();
    const { state, dispatch } = useContext(store);
    const [tab, setTab] = useState('signin');
    const [signupInfo, setSignupInfo] = useState();

    useEffect(() => {
        const query = f7.utils.parseUrlQuery(loc.search);
        if (query && query.result) {
            const result = JSON.parse(query.result);
            let { nonce, tab } = query;

            if (nonce === localStorage['3rdparty-nonce']) {
                if (result.result === 'USER_INACTIVE') {
                    alert(L('Your account is currently inactive'));
                } else if (result.result === 'USER_NOT_FOUND') {
                    alert(L('Now you can proceed to sign up to OYoung'));
                    tab = 'signup';
                    setSignupInfo(result);
                } else if (result.result === 'LOGIN_OK') {
                    f7.preloader.show();

                    handleSignIn(
                        '3RDPARTY',
                        result.provider,
                        true,
                        query.redirect || '/home',
                        dispatch,
                        history,
                    ).then(() => {
                        f7.preloader.hide();
                    });
                } else {
                    if (result.errors && result.errors.length)
                        alert(L(result.errors[0]));
                }
            }

            setTab(tab);
        }
    }, [dispatch, history, loc.search]);

    const signIn = (e) => {
        e.preventDefault();

        let f = document.signInForm;
        if (!f.checkValidity()) return;

        f7.preloader.show();
        const params = f7.utils.parseUrlQuery(loc.search);

        handleSignIn(
            f.loginName.value,
            f.password.value,
            state.rememberLogin,
            params.redirect || '/home',
            dispatch,
            history,
        ).then(() => {
            f7.preloader.hide();
        });

        return false;
    };

    const resetPassword = () => {
        f7.dialog.create({
            title: L('Change Password'),
            text: L('We will send a temporary password to your e-mail or phone.'),
            content: '<input id="txtResetEmail" type="text" class="inpt02 inpt_full" placeholder="'
                + L('Login name / E-mail') + '">',
            buttons: [
                {
                    text: L('Cancel'),
                    keyCodes: [27],
                },
                {
                    text: L('Ok'),
                    bold: true,
                    onClick: doResetPassword,
                    keyCodes: [13],
                },
            ],
            destroyOnClose: true,
        }).open();

    };

    const doResetPassword = () => {
        const loginName = $('#txtResetEmail').val().trim();
        if (!loginName) {
            alert(L('Enter your email or login name.'));
            return;
        }

        confirm(L('Continue to reset your password?'), () => {
            f7.preloader.show();

            let formData = new FormData();
            formData.append("loginName", loginName);
            formData.append("lang", getLang());

            return fetchPost('/guest/resetPassword.do', formData)
                .then((success) => {
                    f7.preloader.hide();
                    if (success) {
                        $('#txtResetEmail').val('');
                        alert(L('We sent you a notification. Please check your e-mail or SMS.'));
                    } else {
                        alert(L("Something's wrong. Please check your login name."));
                    }
                })
                .catch(handleFetchError);
        });
    };

    const handle3rdParty = (provider, tab) => {
        const nonce = [...new Array(30)].map(() => Math.floor(Math.random() * 10)).join('');
        localStorage['3rdparty-nonce'] = nonce;
        const state = encodeURIComponent(`tab=${tab}&nonce=${nonce}`);

        if (provider === 'facebook') {
            f7.preloader.show();

            const server = global.location.origin;
            const redirect = server + '/guest/facebookLogin.html';
            const url = `https://www.facebook.com/v9.0/dialog/oauth?client_id=${FACEBOOK_APP_ID}&redirect_uri=${redirect}&state=${state
                }&scope=public_profile,email`;

            global.location.href = url;

        } else if (provider === 'apple') {
            // 문서 - https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js
            // 관리자 - https://developer.apple.com/account/resources/identifiers/list/serviceId

            new Promise((resolve) => {
                if (global.AppleID) {
                    resolve();
                    return;
                }

                f7.preloader.show();

                const s = global.document.createElement('script');
                s.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
                global.document.body.appendChild(s);

                const h = setInterval(() => {
                    if (global.AppleID) {
                        clearInterval(h);
                        global.AppleID.auth.init({
                            clientId: 'kr.co.oyoung.signin',
                            scope: 'name email',
                            redirectURI: global.location.origin + '/guest/appleSignIn.html',
                            state,
                            nonce,
                            usePopup: false,
                        });
                        f7.preloader.hide();
                        resolve();
                    }
                }, 100);
            }).then(() => {
                return global.AppleID.auth.signIn();
            }).catch(handleFetchError);
        }
    }

    const handlePassword = e => {
        const score = passwordScore(e.target.value);
        let msg = score < 3 ? L("password-help") : '';
        e.target.setCustomValidity(msg);
        f7.input.validate(e.target);
    };

    const handlePassword2 = e => {
        let val = e.target.value;
        let msg = '';
        if (val !== document.signUpForm.loginPassword.value) {
            msg = L('Enter the same password twice');
        }
        e.target.setCustomValidity(msg);
        f7.input.validate(e.target);
    };

    const signUp = (e) => {
        e.preventDefault();

        if (!document.signUpForm.checkValidity())
            return;

        confirm(L("Do you confirm your sign up to O'YOUNG?"), () => {
            f7.preloader.show();

            const f = document.signUpForm;
            const data = {};

            Array.prototype.forEach.call(f.elements, e => {
                if (e.name) {
                    e.value = e.value.trim();
                    if ((e.type === 'checkbox' && e.checked) || (e.type !== 'checkbox' && e.name)) {
                        data[e.name] = e.value;
                    }
                }
            });
            if (f.mobilePhone2.value)
                data.mobilePhone = f.mobilePhone1.value + '-' + f.mobilePhone2.value.trim();
            console.log(data);

            handleSignUp(data, dispatch, history).then(success => {
                f7.preloader.hide();
                if (success) {
                    f.reset();
                    setTab('signin');
                    setSignupInfo(null);
                }
            });
        });
    }

    return (
        <div id="container">

            {/* <!--Contents {--> */}
            <div id="login" className="sub">

                <div className="login_wrap">
                    <a href="#back" className="login_close" onClick={() => history.goBack()}>
                        <img src={close_icon01} alt="Login Close" />
                    </a>
                    <div className="login_top">
                        <div className="wrapper">
                            <div className="login_logo"><img src={logo} alt="O'YOUNG LOGO" /></div>
                            <p className="login_top_text">{L('Free beauty news and talk to doctors from Seoul. Safe and private. SIGN UP NOW!')}</p>
                        </div>
                    </div>

                    <div className="menu_2dp login_nav">
                        <div className="wrapper">
                            <ul className="i-col-2 menu_2dp_ul">
                                <li className={tab === 'signin' ? 'active' : ''}><a href="#signin" onClick={() => setTab('signin')}>{L('Sign In')}</a></li>
                                <li className={tab === 'signup' ? 'active' : ''}><a href="#signup" onClick={() => setTab('signup')}>{L('Sign Up')}</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className={`xtab ${tab === 'signin' ? 'visible' : ''}`}>
                        <div className="login_form_wrap">
                            <div className="wrapper">
                                <form name="signInForm" id="login_form" method="post">
                                    <div className="login_form_wbox item-input">
                                        <label htmlFor="loginName" className="sound_only">{L('Login name or E-mail')}</label>
                                        <input
                                            type="text"
                                            name="loginName"
                                            id="loginName"
                                            className="inpt02 inpt_full"
                                            placeholder={L('Login name or E-mail')}
                                            required
                                            validate="true"
                                        />
                                    </div>
                                    <div className="password_view_box login_form_wbox item-input">
                                        <label htmlFor="password" className="sound_only">{L('Password')}</label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            className="inpt02 inpt_view inpt_full"
                                            placeholder={L('Password')}
                                            autoComplete="new-password"
                                            required
                                            validate="true"
                                            onInput={(e) => $('.inpt_view_btn')[e.target.value ? 'show' : 'hide']()}
                                        />
                                        <span
                                            className="inpt_view_btn"
                                            style={{ display: 'none', top: '25px'  }}
                                            onClick={() => {
                                                const e = document.signInForm.password;
                                                e.type = e.type === 'password' ? 'text' : 'password'
                                            }}
                                        />
                                    </div>
                                    <div className="login_form_op">
                                        <ul className="cf login_form_op_ul">
                                            <li className="login_form_op_save">
                                                <div className="i-col-0 login_form_op_save_chkbox">
                                                    <input
                                                        type="checkbox"
                                                        name="login_form_op_save_chk"
                                                        id="login_form_op_save_chk"
                                                        checked={state.rememberLogin}
                                                        onChange={(e) => dispatch({
                                                            type: 'SET_REMEMBER_LOGIN',
                                                            payload: e.target.checked,
                                                        })}
                                                    />
                                                    <label htmlFor="login_form_op_save_chk">{L('Remember me')}</label>
                                                </div>
                                            </li>
                                            <li className="login_form_op_find">
                                                <a href="#resetPassword" className="login_form_op_find_btn"
                                                    onClick={resetPassword}>
                                                    {L('Forgot password?')}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="login_form_submitbox">
                                        <button type="submit" id="login_form_submit" className="btn01" onClick={signIn}>
                                            {L('Sign In')}
                                        </button>
                                    </div>
                                </form>

                                <div className="login_linkbox">
                                    <ul className="login_link_ul">
                                        <li className="login_link_facebook">
                                            <a href="#loginWithFacebook" className="btn01" onClick={() => handle3rdParty('facebook', 'signin')}>
                                                <span className="login_link_icon"><img src={facebookLogo} alt={L('facebook')} /></span>
                                                <span className="login_link_text">{L('Login With Facebook')}</span>
                                            </a>
                                        </li>
                                        <li className="login_link_apple" style={{ display: f7.device.ios ? '' : 'none' }}>
                                            <a href="#loginWithApple" className="btn01" onClick={() => handle3rdParty('apple', 'signin')}>
                                                <span className="login_link_icon" style={{ top: '60%' }}>
                                                    <svg className="logo" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 456.008 560.035">
                                                        <path fill="white" d="M380.844 297.529c.787 84.752 74.349 112.955 75.164 113.314-.622 1.988-11.754 40.191-38.756 79.652-23.343 34.117-47.568 68.107-85.731 68.811-37.499.691-49.557-22.236-92.429-22.236-42.859 0-56.256 21.533-91.753 22.928-36.837 1.395-64.889-36.891-88.424-70.883-48.093-69.53-84.846-196.475-35.496-282.165 24.516-42.554 68.328-69.501 115.882-70.192 36.173-.69 70.315 24.336 92.429 24.336 22.1 0 63.59-30.096 107.208-25.676 18.26.76 69.517 7.376 102.429 55.552-2.652 1.644-61.159 35.704-60.523 106.559M310.369 89.418C329.926 65.745 343.089 32.79 339.498 0 311.308 1.133 277.22 18.785 257 42.445c-18.121 20.952-33.991 54.487-29.709 86.628 31.421 2.431 63.52-15.967 83.078-39.655" />
                                                    </svg>
                                                </span>
                                                <span className="login_link_text">{L('Sign in with Apple')}</span>
                                            </a>
                                        </li>
                                        <li className="login_link_about">
                                            <Link to="/about" className="btn01 f7-ignore">
                                                <span className="login_link_icon"><img src={login_link_icon_about} alt={L('About O\'Young')} /></span>
                                                <span className="login_link_text">{L('About O\'Young')}</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`signup-tab xtab ${tab === 'signup' ? 'visible' : ''}`}>
                        <div className="login_form_wrap">
                            <div className="wrapper" style={{ display: signupInfo ? 'none' : '' }}>
                                <div className="login_linkbox">
                                    <ul className="login_link_ul">
                                        <li className="login_link_facebook">
                                            <a href="#loginWithFacebook" className="btn01" onClick={() => handle3rdParty('facebook', 'signup')}>
                                                <span className="login_link_icon"><img src={facebookLogo} alt={L('facebook')} /></span>
                                                <span className="login_link_text">{L('Sign up with Facebook')}</span>
                                            </a>
                                        </li>
                                        <li className="login_link_apple" style={{ display: f7.device.ios ? '' : 'none' }}>
                                            <a href="#loginWithApple" className="btn01" onClick={() => handle3rdParty('apple', 'signup')}>
                                                <span className="login_link_icon" style={{ top: '60%' }}>
                                                    <svg className="logo" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 456.008 560.035">
                                                        <path fill="white" d="M380.844 297.529c.787 84.752 74.349 112.955 75.164 113.314-.622 1.988-11.754 40.191-38.756 79.652-23.343 34.117-47.568 68.107-85.731 68.811-37.499.691-49.557-22.236-92.429-22.236-42.859 0-56.256 21.533-91.753 22.928-36.837 1.395-64.889-36.891-88.424-70.883-48.093-69.53-84.846-196.475-35.496-282.165 24.516-42.554 68.328-69.501 115.882-70.192 36.173-.69 70.315 24.336 92.429 24.336 22.1 0 63.59-30.096 107.208-25.676 18.26.76 69.517 7.376 102.429 55.552-2.652 1.644-61.159 35.704-60.523 106.559M310.369 89.418C329.926 65.745 343.089 32.79 339.498 0 311.308 1.133 277.22 18.785 257 42.445c-18.121 20.952-33.991 54.487-29.709 86.628 31.421 2.431 63.52-15.967 83.078-39.655" />
                                                    </svg>
                                                </span>
                                                <span className="login_link_text">{L('Sign up with Apple')}</span>
                                            </a>
                                        </li>
                                        <li className="login_link_about">
                                            <a href="#emailSignup" className="btn01" onClick={() => setSignupInfo({ type: 'email' })}>
                                                <span className="login_link_icon"><img src={login_link_icon_about} alt={L('OYoung')} /></span>
                                                <span className="login_link_text">{L('Sign up with e-mail')}</span>
                                            </a>
                                        </li>
                                        <li className="login_link_about">
                                            <a href="#phoneSignup" className="btn01" onClick={() => setSignupInfo({ type: 'other' })}>
                                                <span className="login_link_icon"><img src={login_link_icon_about} alt={L('OYoung')} /></span>
                                                <span className="login_link_text">{L('Sign up with phone number')}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <form
                                name="signUpForm"
                                method="post"
                                style={{ display: signupInfo ? '' : 'none' }}
                            >
                                <input name="thirdParty" type="hidden" value={signupInfo && signupInfo.provider} />
                                <input name="thirdPartyId" type="hidden" value={signupInfo && signupInfo.id} />
                                <input name="thirdPartyToken" type="hidden" />
                                <input name="userName" type="hidden" value={signupInfo && signupInfo.name} />


                                <div className="wBox">
                                    <div className="wrapper">
                                        <label htmlFor="pf_w_language" className="wBox_title">{L('Language')}</label>
                                        <select
                                            name="language"
                                            id="pf_w_language"
                                            className="selectbox selectbox_full"
                                            value={getLang()}
                                            onChange={(e) => {
                                                const lang = e.target.value;
                                                dispatch({ type: 'SET_LANGUAGE', payload: lang });
                                            }}
                                        >
                                            {LANGUAGES.map((l, idx) => (
                                                <option key={l} value={l}>{LANGUAGE_NAMES[idx]}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="wBox" style={{ display: signupInfo && signupInfo.type === 'other' ? '' : 'none' }}>
                                    <div className="wrapper item-input">
                                        <label htmlFor="pf_w_phone1" className="wBox_title">{L('Phone number')}</label>
                                        <select
                                            name="mobilePhone1"
                                            id="pf_w_phone1"
                                            className="selectbox selectbox_full"
                                        >
                                            <option value="+82">{L('Korea')} +82</option>
                                            <option value="+84">{L('Vietnam')} +84</option>
                                            <option value="+65">{L('Singapore')} +65</option>
                                            <option value="+61">{L('Australia')} +61</option>
                                            <option value="+1">{L('United States')} +1</option>
                                        </select>
                                        <input type="tel" name="mobilePhone2" id="pf_w_phone2"
                                            className="inpt02 inpt_full" placeholder="Phone number"
                                            required={signupInfo && signupInfo.type === 'other'}
                                            validate="true"
                                            minLength="9" maxLength="14"
                                            pattern="[0-9\-]{9,14}"
                                            onBlur={(e) => {
                                                const v = e.target.value.replace(/^[^1-9]+/, '').trim();
                                                e.target.value = v;
                                            }}
                                        />
                                        <p className="profile_wbox_text01">{L('Example: 99-1234567, 888-1234-5678')}</p>
                                    </div>
                                </div>
                                <div className="wBox">
                                    <div className="wrapper item-input">
                                        <label htmlFor="txtLoginName" className="wBox_title">
                                            {L(signupInfo && signupInfo.type === 'other' ? 'Unique login name' : 'E-mail')}
                                        </label>
                                        <input
                                            type={signupInfo && signupInfo.type === 'other' ? 'text' : 'email'}
                                            name="loginName"
                                            id="txtLoginName"
                                            className="inpt02 inpt_full"
                                            required validate="true"
                                            defaultValue={signupInfo && signupInfo.email}
                                        />
                                        <p className="profile_wbox_text01">{L('Allowed characters: alphabets, digits, @, -, _ and .')}</p>
                                    </div>
                                </div>
                                <div className="wBox">
                                    <div className="wrapper item-input">
                                        <label htmlFor="txtPassword" className="wBox_title">{L('Password')}</label>
                                        <input
                                            type="password"
                                            name="loginPassword"
                                            id="txtPassword"
                                            className="inpt02 inpt_full"
                                            required validate="true"
                                            autoComplete="new-password"
                                            onInput={handlePassword} 
                                        />
                                    </div>
                                </div>
                                <div className="wBox">
                                    <div className="wrapper item-input">
                                        <label htmlFor="txtPassword2" className="wBox_title">{L('Verify password')}</label>
                                        <input
                                            type="password"
                                            id="txtPassword2"
                                            className="inpt02 inpt_full"
                                            required validate="true"
                                            autoComplete="new-password"
                                            onInput={handlePassword2}
                                        />
                                    </div>
                                </div>
                                <div className="wBox">
                                    <div className="wrapper item-input">
                                        <label htmlFor="txtReferrer" className="wBox_title">{L("Referrer ID")} ({L("If any")})</label>
                                        <input
                                            type="text"
                                            name="referrer"
                                            id="txtReferrer"
                                            className="inpt02 inpt_full"
                                            maxLength="60"
                                        />
                                    </div>
                                </div>

                                <div className="profile_form_btn">
                                    <div className="wrapper">
                                        <button type="submit" className="btn01 profile_form_submit_btn" onClick={signUp}>{L('Sign Up')}</button>
                                        <a href="#cancel" className="btn01_w profile_form_cancel_btn" onClick={() => setSignupInfo(undefined)}>{L('Other sign-up methods')}</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
            {/* <!--} Contents--> */}
        </div>
    );
}
