import { useContext, useEffect, useState } from 'react';
import { store } from '../Store';
import { f7 } from 'framework7-react';

import search_icon02 from '../images/search_icon02.png';
import delete_icon02 from '../images/delete_icon02.png';

import Footer from "../components/Footer";
import Header from "../components/Header";
import { fetchGet, formatDate, L, storage } from "../utils";
import { CATEGORIES, HOT_KEYWORD_LIST } from '../const';
import { allSearchAl } from '../components/allSearch';
import PartnerList from '../components/PartnerList';
import DoctorCard from '../components/DoctorCard';

export default function SearchPage() {
    const { state, dispatch } = useContext(store);
    const [ searchTarget, setSearchTarget ] = useState();
    const [ needKeyword, setNeedKeyword ] = useState(true);
    const [ searchList, setSearchList ] = useState([]);
    const [ historyKeywords, setHistoryKeywords ] = useState([]);

    useEffect(() => {
        if (!state.partners.length) {
            f7.preloader.show();
            fetchGet('/api/partners').then((result) => {
                result.forEach((c) => {
                    c.categories = c.categories ? c.categories.split(';') : [];
                    c.specialties = c.specialties ? c.specialties.split(';') : [];
                });
                dispatch({type: 'SET_PARTNERS', payload: result});
            });
        }
    }, [dispatch, state.partners]);

    useEffect(() => {
        if (!state.consultants.length || !state.partners.length) return;

        const arr = [...state.partners, ...state.consultants];
        arr.forEach(i => {
            if (i.userType === 'partner') {
                i._search = [i.userName, i.englishName, i.categories.map(c => L(c)).join(' '), i.i18nInfo].join(' ').toLowerCase();
            } else {
                i._search = [
                    i.userName,
                    i.englishName,
                    L(i.field),
                    i.categories.map(c => L(c)).join(' '),
                    i.specialties.map(s => L(s)).join(' '),
                    i.i18nProfile,
                ].join(' ').toLowerCase();
            }
        });

        arr.sort((a, b) => {
            if (a._search < b._search) return -1;
            if (a._search > b._search) return 1;
            return 0;
        });

        setSearchTarget(arr);
        storage("searchHistory").then(val => setHistoryKeywords(val || []));

        f7.preloader.hide();

    }, [state.consultants, state.partners]);

    const checkSearchInputbox = (query, type) => {
        if (!query) setNeedKeyword(true);
        if (type === "favorite") setNeedKeyword(false);
    };

    const doSearch = () => {
        const keyword = document.querySelector('#txtAllSearchKeyword').value.trim();
        if (keyword.length < 2) {
            alert(L('Enter at least two letters to search.'));
            return;
        }

        allSearch();
    };

    const allSearch = () => {
        let query = document.querySelector("#txtAllSearchKeyword").value.trim();
        let type = document.querySelector("#tot_sch_select").value.toLowerCase();

        setNeedKeyword(false);
        onSaveHistory();
        checkSearchInputbox(query, type);

        setSearchList(allSearchAl(
            query,
            type,
            searchTarget,
            state.consultantLikes
        ));
    };

    const onSaveHistory = () => {
        let searchedKeyword = document.querySelector("#txtAllSearchKeyword")
            .value.trim();

        var idx = historyKeywords.findIndex(k => k.keyword.toLowerCase() === searchedKeyword.toLocaleLowerCase());
        if (idx >= 0)
            historyKeywords.splice(idx, 1);

        if (searchedKeyword.length > 0) {
            let keywordTemp = {};
            keywordTemp.keyword = searchedKeyword;
            keywordTemp.date = formatDate(new Date());
            historyKeywords.unshift(keywordTemp);

            storage("searchHistory", historyKeywords);
        }
        setHistoryKeywords([...historyKeywords]);
    };

    const onClickChip = (selectedChip) => {
        document.querySelector("#txtAllSearchKeyword").value = selectedChip;
        doSearch();
    };

    const removeHistory = e => {
        let targetKeyword = e.target.parentElement.children[0].innerHTML;
        let indexTarget = historyKeywords.findIndex(
            k => k.keyword === targetKeyword
        );

        if (indexTarget !== -1) {
            historyKeywords.splice(indexTarget, 1);
            storage("searchHistory", historyKeywords);
        }

        setHistoryKeywords([...historyKeywords]);
    };

    const removeHistoryAll = () => {
        setHistoryKeywords([]);
        storage("searchHistory", []);
    };

    return (
        <div id="container" className="gray">

            <Header />

            <div id="search" className="sub">

                <div className="tot_sch_wrap">
                    <div className="wrapper">
                        <form action="" name="tot_sch_f" id="tot_sch_f" className="i-col-0" onSubmit={e => {e.preventDefault(); return false;}}>
                            <select name="" id="tot_sch_select" className="selectbox tot_sch_select">
                                <option value="All">{L('All')}</option>
                                <option value="Partner">{L('Clinic')}</option>
                                <option value="Consultant">{L('Consultant')}</option>
                            </select>
                            <div className="search_inner tot_sch_inner">
                                <input
                                    id="txtAllSearchKeyword"
                                    type="text"
                                    className="inpt01 inpt_full search_inpt"
                                    placeholder={L('Search')}
                                    onKeyDown={e => {if ((e.code || e.keyCode) === 13) doSearch()}}
                                />
                                <button className="search_btn talk_sch_btn" onClick={doSearch}><img src={search_icon02} alt="magnifier" /></button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="tot_sch_result">
                    <div className="wrapper">
                        {needKeyword ? (
                            <AllSearch
                                onClickChip={onClickChip}
                                onClickX={removeHistory}
                                onClickDeleteAll={removeHistoryAll}
                                historyKeywords={historyKeywords}
                            />
                        ) : (
                            <SearchListView
                                sList={searchList}
                                consultantLikes={state.consultantLikes}
                                setNeedKeyword={setNeedKeyword}
                            />
                        )}
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

function AllSearch({
    onClickChip,
    onClickX,
    onClickDeleteAll,
    historyKeywords,
}) {
    return (
        <ul className="tot_sch_result_ul">
            <li>
                <p className="tot_sch_result_title">{L('Hot keyword')}</p>
                <div className="tot_sch_result_data tot_sch_result_keyword">
                    <ul className="i-col-0 keyword-b">
                        {HOT_KEYWORD_LIST.map(keyword => (
                            <li key={keyword}>
                                <a href="#keyword" onClick={() => onClickChip(keyword)}>{L(keyword)}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </li>
            <li>
                <p className="tot_sch_result_title">{L('By category')}</p>
                <div className="tot_sch_result_data tot_sch_result_category">
                    <ul className="i-col-0 keyword-b">
                        {CATEGORIES.map(category => (
                            <li key={category}>
                                <a href="#category" onClick={() => onClickChip(category)}>{L(category)}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </li>
            {historyKeywords.length > 0 && (
                <li>
                    <p className="tot_sch_result_title">{L('Search History')}</p>
                    <div className="tot_sch_result_data tot_sch_result_history">
                        <ul className="i-col-0 keyword-b">
                            {historyKeywords.map((k, i) => (
                                <li key={k.keyword}>
                                    <div>
                                        <span onClick={() => onClickChip(k.keyword)}>{k.keyword}</span>
                                        <button className="tot_sch_result_history_delete" onClick={onClickX}>
                                            <img src={delete_icon02} alt="Search History Delete" />
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        {historyKeywords.length > 0 && (
                            <button className="tot_sch_result_history_all_delete" onClick={onClickDeleteAll}>{L('All Search Delete')}</button>
                        )}
                    </div>
                </li>
            )}
        </ul>

    );
}

function SearchListView({
    sList,
    consultantLikes,
    setNeedKeyword,
}) {
    let viewList = sList.reduce((object, cur) => {
        // init
        if (!object.clinic) {
            object.clinic = [];
        }
        if (!object.doctor) {
            object.doctor = [];
        }
        if (!object.trend) {
            object.trend = [];
        }

        // 다시 분류
        if (cur.userType === 'partner')
            object.clinic.push(cur);
        else if (cur.userName)
            object.doctor.push(cur);
        else
            object.trend.push(cur);
        return object;
    }, {});

    let clinicList, doctorList;

    if (viewList.clinic) {
        clinicList = (
            <div className="clinic_list_wrap">
                <PartnerList partners={viewList.clinic} />
            </div>
        );
    }

    if (viewList.doctor) {
        doctorList = (
            <div className="doctor_list_wrap">
                {viewList.doctor.map((d) => <DoctorCard key={d.id} doctor={d} consultantLikes={consultantLikes} />)}
            </div>
        );
    }

    const clear = () => {
        document.querySelector('#txtAllSearchKeyword').value = '';
        setNeedKeyword(true);
    };

    const clearButton = (
        <button type="button" className="btn02" onClick={clear} style={{background: 'white', marginLeft: '1em', height: '40px'}}>
            {L('Clear')}
        </button>
    );

    return (
        <div className="list">
            {sList.length === 0 ? (
                <div
                    className="block text-align-center"
                    style={{ color: "red" }}
                >
                    {L("We couldn't find any results for your search.")}
                    {clearButton}
                </div>
            ) : (
                <div className="text-align-center">
                    {/* <div className="block">Your search {sList.noMatchKeyword} did not match any result</div> */}
                    {L("Total of ")} {sList.length} {L(" result for ")}
                    <span style={{ fontWeight: "bolder" }}>
                        {L(sList.keyword)}
                    </span>
                    {clearButton}
                </div>
            )}

            {viewList.doctor && viewList.doctor.length > 0 && (
                <div>
                    <h3 style={{margin: '20px 0 12px 1em'}}>{L('Consultants')} ({viewList.doctor.length})</h3>
                    <div className="D_doctors_div">
                        {doctorList}
                    </div>
                </div>
            )}
            {viewList.clinic && viewList.clinic.length > 0 && (
                <div>
                    <h3 style={{margin: '20px 0 12px 1em'}}>{L('Clinics')} ({viewList.clinic.length})</h3>
                    <div className="D_clinic_list">
                        {clinicList}
                    </div>
                </div>
            )}
        </div>
    );
}
