import { useContext, useEffect, useState } from "react";
import { f7 } from "framework7-react";

import talk_chat_msg_receive_img from '../images/talk_chat_msg_receive_img.png';
import camera_icon01 from '../images/camera_icon01.png';

import Footer from "../components/Footer";
import { store } from "../Store";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { confirm, fetchGet, fetchPost, getLang, handleFetchError, L } from "../utils";
import { useHistory, useLocation } from "react-router";
import { CATEGORIES } from "../const";
import PictureForm from "../components/PictureForm";

export default function ReviewWritePage() {
    const { state } = useContext(store);
    const loc = useLocation();
    const history = useHistory();
    const [review, setReview] = useState({ categories: [] });
    const [clinics, setClinics] = useState([]);
    const [consultants, setConsultants] = useState([]);

    useEffect(() => {
        fetchGet('/api/partners?type=CLINIC').then((clinics) => {
            const sorter = getLang() === 'ko'
                ? (a, b) => a.userName < b.userName ? -1 : (a.userName > b.userName ? 1 : 0)
                : (a, b) => a.englishName < b.englishName ? -1 : (a.englishName > b.englishName ? 1 : 0)
            clinics.sort(sorter);
            setClinics(clinics.filter((c) => c.doctorsCount > 0));
        });
    }, [state.user])

    useEffect(() => {
        const params = f7.utils.parseUrlQuery(loc.search);
        const id = params.id;
        if (id) {
            fetchGet('/api/customerReviews/' + id).then((review) => {
                review.categories = review.categories ? review.categories.split(';') : [];
                const doctor = state.consultants.find((c) => c.id === review.consultantId);
                review.partnerId = doctor.partnerId;

                setReview(review);
                setConsultants(state.consultants.filter((c) => doctor && c.partnerId === doctor.partnerId));
            });
        }

    }, [loc.search, state.consultants]);

    if (!state.user) return <div id="container"/>;

    const save = (e) => {
        e.preventDefault();

        if (!document.reviewForm.checkValidity())
            return false;

        confirm(L('Save your review?'), () => {
            f7.preloader.show();

            let formData = new FormData();
            if (review.id)
                formData.append('id', review.id);
            formData.append('categories', review.categories.join(';'));
            formData.append('subject', review.subject);
            formData.append('content', review.content);
            if (review.surgeryYmd)
                formData.append('surgeryYmd', review.surgeryYmd);
            formData.append('consultantId', review.consultantId);
            review.photos && review.photos.forEach((p) => {
                formData.append('photo', p.file || p.id, p.name);
            });

            fetchPost('/customer/customerReviewSave.do', formData)
                .then(result => {
                    if (result.errors)
                        throw result;

                    f7.preloader.hide();
                    history.push('/reviews/' + result.review.id);
                })
                .catch(handleFetchError);
        });

        return false;
    }

    const deleteReview = (e) => {
        e.preventDefault();

        confirm(L('Delete your review?'), () => {
            let formData = new FormData();
            formData.append('id', review.id);

            fetchPost('/customer/customerReviewDelete.do', formData)
                .then(result => {
                    if (result.errors)
                        throw result;

                    history.push('/reviews');
                })
                .catch(handleFetchError);
        });
    };

    return (
        <div id="container">
            <Header />

            <div id="review_write" className="sub">
                <Navbar title={L(review.id ? 'Edit Review' : 'Write Review')} />

                <div className="write_box riview_write_box">
                    <form name="reviewForm" method="post">
                        <div className="wBox">
                            <div className="wrapper">
                                <div className="i-col-0 writer_inf">
                                    <div className="rv_w_writer_img"><img src={talk_chat_msg_receive_img} alt="" /></div>
                                    <p className="rv_w_writer_name">{review.customerName || state.user.userName}</p>
                                </div>
                            </div>
                        </div>
                        <div className="wBox">
                            <div className="wrapper item-input">
                                <label htmlFor="rv_w_category" className="wBox_title">{L('Categories')}</label>
                                <select
                                    name="rv_w_category"
                                    id="rv_w_category"
                                    className="selectbox selectbox_full"
                                    required={!review.categories.length}
                                    validate="true"
                                    onChange={(e) => {
                                        if (review.categories.indexOf(e.target.value) < 0) {
                                            review.categories.push(e.target.value);
                                            setReview({...review});
                                        }
                                        e.target.value = '';
                                    }}
                                >
                                    <option value="">{L('Select')}</option>
                                    {CATEGORIES.map((c) => (
                                        <option key={c} value={c}>{L(c)}</option>
                                    ))}
                                </select>
                                <ul className="i-col-0 keyword-b rv_w_keyword">
                                    {review.categories.map((c, idx) => (
                                        <li key={c} onClick={() => {
                                            review.categories.splice(idx, 1);
                                            setReview({...review});
                                        }}>
                                            <span>{L(c)}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="wBox">
                            <div className="wrapper item-input">
                                <label htmlFor="rv_w_subj" className="wBox_title">{L('Subject')}</label>
                                <input
                                    type="text"
                                    name="rv_w_subj"
                                    id="rv_w_subj"
                                    className="inpt02 inpt_full"
                                    required
                                    validate="true"
                                    maxLength="70"
                                    value={review.subject || ''}
                                    onChange={(e) => {
                                        review.subject = e.target.value;
                                        setReview({...review});
                                    }}
                                />
                            </div>
                        </div>
                        <div className="wBox">
                            <div className="wrapper item-input">
                                <label htmlFor="rv_w_content" className="wBox_title">{L('Review Content')}</label>
                                <div className="textar_length">
                                    <textarea
                                        name="rv_w_content"
                                        id="rv_w_content"
                                        className="textar01 textar_full"
                                        cols="20"
                                        rows="7"
                                        required
                                        validate="true"
                                        value={review.content}
                                        onChange={(e) => {
                                            review.content = e.target.value;
                                            setReview({...review});
                                        }}
                                    />
                                    <span className="textar_length_num">{review.content ? review.content.length : 0}</span>
                                </div>
                            </div>
                        </div>

                        <div className="wBox rv_w_filebox">
                            <div className="wrapper">
                                <p className="wBox_title"><img src={camera_icon01} alt="" />{L('Attach Pictures')}</p>
                                <PictureForm
                                    pictures={review.photos}
                                    setPictures={(pictures) => {
                                        review.photos = pictures;
                                        setReview({...review});
                                    }}
                                />
                            </div>
                        </div>

                        <div className="wBox">
                            <div className="wrapper">
                                <label htmlFor="rv_w_surgeryday" className="wBox_title">{L('Date of Surgery')}</label>
                                <input
                                    name="rv_w_surgeryday"
                                    id="rv_w_surgeryday"
                                    className="inpt02 inpt_full"
                                    type="date"
                                    value={review.surgeryYmd}
                                    onChange={(e) => {
                                        review.surgeryYmd = e.target.value;
                                        setReview({...review});
                                    }}
                                />
                            </div>
                        </div>
                        <div className="wBox">
                            <div className="wrapper item-input">
                                <label htmlFor="rv_w_clinic" className="wBox_title">{L('Clinic')}</label>
                                <select
                                    name="rv_w_clinic"
                                    id="rv_w_clinic"
                                    className="selectbox selectbox_full"
                                    required
                                    validate="true"
                                    value={review.partnerId || ''}
                                    onChange={(e) => {
                                        review.partnerId = e.target.value;
                                        setReview({...review});
                                        setConsultants(state.consultants.filter((c) => c.partnerId === e.target.value));
                                    }}
                                >
                                    <option value="">{L('Select')}</option>
                                    {clinics.map((c) => (
                                        <option key={c.id} value={c.id}>{getLang() === 'ko' ? c.userName : c.englishName}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="wBox">
                            <div className="wrapper item-input">
                                <label htmlFor="rv_w_doctor" className="wBox_title">{L('Doctor')}</label>
                                <select
                                    name="rv_w_doctor"
                                    id="rv_w_doctor"
                                    className="selectbox selectbox_full"
                                    required
                                    validate="true"
                                    value={review.consultantId || ''}
                                    onChange={(e) => {
                                        review.consultantId = e.target.value;
                                        setReview({...review});
                                    }}
                                >
                                    <option value="">{L('Select')}</option>
                                    {consultants.map((d) => (
                                        <option key={d.id} value={d.id}>
                                            {L(d.field)} {getLang() === 'ko' ? d.userName : d.englishName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="rv_w_btnbox">
                            <div className="wrapper">
                                <ul className="i-col-2 rv_w_btn_ul">
                                    {review.id && (
                                        <li><a href="#delete-review" className="btn01_w rv_w_delete" onClick={deleteReview}>{L('Delete')}</a></li>
                                    )}
                                    <li><input type="submit" value={L(review.id ? 'Save' : 'Write')} className="btn01 rv_w_submit" onClick={save} /></li>
                                </ul>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <Footer index="2" />

        </div>
    );
}