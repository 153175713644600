import { useContext, useEffect, useState } from "react";
import $ from 'jquery';

import COUNTRIES from '../data/countries.json';
import AVATAR from '../images/profile_img02.png';

import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { store } from "../Store";
import { confirm, fetchPost, getCurrency, getLang, handleFetchError, L } from "../utils";
import { LANGUAGES, LANGUAGE_NAMES, } from "../const";
import { f7 } from "framework7-react";
import { handleLogout, handleSaveProfile } from "../Services";
import { useHistory } from "react-router";

export default function ProfilePage() {
    const { state, dispatch } = useContext(store);
    const history = useHistory();
    const user = state.user;
    
    let [language, setLanguage] = useState(getLang());
    let [currency, setCurrency] = useState(getCurrency());
    let [countryCode, setCountryCode] = useState();
    let [cities, setCities] = useState([]);
    let [city, setCity] = useState();
    let [phone1, setPhone1] = useState();

    useEffect(() => {
        if (user)
            setLanguage(user.language);
        setCurrency(user ? user.currency : '');
        setCountryCode(user ? user.countryCode : '');
        const country = COUNTRIES.find((c) => user && user.countryCode === c.code);
        setCities(country ? country.cities : []);
        setCity(user ? user.city : '');
        setPhone1(user && user.mobilePhone ? user.mobilePhone.slice(0, user.mobilePhone.indexOf('-')) : '');
    }, [user]);
    
    if (!user) return <div id="container"/>;
    
    const avatar = (user && user.thirdParty === 'facebook')
        ? `https://graph.facebook.com/${user.thirdPartyId}/picture?type=large`
        : AVATAR;

    const save = (e) => {
        e.preventDefault();

        document.profileForm.checkValidity()
            // && checkValidPhone(user)
            && updateProfile(dispatch);

        return false;
    };

    const withdraw = (e) => {
        e.preventDefault();

        f7.dialog.create({
            title: f7.name,
            text: L('Please write the reason for withdrawal.'),
            content: '<textarea id="txtWithdraw" class="textar01 textar_full" cols="30" rows="10"></textarea>',
            buttons: [
                {
                    text: L('Cancel'),
                    keyCodes: [27],
                },
                {
                    text: L('Ok'),
                    bold: true,
                    onClick: doWithdraw,
                    keyCodes: [13],
                },
            ],
            destroyOnClose: true,
        }).open();
    };

    const doWithdraw = () => {
        const reason = $('#txtWithdraw').val().trim();
        if (!reason) {
            alert(L('Please give us the reason why you want to delete your account.'));
            return;
        }

        confirm(L('account-deletion-prompt'), () => {
            let formData = new FormData();
            formData.append("reason", reason);

            fetchPost("/customer/withdrawal.do", formData)
                .then(result => {
                    if (result && result.authStatus === "DELETED") {
                        handleLogout(dispatch, history);
                        alert(L('Your account has been deleted. We hope you can join us again soon.'));
                    }
                })
                .catch(handleFetchError);
        });
    };

    return (
        <div id="container">

            <Header />

            {/* <!--Contents {--> */}
            <div id="edit_profile" className="sub mypage">

                <Navbar title={L('Edit Profile')} />

                <div className="profile_wrap">
                    <form name="profileForm" method="post">
                        <div className="profile_top">
                            <div className="wrapper">
                                <div className="profile_img"><img src={avatar} alt="user avatar" /></div>
                                <p className="profile_name">{user.userName}</p>
                            </div>

                            <div className="profile_textbox01">
                                <div className="wrapper">
                                    <p className="profile_text01">{L('Set up your profile in order to talk to consultants and make reservations easy!')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="profile_wbox_all">
                            <div className="wBox">
                                <div className="wrapper item-input">
                                    <label htmlFor="fullName" className="wBox_title">{L('Full name on passport')}</label>
                                    <input
                                        type="text"
                                        name="fullName"
                                        id="fullName"
                                        className="inpt02 inpt_full"
                                        required validate="true"
                                        defaultValue={user.fullName}
                                    />
                                </div>
                            </div>
                            <div className="wBox">
                                <div className="wrapper item-input">
                                    <label htmlFor="birthdayYmd" className="wBox_title">{L('Date of birth')}</label>
                                    <input
                                        type="date"
                                        name="birthdayYmd"
                                        id="birthdayYmd"
                                        className="inpt02 inpt_full"
                                        required validate="true"
                                        defaultValue={user.birthdayYmd}
                                    />
                                </div>
                            </div>
                            <div className="wBox">
                                <div className="wrapper">
                                    <label className="wBox_title">{L('Gender')}</label>
                                    <ul className="i-col-2 pf_gender_radio_ul">
                                        <li>
                                            <div className="profile_wbox_radiobox">
                                                <input type="radio" name="sex" id="sexF" value="F" defaultChecked={user.sex !== 'M'} />
                                                <label htmlFor="sexF">{L('Female')}</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="profile_wbox_radiobox">
                                                <input type="radio" name="sex" id="sexM" value="M" defaultChecked={user.sex === 'M'} />
                                                <label htmlFor="sexM">{L('Male')}</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="wBox">
                                <div className="wrapper">
                                    <label htmlFor="pf_w_language" className="wBox_title">{L('Language')}</label>
                                    <select
                                        name="language"
                                        id="pf_w_language"
                                        className="selectbox selectbox_full"
                                        value={language}
                                        onChange={(e) => setLanguage(e.target.value)}
                                    >
                                        {LANGUAGES.map((l, idx) => (
                                            <option key={l} value={l}>{LANGUAGE_NAMES[idx]}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="wBox">
                                <div className="wrapper">
                                    <label htmlFor="pf_w_currency" className="wBox_title">{L('Currency')}</label>
                                    <select
                                        name="currency"
                                        id="pf_w_currency"
                                        className="selectbox selectbox_full"
                                        value={currency}
                                        onChange={(e) => setCurrency(e.target.value)}
                                    >
                                        <option value="">{L('Select')}</option>
                                        <option value="KRW">{L('Korean Won')}</option>
                                        <option value="VND">{L('Vietnamese Dong')}</option>
                                        <option value="USD">{L('US Dollar')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="wBox">
                                <div className="wrapper">
                                    <label htmlFor="pf_w_country" className="wBox_title">{L('Country')}</label>
                                    <select
                                        name="countryCode"
                                        id="pf_w_country"
                                        className="selectbox selectbox_full"
                                        value={countryCode}
                                        onChange={(e) => {
                                            setCountryCode(e.target.value);
                                            const country = COUNTRIES.find((c) => c.code === e.target.value);
                                            setCities(country ? country.cities : []);
                                        }}
                                    >
                                        <option value="">{L('Select')}</option>
                                        {COUNTRIES.map((c) => (
                                            <option key={c.code} value={c.code}>{L(c.name)}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="wBox">
                                <div className="wrapper">
                                    <label htmlFor="pf_w_city" className="wBox_title">{L('City/Region')}</label>
                                    <select
                                        name="city"
                                        id="pf_w_city"
                                        className="selectbox selectbox_full"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    >
                                        <option value="">{L('Select')}</option>
                                        {cities.map((c) => (
                                            <option key={c} value={c}>{c}</option>
                                        ))}
                                        <option value="overseas">{L('Overseas')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="wBox">
                                <div className="wrapper item-input">
                                    <label htmlFor="pf_w_phone1" className="wBox_title">{L('Phone number')}</label>
                                    <select
                                        name="mobilePhone1"
                                        id="pf_w_phone1"
                                        className="selectbox selectbox_full"
                                        value={phone1}
                                        onChange={(e) => setPhone1(e.target.value)}
                                    >
                                        <option value="">{L('Select')}</option>
                                        <option value="+82">{L('Korea')} +82</option>
                                        <option value="+84">{L('Vietnam')} +84</option>
                                        <option value="+65">{L('Singapore')} +65</option>
                                        <option value="+61">{L('Australia')} +61</option>
                                        <option value="+1">{L('United States')} +1</option>
                                    </select>
                                    <input type="tel" name="mobilePhone2" id="pf_w_phone2"
                                        className="inpt02 inpt_full" placeholder="Phone number"
                                        validate="true" minLength="9" maxLength="14"
                                        pattern="[0-9\-]{9,14}"
                                        defaultValue={(
                                            user.mobilePhone
                                            && user.mobilePhone.slice(user.mobilePhone.indexOf('-') + 1)
                                        ) || ''}
                                        onBlur={(e) => {
                                            const v = e.target.value.replace(/^[^1-9]+/, '').trim();
                                            e.target.value = v;
                                        }}
                                    />
                                    <p className="profile_wbox_text01">{L('Example: 99-1234567, 888-1234-5678')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="profile_form_agree">
                            <div className="wrapper">
                                <div className="i-col-0 profile_form_agree_chkbox">
                                    <input type="checkbox" name="doEverything" id="profile_form_agree_chk" defaultChecked={!!user.doEverything} />
                                    <label htmlFor="profile_form_agree_chk">
                                        {L('I need O’YOUNG to do everything.')}<br/>
                                        {L('Recommend the hospital and arrange trip for me.')}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="profile_form_btn">
                            <div className="wrapper">
                                <button type="submit" className="btn01 profile_form_submit_btn" onClick={save}>{L('Save')}</button>
                                <a href="#withdraw" className="btn01_w profile_form_cancel_btn" onClick={withdraw}>{L('Delete Account')}</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* <!--} Contents--> */}

            <Footer />
        </div>
    );
}

// const checkValidPhone = (user) => {
//     const f = document.profileForm;
//     const phone2 = f.mobilePhone2.value.trim();

//     if (!phone2)
//         return true;

//     const phone = f.mobilePhone1.value + '-' + phone2;
//     if (phone !== user.mobilePhone) {
//         global.app.views.main.router.navigate('/phone-verification?phone=' + phone, {
//             context: {
//                 onComplete: this.updateProfile,
//             },
//         });
//         return false;
//     } else {
//         return true;
//     }
// }

const updateProfile = (dispatch) => {
    const f = document.profileForm;
    const data = f7.form.convertToData(f);
    data.doEverything = f.doEverything.checked;
    data.mobilePhone = data.mobilePhone2 ? (data.mobilePhone1 + '-' + data.mobilePhone2) : '';

    confirm(L('Ok to update your profile?'), () => {
        f7.preloader.show();
        f.reset();

        handleSaveProfile(data, dispatch).then(() => {
            f7.preloader.hide();
            alert(L("Update completed"), () => {
                // f7.views.main.router.navigate(this.redirect, {
                //     clearPreviousHistory: true
                // });
            });
        }).catch(handleFetchError);
    });

    return false;
};

