import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import view_icon01 from '../images/view_icon01.png';

import { handleConsultantLike } from "../Services";
import { store } from "../Store";
import { getLang, loadLazyImages, loadLazyImagesOnScroll } from "../utils";
import LikeButton from "./LikeButton";

export default function PartnerList({partners}) {
    let { state, dispatch } = useContext(store);

    useEffect(() => {
        loadLazyImages('.clinic_thumb img');
        return loadLazyImagesOnScroll('.clinic_thumb img', document);
    }, [partners]);

    return partners.map((c) => {
        return (
            <div key={c.id} className="clinic_card-a like">
                <div className="wrapper">
                    <div className="clinic_title cf">
                        <Link to={`/partners/${c.id}`} className="clinic_title_txt f7-ignore">
                            {getLang() === 'ko' ? c.userName : c.englishName}
                        </Link>
                        <span className="clinic_view"><img src={view_icon01} alt="# views" /><b>{c.views}</b></span>
                    </div>
                    {c.banner && (
                        <Link to={`/partners/${c.id}`} className="clinic_thumb f7-ignore">
                            <img data-src={`/upload/${c.banner.thumbnail || c.banner.path}`} alt={c.banner.name} />
                        </Link>
                    )}
                    <ul className="i-col-1 doctor_dt_btn">
                        <li>
                            <LikeButton
                                onClickLike={() => handleConsultantLike(c, null, state, dispatch)}
                                target={c}
                                targetLike={state.consultantLikes}
                                className="doctor_dt_like_btn"
                            />
                        </li>
                    </ul>
                </div>
            </div>
        );
    });
}
