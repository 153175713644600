import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

import program_health_item01 from "../images/t-program_health_item01.png";
import program_health_item02 from "../images/t-program_health_item02.png";
import program_health_item03 from "../images/t-program_health_item03.png";
import program_health_item04 from "../images/t-program_health_item04.png";
import { L } from "../utils";

export default function HealthPage() {
    return (
        <div id="container">
            <Header />

            <div id="program_health" className="sub">

                <div className="menu_2dp">
                    <div className="wrapper">
                        <ul className="i-col-2 menu_2dp_ul">
                            <li><Link to="/products" className="f7-ignore">{L('Beauty')}</Link></li>
                            <li className="active"><Link to="/health" className="f7-ignore">{L('Health')}</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="prod_list">
                    <div className="wrapper">
                        <p className="prod_list_tit">{L('O\'YOUNG Health Programs')}</p>

                        <div className="i-col-2 prod_list_wrap">
                            <Link to="/health1" className="prod_list_item f7-ignore">
                                <div className="prod_list_item_thumb bg01">
                                    <img src={program_health_item01} alt="Program values" />
                                </div>
                                <p className="prod_list_item_name">{L('Program Values')}</p>
                            </Link>
                            <Link to="/health2" className="prod_list_item f7-ignore">
                                <div className="prod_list_item_thumb bg02">
                                    <img src={program_health_item02} alt="Cell types" />
                                </div>
                                <p className="prod_list_item_name">{L('Cell Types')}</p>
                            </Link>
                            <Link to="/health3" className="prod_list_item f7-ignore">
                                <div className="prod_list_item_thumb bg03">
                                    <img src={program_health_item03} alt="Process" />
                                </div>
                                <p className="prod_list_item_name">{L('Process')}</p>
                            </Link>
                            <Link to="/health4" className="prod_list_item f7-ignore">
                                <div className="prod_list_item_thumb bg04">
                                    <img src={program_health_item04} alt="Medical tests" />
                                </div>
                                <p className="prod_list_item_name">{L('Medical Tests')}</p>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>

            <Footer index="1" />
        </div>
    );
}
