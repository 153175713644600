import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import talk_icon from '../images/talk_icon.png';
import mail_icon from '../images/mail_icon.png';

import Footer from "../components/Footer";
import Header from "../components/Header";
import { fetchGet, getLang, L } from "../utils";

export default function PoliciesPage() {
    const loc = useLocation();
    const [content, setContent] = useState({});

    useEffect(() => {
        fetchGet('/api/contents/COMPANY').then((result) => {
            if (result)
                setContent(JSON.parse(result.i18nContent));
        });
    }, [loc.search]);

    return (
        <div id="container" className="gray">
            <Header />

            <div id="policy" className="sub">

                <div className="policy_wrap">
                    <div className="wrapper">
                        <div className="policy_nav">
                            <ul className="policy_nav_ul">
                                <li>
                                    <Link to="/policy-general" className="policy_nav_inner f7-ignore">
                                        {L('General Terms and Conditions')}
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to="./general_payment_terms.html" className="policy_nav_inner f7-ignore">
                                        {L('General Payment Terms')}
                                    </Link>
                                </li> */}
                                <li>
                                    <Link to="/policy-privacy" className="policy_nav_inner f7-ignore">
                                        {L('Privacy Policy')}
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className="oyoung_info">
                            <div dangerouslySetInnerHTML={{__html: content ? (content[getLang()] || content.en) : ''}} />
                        </div>

                        <div className="oyoung_contact">
                            <ul className="i-col-2 oyoung_contact_ul">
                                <li>
                                    <Link to="/talks" className="oyoung_contact_inner">
                                        <img src={talk_icon} alt="" className="oyoung_contact_talk" />
                                        <span className="oyoung_contact_text">O'young Talk</span>
                                    </Link>
                                </li>
                                <li>
                                    <a href="mailto:Help@oyoung.co.kr" className="oyoung_contact_inner f7-ignore">
                                        <img src={mail_icon} alt="" className="oyoung_contact_mail" />
                                        <span className="oyoung_contact_text">Help@oyoung.co.kr</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />

        </div>
    )
}