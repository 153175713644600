import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import talk_chat_msg_receive_img from '../images/talk_chat_msg_receive_img.png';
import view_icon01 from '../images/view_icon01.png';
import comment_icon01 from '../images/comment_icon01.png';
import quick_write_icon from '../images/quick_write_icon.png';

import CategoriesBar from "../components/CategoriesBar";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SortKeysBar, { ORDER_KEYS } from "../components/SortKeysBar";
import { store } from "../Store";
import { fetchGet, formatRelativeTime, L, loadLazyImages, loadLazyImagesOnScroll, needLogin } from "../utils";
import LikeButton from "../components/LikeButton";
import { handleReviewLike } from "../Services";
import { f7 } from "framework7-react";
import Navbar from "../components/Navbar";

export default function ReviewsPage() {
    const history = useHistory();
    const loc = useLocation();
    const { state } = useContext(store);
    const [onlyMine, setOnlyMine] = useState();
    const [category, setCategory] = useState();
    const [orderKey, setOrderKey] = useState(ORDER_KEYS[0]);
    const [reviews, setReviews] = useState();
    const [reviewLikes, setReviewLikes] = useState([]);

    useEffect(() => {
        const params = f7.utils.parseUrlQuery(loc.search);
        setOnlyMine(params.mine);

        Promise.all([
            fetchGet('/api/customerReviews?size=50'),
            state.user ? fetchGet('/customer/favorites.do?type=customer_review') : null,
        ]).then((result) => {
            setReviews(result[0].content);
            if (result[1])
                setReviewLikes(result[1].map((a) => ({id: a[0]})));
        });
    }, [loc.search, state.user]);

    let filtered;
    if (reviews) {
        filtered = reviews.filter(
            (r) => (!category || r.categories.indexOf(category) >= 0) && (!onlyMine || (state.user && state.user.id === r.customerId))
        );
        filtered.sort(orderKey.sorter);
    }

    useEffect(() => {
        loadLazyImages('.review_cont_img_inner img');
        return loadLazyImagesOnScroll('.review_cont_img_inner img', document);
    }, [filtered]);

    return (
        <div id="container" className="gray">

            <Header />

            <div id="review" className="sub">

                {onlyMine ? (
                    <Navbar title={L('My Reviews')} />
                ) : (
                    <div className="menu_2dp">
                        <div className="wrapper">
                            <ul className="i-col-3 menu_2dp_ul">
                                <li><Link to="/doctors" className="f7-ignore">{L('Doctors')}</Link></li>
                                <li><Link to="/clinics" className="f7-ignore">{L('Clinics')}</Link></li>
                                <li className="active"><Link to="/reviews" className="f7-ignore">{L('Reviews')}</Link></li>
                            </ul>
                        </div>
                    </div>
                )}

                <CategoriesBar category={category} setCategory={setCategory} />

                <div className="review_list">

                    <SortKeysBar orderKey={orderKey} setOrderKey={setOrderKey} />

                    <div className="review_list_wrap">
                        {filtered ? (
                            filtered.length
                            ? filtered.map((r) => {
                                const categories = r.categories.split(';');
                                const content = r.content.trim();
                                let excerpt = content.slice(0, 100);
                                if (excerpt.length < content.length)
                                    excerpt += '...';
                                
                                return (
                                    <div key={r.id} className="review_list_box">
                                        <div className="wrapper">
                                            <div className="i-col-0 review_card_top">
                                                <div className="review_img">
                                                    <img src={talk_chat_msg_receive_img} alt={L('customer')} />
                                                </div>
                                                <div className="review_inf cf">
                                                    <div className="review_name_box">
                                                        <Link
                                                            to={{pathname: `/reviews/${r.id}`, state: { review: r, reviewLikes }}}
                                                            className="review_name f7-ignore"
                                                        >
                                                            {r.customerName}
                                                        </Link>
                                                    </div>
                                                    <p className="review_date">{formatRelativeTime(r.createdAt)}</p>
                                                    <span className="review_view"><img src={view_icon01} alt="Number of views" /><b>{r.views}</b></span>
                                                </div>
                                                <Link
                                                    to={{pathname: `/reviews/${r.id}`, state: { review: r, reviewLikes }}}
                                                    className="review_subj f7-ignore"
                                                >
                                                    {r.subject}
                                                </Link>
                                            </div>
                                            <div className="review_card_cont">
                                                {r.photos.length ? (
                                                    <div className="review_cont_img">
                                                        <ul className={`${r.photos.length > 1 ? 'i-col-2' : 'i-col-1'} review_cont_img_ul`}>
                                                            {r.photos.filter((_p, idx) => idx < 2).map((p) => (
                                                                <li key={p.id}>
                                                                    <div className="review_cont_img_inner" style={r.photos.length <= 1 ? {paddingBottom: '55.6%'} : null}>
                                                                        <img data-src={`/upload/${p.thumbnail}`} alt={p.name} />
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ) : ''}
                                                <ul className="i-col-0 review_keyword">
                                                    {categories.map((c) => (
                                                        <li key={c}><a href={`#${c}`}>{L(c)}</a></li>
                                                    ))}
                                                </ul>
                                                <div className="review_cont_txt01">{excerpt}</div>
                                                <Link
                                                    to={{pathname: `/reviews/${r.id}`, state: { review: r, reviewLikes }}}
                                                    className="review_cont_more_btn f7-ignore"
                                                >
                                                    + {L('View more')}
                                                </Link>
                                            </div>
                                            <ul className="i-col-2 doctor_dt_btn">
                                                <li>
                                                    <LikeButton
                                                        target={r}
                                                        targetLike={reviewLikes}
                                                        onClickLike={() => handleReviewLike(
                                                            r,
                                                            () => setReviews(filtered),
                                                            state.user,
                                                            reviewLikes,
                                                            setReviewLikes
                                                        )}
                                                    />
                                                </li>
                                                <li>
                                                    <button
                                                        className="doctor_dt_comment_btn f7-ginore"
                                                        onClick={() => {
                                                            history.push(`/reviews/${r.id}`, { review: r, reviewLikes, writeComment: true });
                                                        }}
                                                    >
                                                        <img src={comment_icon01} alt="comment icon" />
                                                        {L('Comments')} {r.comments ? <b>({r.comments})</b> : ''}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                            })
                            : <div style={{margin: '1em', textAlign: 'center'}}>{L('Such empty!')}</div>
                        ) : ''
                        }
                        {/* <div className="doctor_dt_review_more">
                            <button className="doctor_dt_review_more_btn">+ {L('More')}</button>
                        </div> */}
                    </div>
                    <div className="quick_wrap">
                        <button
                            type="button"
                            className="quick_btn quick_btn02"
                            onClick={() => {
                                if (!state.user) needLogin('/review-write', history);
                                else history.push('/review-write');
                            }}
                        >
                            <img src={quick_write_icon} alt="Write icon" />{L('Write')}
                        </button>
                    </div>
                </div>
            </div>

            <Footer index="2" upButtonClass="above_r_fixBtn" />
        </div>
    );
}