import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import $ from 'jquery';
import Video from 'twilio-video';
import { f7 } from "framework7-react";

import { confirm, fetchGet, getLang, handleFetchError, L } from "../utils";
import { store } from "../Store";

let localTrack;
let talk;
let hideTimeout;
let talkTimeInterval;

export default function ConsultationRoomPage() {
    const { state } = useContext(store);
    const history = useHistory();
    const { id } = useParams();
    const [consultation, setConsultation] = useState();

    useEffect(() => {
        fetchGet('/customer/api/consultations/' + id).then(result => setConsultation(result.consultation));
    }, [id]);

    const c = consultation || {};
    const now = new Date();
    const bookDate = new Date(c.bookDate);
    // 시작 전 15분부터 시작 후 2시간 이내만 허용한다.
    const open = c.status === 'CONFIRMED' && now.getTime() > bookDate.getTime() - 15 * 60 * 1000
        && now.getTime() < bookDate.getTime() + 120 * 60 * 1000;

    const stopPreview = () => {
        $('#btnStopPreview').hide();
        if (localTrack) {
            localTrack.stop();
            localTrack.detach();
            localTrack = null;
        }
        $('#divPreTalk video').remove();
    };

    const startSession = () => {
        stopPreview();
        $('#actions').fadeOut('fast');

        const identities = {};
        identities[state.user.id] = state.user.userName;
        identities[c.talk.consultantId] = c.talk.consultantEnglishName;

        talk = new VideoTalk(Video, '#vidActiveVideo', '#divParticipants', identities);
        var roomId = c.id;
        f7.preloader.show();
            
        fetchGet('/customer/api/videoToken.do?id=' + roomId + '&uuid=' + c.uuid).then(result => {
            if (result.errors)
                throw result;
    
            return talk.init(result.token, roomId, true);
        }).then(() => {
            $('#divPreTalk').hide();
            $('#divTalk').show();
            f7.preloader.hide();

            $('#divTalk .center-buttons').removeClass('hidden-control');

            hideTimeout = setTimeout(() => {
                $('#divTalk .center-buttons').addClass('hidden-control');
            }, 5000);

            talkTimeInterval = setInterval(() => {
                var now = new Date();
                var sec = Math.floor((now.getTime() - new Date(consultation.bookDate).getTime()) / 1000);
                var sign = sec < 0 ? '-' : '+';
                sec = Math.abs(sec);
                var min = Math.floor(sec / 60);
                sec = sec % 60;
                var time = now.toLocaleTimeString(getLang())
                    + ' (' + sign + (min < 10 ? '0' : '') + min + ':'
                    + (sec < 10 ? '0' : '') + sec + ')';
                $('#divTalk time').text(time);
            }, 170);

            talk.vars.room.once('disconnected', () => {
                clearInterval(talkTimeInterval);
                $('#divTalk').hide();
                $('#divPreTalk').show();
                $('#actions').fadeIn('fast');
            });

        }).catch(handleFetchError);
    };

    const showButtons = () => {
        clearTimeout(hideTimeout);
        if ($('#divTalk .center-buttons').is('.hidden-control'))
            $('#divTalk .center-buttons').removeClass('hidden-control');

        hideTimeout = setTimeout(function() {
            $('#divTalk .center-buttons').addClass('hidden-control');
        }, 5000);
    };

    const toggleVideo = e => {
        let el = e.target;
        let off = el._off = !el._off;
        talk.toggleVideo(off);
        if (off) {
            $(el).removeClass('on');
        } else {
            $(el).addClass('on');
        }
        $(el).find('i').text(off ? 'videocam_off' : 'videocam');
    }

    const toggleAudio = e => {
        let el = e.target;
        let off = el._off = !el._off;
        talk.toggleAudio(off);
        if (off) {
            $(el).removeClass('on');
        } else {
            $(el).addClass('on');
        }
        $(el).find('i').text(off ? 'mic_off' : 'mic');
    }

    const disconnect = () => {
        confirm(L('Disconnect from the consultation?'), () => {
            talk.disconnect();
        });
    };

    
    return (
        <div id="container" style={{height: '100vh'}}>
            <div id="divPreTalk" style={{display: open ? '' : 'none'}} onMouseDown={() => $('#actions').fadeIn('fast')}>
                <span id="btnStopPreview" className="material-icons" style={{display: 'none'}} onClick={stopPreview}>cancel</span>
            </div>
            <div id="divTalk" style={{display: 'none'}}>
                <video id="vidActiveVideo" autoPlay playsInline muted style={{objectFit: 'cover'}}
                    onMouseDown={showButtons}></video>

                <div className="time">
                    <span className="material-icons" style={{verticalAlign: 'middle'}}>access_time</span> <time></time>
                </div>

                <div className="center-buttons hidden-control">
                    <span id="btnToggleVideo" className="material-icons on" onClick={toggleVideo}>videocam</span>
                    <span id="btnToggleAudio" className="material-icons on" onClick={toggleAudio}>mic</span>
                    <span id="btnDisconnect" className="material-icons on" onClick={disconnect}>close</span>
                </div>

                <div id="divParticipants"></div>
            </div>

            <div id="actions" style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%'}}>
                <div className="center-buttons" style={{height: '200px'}}>
                    <a
                        href="#start"
                        className="btn01"
                        style={{marginBottom: '15px'}}
                        onClick={startSession}
                    >
                        {L('Start Consultation')}
                    </a>
                    <a
                        href="#preview"
                        className="btn01_w bk_w_delete"
                        style={{marginBottom: '15px'}}
                        onClick={() => {
                            $('#actions').fadeOut();
                            Video.createLocalVideoTrack().then(track => {
                                $('#btnStopPreview').show();
                                localTrack = track;
                                const video = track.attach();
                                document.querySelector('#divPreTalk').appendChild(video);
                                video.play();
                            }).catch((e) => {
                                return alert(L('Accessing camera denied. Allow us to access your camera.'));
                            }).then(() => {
                                return Video.createLocalAudioTrack()
                            }).then((track) => {
                                track.stop();
                            }).catch((e) => {
                                return alert(L('Accessing microphone denied. Allow us to access your mic.'));
                            });
                        }}
                    >
                        {L('Preview Video')}
                    </a>
                    <a
                        href="#exit"
                        className="btn01_w bk_w_delete"
                        onClick={() => history.goBack()}
                    >
                        {L('Exit')}
                    </a>

                </div>
            </div>
        </div>

    );
}


// Twilio 예시 https://github.com/twilio/video-quickstart-js 에서 상당 부분 차용

function VideoTalk(Video, activeVideoSelector, participantsContainerSelector, identities) {
    this.vars = {
        Video: Video,
        activeVideo: document.querySelector(activeVideoSelector),
        participants: document.querySelector(participantsContainerSelector),
        
        activeParticipant: null,
        isActiveParticipantPinned: false,
        room: null,
        identities: identities || {},
    };
}

VideoTalk.prototype.disconnect = function() {
    console.log('화상 통화 종료');
    if (this.vars.room)
        this.vars.room.disconnect();
};

VideoTalk.prototype.init = function(token, roomId, localOn) {
    var options = { 
        name: roomId,
        audio: localOn,
        video: localOn ? { height: 480, frameRate: 24, width: 640 } : false,
        bandwidthProfile: {
            video: {
                mode: 'collaboration',
                maxSubscriptionBitrate: 2500000,
                maxTracks: 5,
                dominantSpeakerPriority: 'standard'
            }
        },
        dominantSpeaker: true,
        maxAudioBitrate: 16000,
        // preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
        networkQuality: {local:1, remote: 1}
    }

    var _this = this;

    return this.vars.Video.connect(token, options).then(function(room) {
        console.log('화상 통화 시작, 방 정보 = ', room);

        _this.vars.room = room;
        var localVideoTrack;

        if (localOn) {
            // Save the LocalVideoTrack.
            localVideoTrack = Array.from(room.localParticipant.videoTracks.values())[0].track;

            // Make the Room available in the JavaScript console for debugging.
            // global.room = room;

            // Handle the LocalParticipant's media.
            _this.participantConnected(room.localParticipant);
        }

        // Subscribe to the media published by RemoteParticipants already in the Room.
        room.participants.forEach(function(participant) {
            _this.participantConnected(participant);
        });

        // Subscribe to the media published by RemoteParticipants joining the Room later.
        room.on('participantConnected', function(participant) {
            _this.participantConnected(participant);
        });

        // Handle a disconnected RemoteParticipant.
        room.on('participantDisconnected', function(participant) {
            _this.participantDisconnected(participant);
        });

        // Update the active Participant when changed, only if the user has not
        // pinned any particular Participant as the active Participant.
        room.on('dominantSpeakerChanged', function() {
            if (!_this.vars.isActiveParticipantPinned) {
                _this.setCurrentActiveParticipant();
            }
        });

        var visibilityChange = function() {
            if (this.visibilityState === 'hidden') {
                // When the app is backgrounded, your app can no longer capture
                // video frames. So, stop and unpublish the LocalVideoTrack.
                if (localVideoTrack) {
                    localVideoTrack.stop();
                    room.localParticipant.unpublishTrack(localVideoTrack);
                }
            } else {
                // When the app is foregrounded, your app can now continue to
                // capture video frames. So, publish a new LocalVideoTrack.
                if (localOn)
                    _this.vars.Video.createLocalVideoTrack().then(function(l) {
                        return room.localParticipant.publishTrack(l);
                    });
            }
        };

        // On mobile browsers, use "visibilitychange" event to determine when
        // the app is backgrounded or foregrounded.
        document.addEventListener('visibilitychange', visibilityChange);

        window.addEventListener('beforeunload', function() {
            console.log('웹페이지 unload');
            if (_this.vars.room)
               _this.vars.room.disconnect();
        });

        room.once('disconnected', function(room, error) {
            console.log('화상 통화 종료, 오류 = ', error || '없음');

            // Clear the event handlers on document and window..
            document.removeEventListener('visibilitychange', visibilityChange);

            // Stop the LocalVideoTrack.
            if (localVideoTrack)
                localVideoTrack.stop();

            // Handle the disconnected LocalParticipant.
            _this.participantDisconnected(room.localParticipant);

            // Handle the disconnected RemoteParticipants.
            room.participants.forEach(function (participant) {
                _this.participantDisconnected(participant);
            });

            // Clear the active Participant's video.
            // activeVideo.srcObject = null;

            // Clear the Room reference used for debugging from the JavaScript console.
            // global.room = null;
        });

        setTimeout(function() {
            // Set the current active Participant.
            _this.setCurrentActiveParticipant();
        }, 700);
    });
}

VideoTalk.prototype.toggleVideo = function(off) {
    this.vars.room.localParticipant.videoTracks.forEach(function(publication) {
        publication.track[off ? 'disable' : 'enable']();
    });
};

VideoTalk.prototype.toggleAudio = function(off) {
    this.vars.room.localParticipant.audioTracks.forEach(function(publication) {
        publication.track[off ? 'disable' : 'enable']();
    });
};

/**
 * Set the active Participant's video.
 * @param participant - the active Participant
 */
VideoTalk.prototype.setActiveParticipant = function(participant) {
    if (this.vars.activeParticipant) {
        var activeParticipant = document.getElementById('participant-' + this.vars.activeParticipant.sid);
        if (activeParticipant) {
            activeParticipant.classList.remove('active');
            activeParticipant.classList.remove('pinned');
        }

        if (this.vars.activeParticipant !== participant) {
            // Detach any existing VideoTrack of the active Participant.
            let track = Array.from(this.vars.activeParticipant.videoTracks.values())[0] || {};
            if (track.activeTrack) {
                track.activeTrack.detach(this.vars.activeVideo);
                this.vars.activeVideo.style.opacity = '0';
            }
        }
    }

    var p = document.getElementById('participant-' + participant.sid);
    if (p) {
        p.classList.add('active');
        if (this.vars.isActiveParticipantPinned) {
            p.classList.add('pinned');
        }
    }

    if (this.vars.activeParticipant !== participant) {
        // Attach the new active Participant's video.
        let track = Array.from(participant.videoTracks.values())[0] || {};
        if (track.track) {
            track.track.attach(this.vars.activeVideo);
            this.vars.activeVideo.style.opacity = '';
            this.vars.activeVideo.style.transform = participant === this.vars.room.localParticipant ? 'scaleX(-1)' : '';
        }
    }

    // Set the new active Participant.
    this.vars.activeParticipant = participant;
}

function validParticipant(p) {
    var v = p && Array.from(p.videoTracks.values());
    return v && v.length && v[0].track;
}

/**
 * Set the current active Participant in the Room.
 */
VideoTalk.prototype.setCurrentActiveParticipant = function() {
    var active = this.vars.room.dominantSpeaker;

    if (!validParticipant(active)) {
        this.vars.room.participants.forEach(function(p) {
            if (!active)
                active = p;
        });

        if (!validParticipant(active))
            active = this.vars.room.localParticipant;
    }

    if (validParticipant(active)) {
        console.log('현재 활성 참여자: ', active);
        this.setActiveParticipant(active);
    }
}

/**
 * Set up the Participant's media container.
 * @param participant - the Participant whose media container is to be set up
 */
VideoTalk.prototype.setupParticipantContainer = function(participant) {
    // Add a container for the Participant's media.
    var html = '<div class="participant" data-identity="' + participant.identity
        + '" id="participant-' + participant.sid + '"><audio autoplay '
        + (participant === this.vars.room.localParticipant ? 'muted' : '')
        + ' style="opacity: 0"></audio><video autoplay muted playsinline style="opacity: 0"></video>'
        + '<div class="participant-name">' + (this.vars.identities[participant.identity] || participant.identity) + '</div>'
        + '<span class="material-icons">done</span><div>';
    var temp = document.createElement('div');
    temp.innerHTML = html;
    var container = temp.firstChild;

    // Add the Participant's container to the DOM.
    this.vars.participants.appendChild(container);

    // Toggle the pinning of the active Participant's video.
    var _this = this;
    container.addEventListener('click', function() {
        if (_this.vars.activeParticipant === participant && _this.vars.isActiveParticipantPinned) {
            // Unpin the RemoteParticipant and update the current active Participant.
            _this.setVideoPriority(participant, null);
            _this.vars.isActiveParticipantPinned = false;
            _this.setCurrentActiveParticipant();
        } else {
            if (validParticipant(participant)) {
                // Pin the RemoteParticipant as the active Participant.
                if (_this.vars.isActiveParticipantPinned) {
                    _this.setVideoPriority(_this.vars.activeParticipant, null);
                }
                _this.setVideoPriority(participant, 'high');
                _this.vars.isActiveParticipantPinned = true;
                _this.setActiveParticipant(participant);
            }
        }
    });
}

/**
 * Set the VideoTrack priority for the given RemoteParticipant. This has no
 * effect in Peer-to-Peer Rooms.
 * @param participant - the RemoteParticipant whose VideoTrack priority is to be set
 * @param priority - null | 'low' | 'standard' | 'high'
 */
VideoTalk.prototype.setVideoPriority = function(participant, priority) {
    participant.videoTracks.forEach(function(publication) {
        var track = publication.track;
        if (track && track.setPriority) {
            track.setPriority(priority);
        }
    });
}

/**
 * Attach a Track to the DOM.
 * @param track - the Track to attach
 * @param participant - the Participant which published the Track
 */
VideoTalk.prototype.attachTrack = function(track, participant) {
    // Attach the Participant's Track to the thumbnail.
    var media = document.querySelector('#participant-' + participant.sid + ' > ' + track.kind);
    media.style.opacity = '';
    track.attach(media);

    // If the attached Track is a VideoTrack that is published by the active
    // Participant, then attach it to the main video as well.
    if (track.kind === 'video' && participant === this.vars.activeParticipant) {
        track.attach(this.vars.activeVideo);
        this.vars.activeVideo.style.opacity = '';
    }
}

/**
 * Detach a Track from the DOM.
 * @param track - the Track to be detached
 * @param participant - the Participant that is publishing the Track
 */
VideoTalk.prototype.detachTrack = function(track, participant) {
    // Detach the Participant's Track from the thumbnail.
    var media = document.querySelector('#participant-' + participant.sid + ' > ' + track.kind);
    media.style.opacity = '0';
    track.detach(media);

    // If the detached Track is a VideoTrack that is published by the active
    // Participant, then detach it from the main video as well.
    if (track.kind === 'video' && participant === this.vars.activeParticipant) {
        track.detach(this.vars.activeVideo);
        this.vars.activeVideo.style.opacity = '0';
    }
}

/**
 * Handle the Participant's media.
 * @param participant - the Participant
 */
VideoTalk.prototype.participantConnected = function(participant) {
    if (participant.identity === '_spectator_')
        return;

    console.log('참석자 연결', participant);

    var _this = this;

    // Set up the Participant's media container.
    _this.setupParticipantContainer(participant);

    // Handle the TrackPublications already published by the Participant.
    participant.tracks.forEach(function(publication) {
        _this.trackPublished(publication, participant);
    });

    // Handle theTrackPublications that will be published by the Participant later.
    participant.on('trackPublished', function(publication) {
        _this.trackPublished(publication, participant);
    });
}

/**
 * Handle a disconnected Participant.
 * @param participant - the disconnected Participant
 */
VideoTalk.prototype.participantDisconnected = function(participant) {
    console.log('참석자 연결 종료', participant);

    // If the disconnected Participant was pinned as the active Participant, then
    // unpin it so that the active Participant can be updated.
    if (this.vars.activeParticipant === participant && this.vars.isActiveParticipantPinned) {
        this.vars.isActiveParticipantPinned = false;
        this.setCurrentActiveParticipant();
    }

    // Remove the Participant's media container.
    var p = document.getElementById('participant-' + participant.sid);
    if (p)
        p.parentElement.removeChild(p);
}

/**
 * Handle to the TrackPublication's media.
 * @param publication - the TrackPublication
 * @param participant - the publishing Participant
 */
VideoTalk.prototype.trackPublished = function(publication, participant) {
    var _this = this;

    // If the TrackPublication is already subscribed to, then attach the Track to the DOM.
    if (publication.track) {
        _this.attachTrack(publication.track, participant);
    }

    // Once the TrackPublication is subscribed to, attach the Track to the DOM.
    publication.on('subscribed', function(track) {
        _this.attachTrack(track, participant);
    });

    // Once the TrackPublication is unsubscribed from, detach the Track from the DOM.
    publication.on('unsubscribed', function(track) {
        _this.detachTrack(track, participant);
    });
}

