import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import fix_menu_icon01 from '../images/fix_menu_icon01.png';
import fix_menu_icon02 from '../images/fix_menu_icon02.png';
import fix_menu_icon03 from '../images/fix_menu_icon03.png';
import fix_menu_icon04 from '../images/fix_menu_icon04.png';
import { L } from '../utils';

const _menu = [
    { icon: fix_menu_icon01, label: 'Home', link: '/home' },
    { icon: fix_menu_icon02, label: 'Program', link: '/products' },
    { icon: fix_menu_icon03, label: 'K-Doctor', link: '/doctors' },
    { icon: fix_menu_icon04, label: 'Booking', link: '/consultation-booking' },
];

function showHideScrollTopButton() {
    let _documentY = document.documentElement.scrollTop;
    let _direction = _documentY - global.__scrollPosition >= 0 ? 1 : -1;
    global.__scrollPosition = _documentY;

    if (_documentY === 0) {
        $('#r_fixBtn').hide();
    } else if (_documentY + $(window).height() >= document.documentElement.offsetHeight) {
        $('#r_fixBtn').show();
    } else if (_direction === 1) {
        $('#r_fixBtn').hide();
    } else {
        $('#r_fixBtn').show();
    }
}

export default function Footer({index, upButtonClass}) {
    useEffect(() => {
        global.__scrollPosition = document.documentElement.scrollTop || 0;
        $(document).off('scroll', showHideScrollTopButton).on('scroll', showHideScrollTopButton);

        $("#topBtn").off('click').on('click', () => {
            $('html, body').animate({
                scrollTop: 0
            }, 400);
            return false;
        });
    }, []);

    return (
        <>
            <div id="fix_menu">
                <ul className="i-col-4">
                    {_menu.map((item, idx) => (
                        <li key={item.label} className={+index === idx ? "active" : ''}>
                            <Link to={item.link} className="f7-ignore">
                                <div className="fix_menu_icon"><img src={item.icon} alt={L(item.label)} /></div>
                                <p className="fix_menu_txt">{L(item.label)}</p>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>

            <div id="r_fixBtn" className={upButtonClass}>
                <div id="topBtn"><i className="arrow up"></i></div>
            </div>
        </>
    );
}
