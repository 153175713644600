import { f7 } from "framework7-react";
import ReactGA from "react-ga";

import { getCookie, getDeviceToken, setCookie, setLoginInfo } from "./nativeBridge";
import { fetchGet, fetchPost, fetchPostJson, handleFetchError, L, needLogin, setCurrency, storage } from "./utils";
import { LANGUAGES } from "./const";

export function handleTrendLike(trend, state, dispatch) {
    if (!state.user) {
        needLogin();
        return;
    }

    let trends = [...state.trendLikes];
    let idx = -1;
    state.trendLikes.forEach((t, i) => {
        if (t.id === trend.id) idx = i;
    });

    if (idx >= 0) {
        trend.likes--;
        trends.splice(idx, 1);
    } else {
        trend.likes++;
        trends.push(trend);
    }
    dispatch({type: 'SET_TREND_LIKES', payload: trends});

    // 서버 반영
    let data = new FormData();
    data.append("targetId", trend.id);
    data.append("like", idx < 0);
    data.append('type', 'trend');

    return fetchPost("/customer/favorite.do", data);
}

export function handleConsultantLike(consultant, setConsultant, state, dispatch) {
    if (!state.user) {
        needLogin();
        return;
    }

    let consultants = [...state.consultantLikes];
    let idx = -1;
    state.consultantLikes.forEach((t, i) => {
        if (t.id === consultant.id) idx = i;
    });

    if (idx >= 0) {
        consultant.likes--;
        consultants.splice(idx, 1);
    } else {
        consultant.likes++;
        consultants.push(consultant);
    }
    if (setConsultant) setConsultant({...consultant});
    dispatch({type: 'SET_CONSULTANT_LIKES', payload: consultants});

    // 서버 반영
    let data = new FormData();
    data.append("targetId", consultant.id);
    data.append("like", idx < 0);
    data.append('type', consultant.userType === 'partner' ? 'partner' : 'consultant');

    return fetchPost("/customer/favorite.do", data);
}

export function handleReviewLike(review, resetReviews, user, reviewLikes, setReviewLikes) {
    if (!user) {
        needLogin();
        return;
    }

    let idx = reviewLikes.findIndex((l) => l.id === review.id);

    if (idx >= 0) {
        review.likes--;
        reviewLikes.splice(idx, 1);
    } else {
        review.likes++;
        reviewLikes.push({id: review.id});
    }
    resetReviews();
    setReviewLikes([...reviewLikes]);

    // 서버 반영
    let data = new FormData();
    data.append("targetId", review.id);
    data.append("like", idx < 0);
    data.append('type', 'customer_review');

    return fetchPost("/customer/favorite.do", data);
}

export function handleSignIn(login, password, rememberMe, redirect, dispatch, history) {
    let formData = new FormData();
    formData.append("username", "customer:" + login);
    formData.append("password", password);
    if (rememberMe)
        formData.append("remember-me", "on");

    return fetchPost("/customer/login.do", formData)
        .then(result => {
            if (result.login) {
                if (rememberMe)
                    getCookie('remember-me').then(value => storage('rememberMeCookie', value));

                global.setLoginInfoForToken = (token) => setLoginInfo(result.login.loginName, token);

                handleLogin(redirect, dispatch, history)
                    .then(() => getDeviceToken(global.setLoginInfoForToken));
            } else if (result.error) {
                alert(L(result.error));
            }
        })
        .catch(handleFetchError);
}

export function handleLogin(redirect, dispatch, history) {
    return Promise.all([
        storage('rememberLogin'),
        storage('rememberMeCookie')
    ]).then(([rememberLogin, rememberMeCookie]) => {
        // 로그인 기억 여부 초기화
        dispatch({ type: 'SET_REMEMBER_LOGIN', payload: !!rememberLogin });

        // 로그인 기억 쿠키 되살리기
        console.log('rememberLogin', rememberLogin, 'remember-me cookie', rememberMeCookie);
        if (rememberLogin && rememberMeCookie) {
            return new Promise(resolve => {
                resolve(setCookie('remember-me', rememberMeCookie));
            });
        }
    }).then(() => Promise.all([
        fetchGet("/customer/init.do"),
        storage('_language'),
        storage('currency'),
    ])).then(([info, language, currency]) => {
        // 언어 초기화
        let defaultLang = navigator.language.slice(0, 2);
        if (LANGUAGES.indexOf(defaultLang) < 0)
            defaultLang = 'en';
        const lang = (info.login && info.login.language) || language || defaultLang;
        dispatch({ type: 'SET_LANGUAGE', payload: lang });

        // csrf
        if (info.csrfToken) {
            sessionStorage.csrfHeaderName = info.csrfHeaderName;
            sessionStorage.csrfToken = info.csrfToken;
        }

        info.consultants.forEach((c) => {
            if (c.categories)
                c.categories = c.categories.split(';');
            if (c.specialties)
                c.specialties = c.specialties.split(';');
        });
        dispatch({
            type: 'SET_CONSULTANTS',
            payload: info.consultants.filter((c) => (
                (!c.partnerAuthStatus || c.partnerAuthStatus === 'NORMAL')
                && c.publishingRequest === 'Approved'
                && c.inApp
            ))
        });

        info.trends.forEach((t) => {
            t.titles = JSON.parse(t.i18nTitle);
        });
        dispatch({ type: 'SET_TRENDS', payload: info.trends });

        // 환율
        setCurrency((info.login && info.login.currency) || currency, info.exchangeRates);

        // 로그인 후 이동할 페이지가 지정됐는가?
        return (
            info.login
            ? _initLogin(info.login, dispatch)
            : new Promise((resolve) => resolve(dispatch({ type: 'SET_USER', payload: null })))
        ).then(() => {
            if (redirect) {
                console.log('redirect =', redirect);
                history.replace(redirect[0] === '/' ? redirect : '/' + redirect);
            } else if (info.login && info.login.tempAuth) {
                history.push('/password', true);
            }
        });

    }).catch(handleFetchError);
}

const _initLogin = (member, dispatch) => {
    return Promise.all([
        storage('memberId'),
    ]).then(([oldMemberId]) => {
        //TODO 우선 간단하게 인증정보 삭제
        member.authKey = "";

        ReactGA.event({ category: "User", action: "Login" });

        // 이전에 로그인한 사용자와 다른 사용자로 로그인한 경우 language외의 기억 값 초기화
        if (oldMemberId !== member.id) {
            storage('openTalkHelp', null);
            storage('searchHistory', null);
            storage('memberId', member.id);
            delete localStorage['login-ymd'];
        }

        // 트렌드 좋아요 등 여러 기본 데이터 조회, 보관
        setTimeout(() => {
            fetchGet("/customer/baseData.do").then(result => {
                dispatch({ type: 'SET_TREND_LIKES', payload: result.trendLikes });
                dispatch({ type: 'SET_CONSULTANT_LIKES', payload: result.consultantLikes });
            }).catch(handleFetchError);
        }, 500);

        return dispatch({ type: 'SET_USER', payload: member });
    });
};

export function handleLogout(dispatch, history) {
    fetchPost("/customer/logout.do").finally(() => {
        storage('rememberMeCookie', '');
        setCookie('remember-me', '');

        ReactGA.event({ category: "User", action: "Logout" });
        history.push("/sign");
        dispatch({ type: 'SET_USER', payload: null });
        dispatch({ type: 'SET_TREND_LIKES', payload: null });
        dispatch({ type: 'SET_CONSULTANT_LIKES', payload: null });
    }).catch(() => {
        // Spring에서 로그아웃 리디렉션을 발생시키는데 우리가 무시하느라 오류 발생. 여기서 오류 무시
        console.log('스프링 로그아웃 리디렉션 무시');
    });
}

export function handleSaveProfile(data, dispatch) {
    return fetchPostJson("/customer/edit.do", data).then(result => {
        if (result.errors)
            throw result;

        _initLogin(result.customer, dispatch);
        dispatch({ type: 'SET_LANGUAGE', payload: result.customer.language });
        setCurrency(result.customer.currency);
    });
};

export function handleSignUp(data, dispatch, history) {
    return fetchPostJson("/guest/signUp.do", data)
        .then(result => {
            if (result.errors)
                throw result;

            ReactGA.event({
                category: "User",
                action: "Created an account"
            });

            if (result.customer.authStatus !== 'NORMAL')
                alert(L("We sent you email and you need to click the confirmation link.")
                    + '<br><br>' + result.customer.email);
            else if (result.customer.thirdParty) {
                handleSignIn('3RDPARTY', result.customer.thirdParty, true, '/home', dispatch, history);
            } else {
                alert(L("Thanks for signing up to O'Young. Now you can sign in."));
            }

            return true;
        })
        .catch(handleFetchError);
}

export function handleSendMessage(
    talkId,
    message,
    photoBlobs,
    filenames
) {
    let formData = new FormData();
    formData.append("message", message);
    formData.append('talkId', talkId);
    if (photoBlobs) {
        photoBlobs.forEach((e, idx) => {
            formData.append("file", e, filenames[idx]);
        });
    }

    return fetchPost("/customer/api/talkMessages", formData)
        .then(result => {
            if (result.errors)
                throw result;

            return result.message;
        })
        .catch(handleFetchError);
}

export function handleStartTalk(recipientIds, form, otherData, photos) {
    let data = new FormData();
    recipientIds.forEach(id => {
        data.append("recipientId", id);
    });

    data.append('category', form.category);
    data.append('question', form.question);
    if (form.surgeries)
        data.append('surgeries', form.surgeries);
    if (form.lastSurgery)
        data.append('lastSurgery', form.lastSurgery);

    if (photos)
        photos.forEach(p => {
            data.append('file', p.file || p.id, p.name);
        });

    if (otherData)
        for (let i = 0; i < otherData.length; ++i)
            data.append(otherData[i].key, otherData[i].value);
    else
        data.append('timezone', new Date().getTimezoneOffset());

    f7.preloader.show();
    return fetchPost("/customer/talkStart.do", data)
        .then((result) => { f7.preloader.hide(); return result; })
        .catch(handleFetchError);
};

export function handleChangePassword(oldPassword, newPassword) {
    let formData = new FormData();

    formData.append("oldPassword", oldPassword);
    formData.append("newPassword", newPassword);

    return fetchPost("/customer/changePassword.do", formData).catch(
        handleFetchError
    );
};

export async function handleAskQuote(talkId, categories) {
    let formData = new FormData();
    formData.append("talkId", talkId);
    categories.forEach(c => formData.append("category", c));

    return await fetchPost("/customer/estimateRequest.do", formData)
        .catch(handleFetchError);
};

export async function handleLeaveTalk(talkId, leaveReason) {
    let formData = new FormData();
    formData.append('talkId', talkId);
    formData.append('leaveReason', leaveReason);
    return await fetchPost('/customer/talkLeave.do', formData)
        .catch(handleFetchError);
};
