import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import $ from 'jquery';
import { PhotoBrowser } from "framework7-react";
import { Link } from "react-router-dom";

import share_icon01 from '../images/share_icon01.png';

import Footer from "../components/Footer";
import Header from "../components/Header";
import LikeButton from "../components/LikeButton";
import Navbar from "../components/Navbar";
import ReviewList from "../components/ReviewList";
import { store } from "../Store";
import { fetchGet, getLang, L } from "../utils";
import { handleConsultantLike } from '../Services';
import { exeShare } from "../nativeBridge";
import TalkButton from "../components/TalkButton";

export default function DoctorDetailPage() {
    const { id } = useParams();
    const { state, dispatch } = useContext(store);
    const photoBrowser = useRef(null);
    const [doctor, setDoctor] = useState();
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        // 탭 전환 처리
        const f = function () {
            const tabBtn_idx = $(this).index();

            $(this).addClass('active').siblings().removeClass('active');
            $(".doctor_dt_indt_ct").eq(tabBtn_idx).fadeIn(200).siblings().hide();
        };

        $(document).on('click', '.doctor_dt_indt_tab_ul li', f);
        return () => $(document).off('click', '.doctor_dt_indt_tab_ul li', f);
    }, []);

    useEffect(() => {
        Promise.all([
            fetchGet('/api/consultants/' + id + '?view=true'),
            fetchGet('/api/customerReviews?consultantId=' + id),
        ]).then(result => {
            setDoctor(result[0]);
            setReviews(result[1].content);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id]);

    if (!doctor) return <div id="container" />;

    const share = () => doctor.id && exeShare(
        // L("Share consultant from O'YOUNG"),
        // "O'YOUNG Doctor",
        global.window.location.origin + "/guest/shareDoctor.html?id=" + doctor.id
    );

    let profile = doctor.i18nProfile ? JSON.parse(doctor.i18nProfile) : {};
    profile = profile[getLang()] || profile.en;

    let news = doctor.i18nNews ? JSON.parse(doctor.i18nNews) : {};
    news = news[getLang()] || news.en;

    const categories = doctor.categories ? doctor.categories.split(';') : (doctor.field ? [doctor.field] : []);
    const specialties = doctor.specialties ? doctor.specialties.split(';') : [];

    return (
        <div id="container">
            <Header />

            <div id="doctor_detail" className="sub">
                <Navbar title={getLang() === 'ko' ? doctor.userName : doctor.englishName} />

                <div className="doctor_dt_wrap">
                    <div className="doctor_dt_inf">
                        <div className="wrapper">
                            {doctor.partner && (
                                <Link to={`/partners/${doctor.partner.id}`} className="f7-ignore">
                                    <div className="doctor_dt_logo">
                                        {doctor.partner.logo && (
                                            <div className="doctor_dt_logo_img">
                                                <img
                                                    src={`/upload/${doctor.partner.logo.thumbnail
                                                        || doctor.partner.logo.path}`}
                                                    alt={doctor.partner.userName}
                                                />
                                            </div>
                                        )}
                                        <p className="doctor_dt_cname">{getLang() === 'ko' ? doctor.partner.userName : doctor.partner.englishName}</p>
                                    </div>
                                </Link>
                            )}

                            <ul className="doctor_dt_summary">
                                {doctor.field && <li><p className="subtitle_st01 dot">{L(doctor.field)}</p></li>}
                                {doctor.recommended && (
                                    <li><p className="subtitle_st01 dot">{L('O\'YOUNG recommends this {}', L('consultant'))}</p></li>
                                )}
                            </ul>

                            <ul className="i-col-2 doctor_dt_btn">
                                <li>
                                    <LikeButton
                                        target={doctor}
                                        targetLike={state.consultantLikes}
                                        onClickLike={() => handleConsultantLike(doctor, setDoctor, state, dispatch)}
                                    />
                                </li>
                                <li>
                                    <button className="doctor_dt_share_btn" onClick={share}>
                                        <img src={share_icon01} alt="Share" />{L('Share')}
                                    </button>
                                </li>
                            </ul>

                        </div>
                    </div>

                    <div className="doctor_dt_indt">
                        <div className="doctor_dt_indt_tab">
                            <ul className="i-col-3 doctor_dt_indt_tab_ul">
                                <li className="active"><span>{L('Introduction')}</span></li>
                                <li><span>{L('Related News')}</span></li>
                                <li><span>{L('Reviews')}</span></li>
                            </ul>
                        </div>

                        {doctor.poster && (
                            <PhotoBrowser photos={[`/upload/${doctor.poster.path}`]} ref={photoBrowser} />
                        )}

                        <div className="doctor_dt_indt_ct_wrap">
                            <div className="wrapper">
                                <div className="doctor_dt_indt_ct introduction">
                                    <div className="doctor_dt_indt_ct_inner">
                                        {doctor.poster && (
                                            <img
                                                src={`/upload/${doctor.poster.thumbnail || doctor.poster.path}`}
                                                alt={doctor.poster.name}
                                                onClick={() => photoBrowser.current.open()}
                                            />
                                        )}
                                        <div dangerouslySetInnerHTML={{ __html: profile }} />
                                    </div>
                                    <div className="doctor_dt_indt_ct_addinf">
                                        <ul className="doctor_dt_indt_ct_addinf_ul">
                                            <li>
                                                <p className="subtitle_st01 dot">{L('Categories')}</p>
                                                <ul className="i-col-0 doctor_keyword">
                                                    {categories.map((c) => (
                                                        <li key={c}><span className="category">{L(c)}</span></li>
                                                    ))}
                                                </ul>
                                            </li>
                                            <li>
                                                <p className="subtitle_st01 dot">{L('Specialties')}</p>
                                                <ul className="i-col-0 doctor_keyword">
                                                    {specialties.map((c) => (
                                                        <li key={c}><span className="category">{L(c)}</span></li>
                                                    ))}
                                                </ul>
                                            </li>
                                            {/* <li>
                                                <p className="subtitle_st01 dot">O’Young Talk Time</p>
                                                <p className="doctor_dt_indt_ct_addinf_txt">In your timezone, 10:00 AM ~ 7:00 PM, Weekday</p>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>

                                <div className="doctor_dt_indt_ct news">
                                    <div className="doctor_dt_indt_ct_inner">
                                        {news ? <div dangerouslySetInnerHTML={{ __html: news }} /> : <div>{L('No news yet')}</div>}
                                    </div>
                                </div>

                                <div className="doctor_dt_indt_ct review">
                                    <div className="doctor_dt_review_list_wrap">
                                        <ReviewList reviews={reviews} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <TalkButton consultant={doctor} />
                </div>

            </div>

            <Footer index="2" />
        </div>
    );
}