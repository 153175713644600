import { f7 } from 'framework7-react';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';

import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { handleChangePassword } from '../Services';
import { confirm, L, passwordScore } from "../utils";

const keys = ['currPw', 'newPw', 'newPw2'];
const labels = ['Current password', 'New password', 'Confirm password'];

export default function PasswordPage() {
    const history = useHistory();

    const onInputPassword = e => {
        $(e.target).closest('.wrapper').find('.inpt_view_btn')[e.target.value ? 'show' : 'hide']();

        if (e.target.name === 'newPw') {
            const score = passwordScore(e.target.value);
            $(e.target).closest('.item-input').find('meter').prop('value', score);

            let msg = score < 3 ? L("password-help") : '';
            e.target.setCustomValidity(msg);
            f7.input.validate(e.target);
        }
    };

    const save = (e) => {
        e.preventDefault();
        
        let f = document.passwordForm;
        
        //새로운 비밀번호 
        if (passwordScore(f.newPw.value) < 3) {
            alert(L('password-help'));
            f.newPw.focus();
            return false;
        }
    
        //비밀번호 같은지 확인
        if (f.newPw.value !== f.newPw2.value){
            alert(L('Passwords do not match!'));
            f.newPw2.focus();
            return false;
        }

        if (f.currPw.value === f.newPw.value) {
            alert(L('Current and new passwords are the same.'));
            f.currPw.focus();
            return false;
        }
    
        confirm(L('Do you want to change your password?'), () => {
            f7.preloader.show();
            handleChangePassword(f.currPw.value, f.newPw.value).then(ok => {
                f7.preloader.hide();
                if (ok){         //성공
                    alert(L('Password changed successfully.'), () => {
                        history.goBack();
                    });
                    
                } else {     //실패
                    alert(L('Passwords do not match!'));
                }
                f.reset();

                history.goBack();
            })
                
        })
    
         return false;
    };

    return (
        <div id="container">

            <Header />

            <div id="edit_profile" className="sub mypage">

                <Navbar title={L('Change Password')} />

                <div className="profile_wrap">
                    <form name="passwordForm" method="post">
                        <div className="profile_wbox_all">
                            {keys.map((key, idx) => (
                                <div key={key} className="wBox">
                                    <div className="wrapper item-input">
                                        <label htmlFor={key} className="wBox_title">{L(labels[idx])}</label>
                                        <input
                                            type="password"
                                            name={key}
                                            id={key}
                                            className="inpt02 inpt_full"
                                            required validate="true"
                                            onInput={onInputPassword}
                                        />
                                        <span
                                            className="inpt_view_btn"
                                            style={{ display: 'none', top: '55px' }}
                                            onClick={() => {
                                                const e = document.passwordForm[key];
                                                e.type = e.type === 'password' ? 'text' : 'password'
                                            }}
                                        />
                                        {key === 'newPw' && (
                                            <meter className="strength-meter" min="0" max="4" low="2" high="3" optimum="4"></meter>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="profile_form_btn">
                            <div className="wrapper">
                                <button type="submit" className="btn01 profile_form_submit_btn" onClick={save}>{L('Save')}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );
}