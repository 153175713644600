import { useContext, useEffect } from "react";
import { Redirect, Route } from "react-router";
import { store } from "../Store";
import { L } from "../utils";

export default function PrivateRoute({ children, ...rest }) {
    const { state } = useContext(store);

    useEffect(() => {
        if (state.user === null)
            alert(L('Please login to use our service.'));
    }, [state.user]);

    return (
        <Route
          {...rest}
          render={({ location }) => (
            state.user === undefined
                ? <div />
                : state.user ? children : <Redirect to={`/sign?redirect=${encodeURIComponent(location.pathname + location.search)}`} />
          )}
        />
    );
}
