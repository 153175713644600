import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Swiper, SwiperSlide } from "framework7-react";
import { Link } from "react-router-dom";

import Footer from "../components/Footer"
import Header from "../components/Header";
import Navbar from "../components/Navbar"
import { getLang, L } from "../utils";
import { store } from "../Store";

export default function ProductDetailPage() {
    const { id } = useParams();
    const { state } = useContext(store);
    let [product, setProduct] = useState();

    useEffect(() => {
        setProduct(state.products.find((p) => p.id === id));
   
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (!product) return <div id="container"></div>;

    let otherProducts = product && state.products
        ? state.products.filter((p) => p.partner.id === product.partner.id && p.id !== product.id)
        : [];

    return (
        <div id="container">
            <Header />

            <div id="program_beauty_detail" className="sub">
                <Navbar title={product.productNames[getLang()] || product.productNames.en} />

                <div className="prod_dt_img">
                    <div className="wrapper">
                        <Swiper
                            loop={true}
                            pagination={{ el: '.prod_dt_img_sl .swiper-pagination' }}
                            className="prod_dt_img_sl"
                        >
                            {product.banners.map((b) => (
                                <SwiperSlide key={b.id}>
                                    <img src={`/upload/${b.thumbnail}`} alt={b.name} />
                                </SwiperSlide>
                            ))}
                            <div className="swiper-pagination"></div>
                        </Swiper>
                    </div>
                </div>

                <div className="prod_dt_summary">
                    <div className="wrapper">
                        {Object.keys(product.details).length ? (
                            <div className="prod_dt_summary_tb_wrap">
                                <table className="prod_dt_summary_tb">
                                    <tbody>
                                        {product.details.ingredients && (
                                            <tr>
                                                <th>
                                                    <p className="subtitle_st01 dot">{L('Ingredients')}</p>
                                                </th>
                                                <td>{product.details.ingredients[getLang()] || product.details.ingredients.en}</td>
                                            </tr>
                                        )}
                                        {product.details.functions && (
                                            <tr>
                                                <th>
                                                    <p className="subtitle_st01 dot">{L('Functions')}</p>
                                                </th>
                                                <td>{product.details.functions[getLang()] || product.details.functions.en}</td>
                                            </tr>
                                        )}
                                        {product.details.usages && (
                                            <tr>
                                                <th>
                                                    <p className="subtitle_st01 dot">{L('How to use')}</p>
                                                </th>
                                                <td>{product.details.usages[getLang()] || product.details.usages.en}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        ) : ''}

                        <div className="prod_dt_expl" dangerouslySetInnerHTML={{ __html: product.descriptions[getLang()] || product.descriptions.en }}></div>
                    </div>
                </div>

                {/* <div className="prod_dt_opsel">
                    <div className="wrapper">
                        <p className="prod_dt_opsel_title">Advanced consultation for your skin</p>
                        <select name="" id="" className="selectbox selectbox_full">
                            <option value="">Choose Clinic</option>
                        </select>
                        <a href="" className="btn01 prod_dt_opsel_btn">Consultation</a>
                    </div>
                </div> */}

                <p className="prod_dt_opsel_title" style={{marginBottom: '-1em', marginTop: '1em'}}>{L('Related Products')}</p>
                <Swiper
                    slidesPerView="auto"
                    freeMode={true}
                    navigation
                    className="prod_dt_rel_sl"
                >
                    {otherProducts.map((p) => (
                        <SwiperSlide key={p.id}>
                            <Link to={`/products/${p.id}`} className="prod_list_item f7-ignore">
                                <div className="prod_list_item_thumb">
                                    <img src={`/upload/${p.banners[0].thumbnail}`} alt={p.banners[0].name} />
                                </div>
                                <p className="prod_list_item_name">{p.productNames[getLang()] || p.productNames.en}</p>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>

            </div>

            <Footer index="1" />
        </div>
    );
}