import { useEffect, useState } from 'react';

import icon01_01 from '../images/t-program_health_pcs_icon01_01.png';
import icon01_02 from '../images/t-program_health_pcs_icon01_02.png';
import icon01_03 from '../images/t-program_health_pcs_icon01_03.png';
import icon01_04 from '../images/t-program_health_pcs_icon01_04.png';
import icon01_05 from '../images/t-program_health_pcs_icon01_05.png';
import icon02_01 from '../images/t-program_health_pcs_icon02_01.png';
import icon02_02 from '../images/t-program_health_pcs_icon02_02.png';
import icon02_03 from '../images/t-program_health_pcs_icon02_03.png';
import icon02_04 from '../images/t-program_health_pcs_icon02_04.png';
import icon02_05 from '../images/t-program_health_pcs_icon02_05.png';

import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { fetchGet, getLang, L } from "../utils";

const _keys = ['PROGRAM_CONSULTATION', 'PROGRAM_PRODUCTION'];
const _images = [
    [icon01_01, icon01_02, icon01_03, icon01_04, icon01_05],
    [icon02_01, icon02_02, icon02_03, icon02_04, icon02_05],
];

export default function Health3Page() {
    const [contents, setContents] = useState();

    useEffect(() => {
        fetchGet('/api/contents?keys=' + _keys.join(',')).then((result) => {
            const contents = {};
            result.forEach((c) => {
                c.title = JSON.parse(c.i18nTitle);
                c.content = JSON.parse(c.i18nContent);
                contents[c.contentKey] = c;
            });
            setContents(contents);
        });
    }, []);

    let titles = _keys.map((k) => contents && contents[k] && contents[k].title);
    let texts = _keys.map((k) => contents && contents[k] && contents[k].content);

    return (
        <div id="container">
            <Header />

            <div id="program_health_process" className="sub">
                <Navbar title={L('Processs')} />

                <div className="health_dt_cont">
                    <div className="health_dt_sec">
                        <div className="wrapper">
                            <p className="health_dt_tit dot" data-num="01"><span>{titles[0] && (titles[0][getLang()] || titles[0].en)}</span></p>
                            <div className="health_pcs_wrap">
                                <div className="health_pcs_step health_pcs_step_customer">
                                    <div className="health_pcs_step_cont">{L('CUSTOMER')}</div>
                                </div>
                                {(function() {
                                    const arr = makeContentArray(texts[0]);

                                    return _images[0].map((img, idx) => (
                                        <div className="health_pcs_step" key={idx}>
                                            <div className="health_pcs_step_cont">
                                                <div className="health_pcs_step_cont_icon"><img src={img} alt="" /></div>
                                                <div className="health_pcs_step_cont_txtbox">
                                                    <p className="health_pcs_step_cont_num">0{idx + 1}.</p>
                                                    <p className="health_pcs_step_cont_txt01" dangerouslySetInnerHTML={{__html: arr[idx]}} />
                                                </div>
                                            </div>
                                        </div>
                                    ));
                                })()}
                            </div>
                        </div>
                    </div>

                    <div className="health_dt_sec">
                        <div className="wrapper">
                            <p className="health_dt_tit dot" data-num="02"><span>{titles[1] && (titles[1][getLang()] || titles[1].en)}</span></p>
                            <div className="health_pcs_wrap">
                                {(function() {
                                    const arr = makeContentArray(texts[1]);

                                    return _images[1].map((img, idx) => (
                                        <div className="health_pcs_step" key={idx}>
                                            <div className="health_pcs_step_cont">
                                                <div className="health_pcs_step_cont_icon"><img src={img} alt="" /></div>
                                                <div className="health_pcs_step_cont_txtbox">
                                                    <p className="health_pcs_step_cont_num">0{idx + 1}.</p>
                                                    <p className="health_pcs_step_cont_txt01" dangerouslySetInnerHTML={{__html: arr[idx]}} />
                                                </div>
                                            </div>
                                        </div>
                                    ));
                                })()}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Footer index="1" />
        </div>
    );
}

function makeContentArray(t) {
    if (!t) return [];

    t = (t[getLang()] || t.en);
    if (!t) return [];

    return t.split(/<\/p>\s*<p>/)
        .map((t) => t.replace(/<p>|<\/p>/g, '').trim())
        .filter((t) => t > '');
}
