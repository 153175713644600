import { useContext, useEffect, useState } from "react";
import { f7 } from "framework7-react";
import { Link, useLocation } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/Header";
import { fetchGet, getLang, L } from "../utils";
import { store } from "../Store";

export default function ProductsPage() {
    let loc = useLocation();
    let [category, setCategory] = useState();
    const { state, dispatch } = useContext(store);

    useEffect(() => {
        let query = f7.utils.parseUrlQuery(loc.search);
        setCategory(query.category || 'SHOP_BEAUTY');
    }, [loc.search]);

    useEffect(() => {
        if (category) 
            fetchGet(`/api/products?category=${category}`)
                .then((products) => {
                    products.forEach((p) => {
                        p.productNames = JSON.parse(p.i18nProductName);
                        p.descriptions = JSON.parse(p.i18nDescription);
                    });
                    dispatch({ type: 'SET_PRODUCTS', payload: products });
                });
    }, [category, dispatch]);

    return (
        <div id="container">
            <Header />
            <div id="program_beauty" className="sub">

                <div className="menu_2dp">
                    <div className="wrapper">
                        <ul className="i-col-2 menu_2dp_ul">
                            <li className="active"><Link to="?category=SHOP_BEAUTY" className="f7-ignore">{L('Beauty')}</Link></li>
                            <li><Link to="/health" className="f7-ignore">{L('Health')}</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="prod_list">
                    <div className="wrapper">
                        <p className="prod_list_tit">{L('O\'YOUNG Beauty Products')}</p>

                        <div className="i-col-2 prod_list_wrap">
                            {/* <!--loop{--> */}
                            {state.products.map((p) => {
                                const name = p.productNames[getLang()] || p.productNames.en;
                                return (
                                    <Link to={`/products/${p.id}`} className="prod_list_item f7-ignore" key={p.id}>
                                        <div className="prod_list_item_thumb">
                                            <img src={`/upload/${p.banners[0].thumbnail}`} alt={name} />
                                        </div>
                                        <p className="prod_list_item_name">{name}</p>
                                    </Link>
                                );
                            })}
                            {/* <!--}loop--> */}
                        </div>
                    </div>
                </div>
            </div>

            <Footer index={1} />
        </div>
    );
}
