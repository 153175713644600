import { useEffect, useState } from 'react';

import img_fosc from '../images/t-program_health_item02_fosc.jpg';
import img_uosc from '../images/t-program_health_item02_uosc.jpg';
import img_mfsc from '../images/t-program_health_item02_mfsc.jpg';

import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { fetchGet, getLang, L } from "../utils";

const _keys = ['PROGRAM_FoSC', 'PROGRAM_UoSC', 'PROGRAM_MfSC'];
const _subtitles = ['Stem-cell from Autologous Fat', 'Stem-cell from Umbilical cord', 'Membrane-free Stem-cell'];
const _images = [img_fosc, img_uosc, img_mfsc];

export default function Health2Page() {
    const [contents, setContents] = useState();

    useEffect(() => {
        fetchGet('/api/contents?keys=' + _keys.join(',')).then((result) => {
            const contents = {};
            result.forEach((c) => {
                c.title = JSON.parse(c.i18nTitle);
                c.content = JSON.parse(c.i18nContent);
                contents[c.contentKey] = c;
            });
            setContents(contents);
        });
    }, []);

    return (
        <div id="container">
            <Header />

            <div id="program_health_celltype" className="sub">
                <Navbar title={L('Cell Types')} />

                <div className="health_dt_cont">
                    {_keys.map((k, idx) => {
                        const c = contents && contents[k];
                        if (!c) return '';

                        const title = c.title[getLang()] || c.title.en;

                        return (
                            <div className="health_dt_sec" key={k}>
                                <div className="wrapper">
                                    <p className="health_dt_tit dot" data-num={idx + 1}><span>{title}</span></p>
                                    <p className="health_dt_subtit">{L(_subtitles[idx])}</p>
                                    <div className="health_dt_img"><img src={_images[idx]} alt={title} /></div>
                                    <div className="health_dt_expl" dangerouslySetInnerHTML={{ __html: c.content[getLang()] || c.content.en }}></div>
                                </div>
                            </div>
                        );
                    })}
                </div>

            </div>

            <Footer index="1" />
        </div>
    );
}
