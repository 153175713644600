import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { f7 } from "framework7-react";

import Footer from "../components/Footer";
import Header from "../components/Header";
import { L, loadLazyImages, loadLazyImagesOnScroll } from "../utils";
import { store } from '../Store';

import CategoriesBar from "../components/CategoriesBar";
import SortKeysBars, { ORDER_KEYS } from "../components/SortKeysBar";
import DoctorCard from "../components/DoctorCard";

export default function DoctorsPage() {
    const { state } = useContext(store);
    const [category, setCategory] = useState();
    const [orderKey, setOrderKey] = useState(ORDER_KEYS[0]);
    const [doctors, setDoctors] = useState([]);
    const loc = useLocation();
    const [onlyMine, setOnlyMine] = useState();

    useEffect(() => {
        const params = f7.utils.parseUrlQuery(loc.search);
        setOnlyMine(params.mine);
    }, [loc.search])

    useEffect(() => {
        let arr = [...state.consultants];
        arr = arr.filter(
            (c) => (!category || (c.categories && c.categories.indexOf(category) >= 0))
                && (!onlyMine || (state.consultantLikes && state.consultantLikes.find((l) => l.id === c.id)))
        );
        arr.sort(orderKey.sorter);
        setDoctors(arr);
    }, [category, onlyMine, orderKey, state]);

    useEffect(() => {
        loadLazyImages('.doctor_img img');
        return loadLazyImagesOnScroll('.doctor_img img', document);
    }, [doctors]);

    return (
        <div id="container">
            <Header />

            <div id="doctor" className="sub">

                {onlyMine ? (
                    <div className="menu_2dp">
                        <div className="wrapper">
                            <ul className="i-col-2 menu_2dp_ul">
                                <li className="active"><Link to="/doctors?mine=true" className="f7-ignore">{L('Favorite Doctors')}</Link></li>
                                <li><Link to="/clinics?mine=true" className="f7-ignore">{L('Favorite Clinics')}</Link></li>
                            </ul>
                        </div>
                    </div>
                ) : (
                    <div className="menu_2dp">
                        <div className="wrapper">
                            <ul className="i-col-3 menu_2dp_ul">
                                <li className="active"><Link to="/doctors" className="f7-ignore">{L('Doctors')}</Link></li>
                                <li><Link to="/clinics" className="f7-ignore">{L('Clinics')}</Link></li>
                                <li><Link to="/reviews" className="f7-ignore">{L('Reviews')}</Link></li>
                            </ul>
                        </div>
                    </div>
                )}

                <CategoriesBar category={category} setCategory={setCategory} />

                <div className="doctor_list">

                    <SortKeysBars orderKey={orderKey} setOrderKey={setOrderKey} includeRecommended />

                    <div className="doctor_list_wrap">
                        <div className="wrapper">
                            {doctors ? (
                                doctors.length
                                    ? doctors.map((d) => <DoctorCard key={d.id} doctor={d} consultantLikes={state.consultantLikes} />)
                                    : <div style={{ margin: '1em', textAlign: 'center' }}>{L('Such empty!')}</div>
                            ) : ''}
                        </div>
                    </div>
                </div>

            </div>

            <Footer index="2" />
        </div>

    );
}