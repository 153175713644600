import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'framework7-react';
import { useContext, useEffect } from 'react';
import AOS from 'aos';
import $ from 'jquery';
import { Link, useHistory } from 'react-router-dom';

import 'aos/dist/aos.css';

import Header from '../components/Header';
import Footer from '../components/Footer';

import t_main_vs01 from '../images/t-main_vs01.jpg';
import video_icon01 from '../images/video_icon01.png';
import t_program_img01 from '../images/t-program_img01.jpg';
import arrow_r_icon01 from '../images/arrow_r_icon01.png';
import main_vital_banner from '../images/main_vital_banner.png';
import arrow_r_icon02 from '../images/arrow_r_icon02.png';
import { fetchGet, getLang, L, PlaceholderImage } from '../utils';
import { store } from '../Store';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function HomePage() {
    const { state } = useContext(store);
    const history = useHistory();

    function onElementHeightChange(elm, callback) {
        var lastHeight = elm.clientHeight
        var newHeight;

        (function run() {
            newHeight = elm.clientHeight;
            if (lastHeight !== newHeight) callback();
            lastHeight = newHeight;

            if (elm.onElementHeightChangeTimer) {
                clearTimeout(elm.onElementHeightChangeTimer);
            }

            elm.onElementHeightChangeTimer = setTimeout(run, 200);
        })();
    }

    useEffect(() => {
        setTimeout(() => AOS.init({ duration: 1200 }), 1000);
        onElementHeightChange(document.body, AOS.refresh);

        _showServerType();

        return () => {
            clearTimeout(document.body.onElementHeightChangeTimer);
        }
    }, []);

    const _consultants = state.consultants.filter(d => d.recommended);
    const _sort = getLang() === 'ko'
        ? (a, b) => a.userName < b.userName ? -1 : (a.userName > b.userName ? 1 : 0)
        : (a, b) => a.englishName < b.englishName ? -1 : (a.englishName > b.englishName ? 1 : 0);
    _consultants.sort(_sort);

    return (
        <div id="container">
            <Header />

            {/*Contents {*/}
            <div id="main">
                <figure id="main_visual">
                    <img src={t_main_vs01} alt="" className="full_img" />
                </figure>

                <div id="main_doctor" className="main_cont">
                    <div className="wrapper">
                        <p className="title_st01 dot" style={{fontSize: '1.77rem'}} data-aos="fade-up">{L('Recommended Doctors')}</p>
                    </div>

                    <Swiper
                        freeMode={true}
                        slidesPerView="auto"
                        centeredSlides={false}
                        className="main_doctor_sl"
                        data-aos="fade-up"
                        style={{minHeight: '190px'}}
                    >
                        {_consultants.map((d) => {
                            const categories = d.categories || [];
                            let catElems = categories
                                .filter((c, idx) => idx < 2)
                                .map(c =>
                                    <li key={c} style={{display:'inline-block'}}>
                                        <span>{L(c)}</span>
                                    </li>
                                );
                            if (!catElems.length)
                                catElems = d.field;

                            return (
                                <SwiperSlide key={d.id}>
                                    <div className="doctor_card-a">
                                        <div className="doctor_thumb">
                                            <Link to={`/consultation-booking?consultant=${d.id}`} className="f7-ignore">
                                                <img
                                                    src={
                                                        d.photo
                                                            ? ("/upload/" + (d.photo.thumbnail || d.photo.path))
                                                            : PlaceholderImage
                                                    }
                                                    alt={d.englishName}
                                                    className="full_img"
                                                />
                                            </Link>
                                        </div>
                                        <div className="doctor_inf">
                                            <Link to={`/consultation-booking?consultant=${d.id}`} className="video_chat_btn f7-ignore"><img src={video_icon01} alt="Video Chat Button" /></Link>
                                            <p className="doctor_name">
                                                <span style={{fontSize: '1rem'}}>{L(d.field || d.userType)}</span>
                                                {' '}
                                                {getLang() === 'ko' ? d.userName : d.englishName}
                                            </p>
                                            <ul className="i-col-0 keyword-a">{catElems}</ul>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>

                    <div className="main_doctor_txtbox">
                        <div className="wrapper">
                            <p className="main_doctor_txt01">{L('home-doctors-header')}</p>
                            <p className="main_doctor_txt02">{L('home-doctors-desc')}</p>
                        </div>
                    </div>
                </div>{/*main_cont*/}

                <div id="main_program" className="main_cont">
                    <p className="title_st01 dot" data-aos="fade-up">{L("O'Young Programs")}</p>

                    <div className="program_card-a">
                        <div className="program_thumb" data-aos="fade-up">
                            <div className="program_thumb_in"><img src={t_program_img01} alt="O'YOUNG Health Program" className="full_img" /></div>
                        </div>
                        <div className="program_inf">
                            <p className="program_expl" data-aos="fade-up">
                                {L('home-program-desc')}
                            </p>
                            <a
                                href="#talk-chat"
                                className="btn01"
                                data-aos="fade-up"
                                onClick={(e) => {
                                    if (state.user) {
                                        fetchGet('/customer/talkOyoung.do').then((result) => {
                                            if (result) {
                                                history.push('/talk-chat?id=' + result.id);
                                            }
                                        });
                                    } else {
                                        history.push('/sign?redirect=/talks');
                                    }
                                }}
                            >
                                {L('Consultation')}<img src={arrow_r_icon01} alt="" />
                            </a>
                        </div>
                    </div>
                </div>{/*main_cont*/}

                <div id="main_vital" className="main_cont">
                    <p className="title_st01 dot" data-aos="fade-up">{L('Smart Vital')}</p>
                    <div className="main_vital_banner" data-aos="fade-up">
                        <img src={main_vital_banner} alt="" className="resize_img main_vital_banner_img" />
                        <div className="main_vital_banner_txtbox">
                            <p className="main_vital_banner_txt01" dangerouslySetInnerHTML={{__html: L("<b>ONLY</b> at O'Young'")}}></p>
                            <p className="main_vital_banner_txt02">{L('Smart Vital')}</p>
                            <Link to="/my-health" className="main_vital_banner_btn f7-ignore"><img src={arrow_r_icon02} alt="" /></Link>
                        </div>
                    </div>
                </div>{/*main_cont*/}

                {/* <div id="main_trend" className="main_cont" data-aos="fade-up">
                    <p className="title_st01 dot">TREND</p>

                    {_trends.length && (
                        <Swiper
                            loop={true}
                            slidesPerView="auto"
                            centeredSlides={true}
                            pagination={{ el: '.main_trend_sl .swiper-pagination' }}
                            className="main_trend_sl"
                        >
                            {_trends.map((t) => {
                                const title = JSON.parse(t.i18nTitle || '{}')[getLang()];

                                return (
                                    <SwiperSlide key={t.id}>
                                        <div className="trend_card-a">
                                            <div className="trend_thumb">
                                                <img
                                                    src={
                                                        t.banner
                                                            ? '/upload/' + (t.banner.thumbnail || t.banner.path)
                                                            : PlaceholderImage
                                                    }
                                                    alt={title}
                                                    className="full_img"
                                                />
                                            </div>
                                            <p className="trend_hash">{L(t.category)}</p>
                                            <div className="trend_txtbox">
                                                <p className="trend_txt">{title}</p>
                                                <Link to={`/trends/${t.id}`} className="trend_btn f7-ignore">
                                                    <img src={arrow_r_icon02_w} alt="link" />
                                                </Link>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                            <div className="swiper-pagination"></div>
                        </Swiper>
                    )}

                </div> */}
                
                {/*main_cont*/}
            </div>
            {/*} Contents*/}

            <Footer index={0} />
        </div>
    );
}

function _showServerType() {
    if (global._showedServerBanner) return;

    let server = global.location.origin;
    if (server.indexOf('oyoung.co.kr') < 0 || server.indexOf('dev') >= 0)
        server = 'Test';
    else if (server.indexOf('stage') >= 0)
        server = 'Staging';
    else
        server = '';

    if (server) {
        global._showedServerBanner = true;
        $('#root').append(`<div class="server-banner"><span class="material-icons">build</span>${server}</div>`);
        setTimeout(() => $('.server-banner').fadeOut(() => $('.server-banner').remove()), 20000);
    }
}
