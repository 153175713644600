import { useEffect, useState } from 'react';

import img_safety from '../images/t-program_health_item01_safety.jpg';
import img_effectiveness from '../images/t-program_health_item01_effectiveness.jpg';
import img_convenience from '../images/t-program_health_item01_convenience.jpg';
import img_accessibillity from '../images/t-program_health_item01_accessibillity.jpg';

import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { fetchGet, getLang, L } from "../utils";

const _keys = ['PROGRAM_1', 'PROGRAM_2', 'PROGRAM_3', 'PROGRAM_4'];
const _images = [img_safety, img_effectiveness, img_convenience, img_accessibillity];

export default function Health1Page() {
    const [contents, setContents] = useState();

    useEffect(() => {
        fetchGet('/api/contents?keys=' + _keys.join(',')).then((result) => {
            const contents = {};
            result.forEach((c) => {
                c.title = JSON.parse(c.i18nTitle);
                c.content = JSON.parse(c.i18nContent);
                contents[c.contentKey] = c;
            });
            setContents(contents);
        });
    }, []);

    return (
        <div id="container">
            <Header />

            <div id="program_health_values" className="sub">
                <Navbar title={L('Program Values')} />

                <div className="health_dt_cont">
                    {_keys.map((k, idx) => {
                        const c = contents && contents[k];
                        if (!c) return '';

                        const title = c.title[getLang()] || c.title.en;

                        return (
                            <div className="health_dt_sec" key={k}>
                                <div className="wrapper">
                                    <p className="health_dt_tit dot" data-num={idx + 1}><span>{title}</span></p>
                                    <div className="health_dt_img"><img src={_images[idx]} alt={title} /></div>
                                    <div className="health_dt_expl" dangerouslySetInnerHTML={{ __html: c.content[getLang()] || c.content.en }}></div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <Footer index="1" />
        </div>
    );
}
