import { useContext, useEffect, useState } from "react";
import $ from 'jquery';
import { f7 } from "framework7-react";

// import myhealth_view_list_icon from '../images/myhealth_view_list_icon.png';
// import myhealth_view_list_icon_ov from '../images/myhealth_view_list_icon_ov.png';

import Header from "./Header";
import Footer from "./Footer";
import { C, fetchGet, formatDateTime, getLang, L } from "../utils";
import { store } from "../Store";
import Navbar from "./Navbar";

export default function MyHealthSubPage({title, documentKind}) {
    const { state } = useContext(store);
    const [loading, setLoading] = useState(true);
    const [documents, setDocuments] = useState([]);
    const [months, setMonths] = useState([]);
    const [currMonth, setCurrMonth] = useState();
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if (documentKind === 'a-la-carte') {
            Promise.all([
                fetchGet('/json/categories.json').then((result) => result.HEALTH_CARE),
                fetchGet('/guest/products.do?category=HEALTH_CARE'),
            ]).then((result) => {
                const services = result[1].products;
                services.forEach(function(p) {
                    var names = JSON.parse(p.i18nProductName);
                    p.productName = names[getLang()] || names.en;
                });
                services.sort(function(a, b) {
                    return a.productName < b.productName ? -1 : (a.productName > b.productName ? 1 : 0);
                });
                const categories = result[0].map((c) => ({
                    name: c,
                    services: services.filter(function(p) {
                        return p.publishingRequest === 'Approved' && p.subcategory === c;
                    }),
                }));
                setCategories(categories);
            });
        }

        if (state.user) {
            setLoading(true);
            fetchGet(`/customer/api/customerDocuments?customerId=${state.user.id}&kind=${documentKind}`)
                .then((result) => {
                    result.sort((a, b) => a.documentDate < b.documentDate ? 1 : (a.documentDate > b.documentDate ? -1 : 0));
                    const map = {};
                    result.forEach((r) => {
                        map[r.documentDate.slice(0, 7)] = 1;
                    });
                    const arr = Object.keys(map);
                    arr.sort((a, b) => a < b ? 1 : (a > b ? -1 : 0));
 
                    setDocuments(result);
                    setMonths(arr);
                    setCurrMonth(arr[0]);

                    setLoading(false);
                });
        }

        $('.myhealth_list_ct_expl').on('click', 'img', function() {
            fileViewer(this, [this.src]);
        });
    }, [documentKind, state]);

    const filtered = documents.filter((d) => d.documentDate.startsWith(currMonth));

    const toggleItem = (e) => {
        $(e.currentTarget).parent()
            .toggleClass('active')
            .children('.myhealth_list_ctbox')
            .slideToggle('fast')
            .parent()
            .siblings()
            .removeClass('active')
            .children('.myhealth_list_ctbox')
            .slideUp('fast');
    };

    return (
        <div id="container">
            <Header />

            <div id="checkup_list" className="sub mypage">
                <Navbar title={L(title)} />

                <div className="i-col-0 myhealth_view_type">
                    {/* <div className="myhealth_view_type_btn">
                        <ul className="i-col-0 myhealth_view_type_btn_ul">
                            <li className="active">
                                <a href="./smartvital_list.html" className="myhealth_view_type_btn_inner myhealth_view_list_btn">
                                    <img src="../../images/myhealth_view_list_icon.png" alt="" className="myhealth_view_icon" />
                                    <img src="../../images/myhealth_view_list_icon_ov.png" alt="" className="myhealth_view_icon_ov" />
                                </a>
                            </li>
                            <li>
                                <a href="./smartvital_calendar.html" className="myhealth_view_type_btn_inner myhealth_view_calendar_btn">
                                    <img src="../../images/myhealth_view_calendar_icon.png" alt="" className="myhealth_view_icon" />
                                    <img src="../../images/myhealth_view_calendar_icon_ov.png" alt="" className="myhealth_view_icon_ov" />
                                </a>
                            </li>
                        </ul>
                    </div> */}

                    <ul className="i-col-0 myhealth_view_month">
                        {months.map((m) => (
                            <li key={m} className={m === currMonth ? 'active' : ''}>
                                <a href="#month" onClick={() => setCurrMonth(m)}>{m}</a>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="myhealth_list_cont">
                    {filtered.length > 0 ? (
                        <div className="wrapper">
                            <p className="myhealth_month_title">
                                {new Date(currMonth).toLocaleDateString(getLang(), {year:'numeric', month: getLang() === 'ko' ? 'long' : 'short'})}
                            </p>
                            <div className="myhealth_list_wrap">
                                {filtered.map((d) => {
                                    return (
                                        <div key={d.id} className="myhealth_list_box">
                                            <div className="myhealth_list_subjbox" onClick={toggleItem}>
                                                <p className="myhealth_list_subj">{d.subject}</p>
                                                <p className="myhealth_list_time">{formatDateTime(d.documentDate)}</p>
                                            </div>
                                            <div className="myhealth_list_ctbox" style={{width: '100%', overflowX: 'auto'}}>
                                                {documentKind === 'measurement' && measurementData(d.data)}
                                                {documentKind === 'a-la-carte' && aLaCarte(d.data, categories)}

                                                <div className="myhealth_list_ct_expl">
                                                    <div dangerouslySetInnerHTML={{__html: d.data.NOTE}} />
                                                </div>

                                                {d.files && d.files.length ? (
                                                    <div className="myhealth_list_ct_btnbox">
                                                        {d.files.map((f) => (
                                                            <button
                                                                key={f.id}
                                                                className="btn03 myhealth_list_ct_download"
                                                                onClick={(e) => {
                                                                    fileViewer(e.target, [global.location.origin + '/upload/' + f.path]);
                                                                }}
                                                            >
                                                                {f.name}
                                                            </button>
                                                        ))}
                                                    </div>
                                                ) : ''}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )
                    : (loading ? '' : <div className="wrapper"><p>{L('No document found')}</p></div>)}
                </div>
            </div>

            <Footer />

        </div>
    );
}

function measurementData(data) {
    const arr = JSON.parse(data.LIST);
    const KEYS = [
        'height', 'weight', 'temperature', 'systolic', 'diastolic', 'pulse',
        'respirtory', 'bmi', 'fat', 'muscle', 'water', 'visceral', 'bone',
    ];

    return (
        <div className="myhealth_tb_wrap">
            <table className="myhealth_tb">
                <thead>
                    <tr>
                        <th>{L('No.')}</th>
                        {arr.map((a) => (
                            <td key={a.sequence}>{a.sequence}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>{L('Time')}</th>
                        {arr.map((a, idx) => (
                            <td key={`time-${idx}`}>{a.time}</td>
                        ))}
                    </tr>
                    {KEYS.map((k) => (
                        <tr key={k}>
                            <th>{L(k.toUpperCase())}</th>
                            {arr.map((a, idx) => (
                                <td key={`${k}-${idx}`}>{a[k] && (+a[k]).toLocaleString()}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

function aLaCarte(data, categories) {
    const rows = [];

    categories.forEach((c) => {
        const subRows = [];

        c.services.forEach((s) => {
            if (data[s.id]) {
                subRows.push(
                    <tr key={s.id}>
                        <td colSpan="2">{s.productName}</td>
                        <td className="text-right">{data[s.id]}</td>
                        <td className="text-right">{data[s.id] ? C(data[s.id] * s.baseSalePrice, s.currency) : ''}</td>
                    </tr>
                );
            } else if (s.options && s.options.length) {
                const subsubRows = [];

                s.options.forEach((o, idx1) => {
                    o.items.forEach((i, idx2) => {
                        const key = `${s.id}-${idx1}-${idx2}`;
                        if (data[key]) {
                            subsubRows.push(
                                <tr key={key}>
                                    <td colSpan="2">{i[getLang()] || i.en}</td>
                                    <td className="text-right">{data[key]}</td>
                                    <td className="text-right">{data[key] ? C(data[key] * (s.baseSalePrice + i.price), s.currency) : ''}</td>
                                </tr>
                            );
                        }
                    });
                });

                if (subsubRows.length) {
                    subRows.push(<tr key={s.id}><th colSpan="4">{s.productName}</th></tr>);
                    subRows.splice(1, 0, subsubRows);
                }

            }
        });

        if (subRows.length) {
            rows.push(<tr key={c.name}><th colSpan="4">{L(c.name)}</th></tr>);
            rows.splice(rows.length, 0, subRows);
        }
    });

    return (
        <table className="health-suggestion">
            <tbody>
                {rows}
            </tbody>
            <tfoot>
                <tr>
                    <th colSpan="3">{L('Total')}</th>
                    <td className="text-right">{data.total}</td>
                </tr>
            </tfoot>
        </table>
    );
}

const fileViewer = (obj, files) => {
    if (!obj || !files || !files.length)
        return;

    let browser = obj.photoBrowser;
    if (!browser)
        browser = obj.photoBrowser = f7.photoBrowser.create({
            photos: files.map(f => {
                const ext = f.toLowerCase();
                if (ext.endsWith('.jpeg') || ext.endsWith('.jpg')
                    || ext.endsWith('.png') || ext.endsWith('.gif'))
                    return f;
                else
                    return {
                        html: '<iframe src="https://docs.google.com/viewer?embedded=true&url='
                            + f
                            + '" style="height:' + (global.innerHeight - 130)
                            + 'px;" frameborder="0"></iframe>'
                    };
            })
        });
    browser.open();
}

