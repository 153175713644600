import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Framework7 from 'framework7/lite-bundle';
import Framework7React from 'framework7-react';

import 'material-design-icons/iconfont/material-icons.css';
import 'framework7/framework7.css';
import 'framework7/components/swiper/swiper.css';
import 'framework7/components/sheet/sheet.css';
import 'framework7/components/popup/popup.css';
import 'framework7/components/photo-browser/photo-browser.css';
import 'framework7/components/dialog/dialog.css';
import 'framework7/components/preloader/preloader.css';
import 'framework7/components/popover/popover.css';
import 'framework7/components/calendar/calendar.css';
import 'framework7/components/notification/notification.css';
import './App.css';

import App from './App';
import { StateProvider } from './Store.js';
import LocationHandler from './components/LocationHandler';

Framework7.use(Framework7React);

ReactDOM.render(
    <React.StrictMode>
        <StateProvider>
            <BrowserRouter basename="/app">
                <LocationHandler />
                <App />
            </BrowserRouter>
        </StateProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

