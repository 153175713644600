import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { fetchGet, getLang, L } from "../utils";

export default function QnaPage() {
    const [content, setContent] = useState();

    useEffect(() => {
        fetchGet('/api/contents/OYOUNG_QNA').then((result) => {
            result.content = JSON.parse(result.i18nContent);
            setContent(result);
        });
    }, []);

    return (
        <div id="container">
            <Header />

            <div id="program_health_process" className="sub">
                <Navbar title={L('Q & A')} />

                {content && (
                    <div
                        className="wrapper"
                        dangerouslySetInnerHTML={{__html: content.content[getLang()] || content.content.en}}
                        style={{margin: '2em 0'}}
                    />
                )}
            </div>

            <Footer />
        </div>
    );
}
