import { f7, List, ListItem, Popover } from "framework7-react";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import $ from 'jquery';

import talk_chat_msg_receive_img from '../images/talk_chat_msg_receive_img.png';
import fileIcon from '../images/file_icon.svg';
import translate_icon from '../images/translate_icon.svg';

import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { C, confirm, drawImageToCanvas, fetchGet, fetchPost, formatDate, formatDateTime, formatRelativeTime, formatYmd, FX, getLang, handleFetchError, L, loadImageFile } from "../utils";
import { store } from "../Store";
import { PHOTO_QUALITY } from "../const";
import { handleAskQuote, handleLeaveTalk, handleSendMessage } from "../Services";

export default function TalkChatPage() {
    const { state } = useContext(store);
    const loc = useLocation();
    const history = useHistory();
    const [talk, setTalk] = useState({});
    const [consultant, setConsultant] = useState({});
    const [messages, setMessages] = useState([]);
    const [last, setLast] = useState(true);
    const [files, setFiles] = useState([]);
    const [fileListOpen, setFileListOpen] = useState(false);
    const [language, setLanguage] = useState(getLang());

    global.loadTalkChat = (id) => {
        fetchGet('/customer/api/talks/' + id).then((result) => {
            setTalk(result.talk);
            setConsultant(result.consultant);
            setMessages(result.messages.content);
            setLast(result.messages.last);

            scrollToBottom();
        });
    };

    useEffect(() => {
        const params = f7.utils.parseUrlQuery(loc.search);
        if (!params.id)
            history.push('/talks');

        global.loadTalkChat(params.id);
    }, [loc, history]);

    useEffect(() => {
        function onScroll() {
            if (last || this.loading || document.documentElement.scrollTop !== 0)
                return;

            this.loading = true;
            const lastMessage = messages[0];
            const lastTime = lastMessage.createdAt;
            const offsetTop = $('#msg' + lastMessage.id).prop('offsetTop');

            f7.preloader.show();

            fetchGet('/customer/api/talks/' + talk.id + '?before=' + lastTime).then(result => {
                setMessages([...result.messages.content, ...messages]);
                setLast(result.messages.last);

                f7.preloader.hide();

                const newTop = $('#msg' + lastMessage.id).prop('offsetTop');
                $(document.documentElement).prop('scrollTop', newTop - offsetTop + 0);

                this.loading = false;
            });
        };

        $(window).on('scroll', onScroll);

        return () => $(window).off('scroll', onScroll);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [talk.id, last]);

    useEffect(() => {
        files.forEach(async (f, i) => {
            let dataUrl = await loadImageFile(f);
            let img = new Image();
            img.src = dataUrl;
            img.onload = () => drawImageToCanvas(img, document.querySelector('.message-photo-' + i), 1024);
        });
    }, [files]);

    let hasButtons;
    // let consultantTitle;
    let categories;

    if (talk.consultantType === 'partner-member'
        || talk.consultantType === 'coordinator') {
        hasButtons = true;
        // consultantTitle = consultant.field;
        categories = consultant.categories ? consultant.categories.split(';') : [];
    } else if (talk.consultantType === 'manager'
        || talk.consultantType === 'admin') {
        hasButtons = false;
        // consultantTitle = 'Customer service number';
    } else {
        hasButtons = true;
        // consultantTitle = consultant.service;
        categories = consultant.categories ? consultant.categories.split(';') : [];
    }

    const handleChangeFile = () => {
        // 선택한 파일이 기존에 있던 파일인지 검사해서 덮어쓸지 추가할지 결정
        let input = document.querySelector('#fileFiles');
        for (let j = 0; j < input.files.length; ++j) {
            let exists = false;

            for (let i = 0; i < files.length; ++i) {
                if (input.files[j].name === files[i].name) {
                    exists = true;
                    files[i] = input.files[j];
                    break;
                }
            }

            if (!exists)
                files.push(input.files[j]);
        }

        if (files.length)
            setFiles([...files]);
        input.value = '';
    };

    const removeFile = (e) => {
        let idx = $(e.target).parent().index();
        files.splice(idx, 1);
        setFiles([...files]);
    };

    const askQuote = () => {
        let categories = $('#ask-price-popover input[name=category]:checked');
        if (!categories.length) {
            alert(L('Please choose one or more categories to ask price.'));
            return;
        }

        confirm(L('Send "Ask price"?'), async () => {
            let arr = [];
            categories.each((i, e) => arr.push(e.value));
            $('#ask-price-popover input[name=category]').prop('checked', false);
            const result = await handleAskQuote(talk.id, arr);
            if (result.message) {
                messages.push(result.message);
                setMessages(messages);
                scrollToBottom(true);
            }
        });
    }

    const leaveTalk = async () => {
        let leaveReason = document.querySelector('input[name=leave-talk]:checked').value;
        await handleLeaveTalk(talk.id, leaveReason);
        talk.closed = true;
        setTalk(talk);

        history.push('/talks');
    }
    
    const send = (e) => {
        e.preventDefault();

        let message = $('#txtMessageToSend').val();
        let photos = document.querySelectorAll('.message-photo');

        if (!message && !photos.length) return false;

        if (message)
            message = message
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/"/g, '&quot;')
                .replace(/\n/g, '\n<br>');

        let p;
        if (photos.length) {
            let photoBlobs;

            let photoPromises = Array.prototype.map.call(photos, c => new Promise(resolve => {
                c.toBlob(resolve, 'image/jpeg', PHOTO_QUALITY);
            }));

            p = Promise.all(photoPromises).then(blobs => {
                photoBlobs = blobs;
                let filenames = Array.prototype.map.call(photos, c => c.getAttribute('alt'));
                return handleSendMessage(talk.id, message, photoBlobs, filenames);
            });
        } else {
            p = handleSendMessage(talk.id, message);
        }

        p.then((message) => {
            messages.push(message);
            setMessages([...messages]);

            scrollToBottom(true);
        });

        // 메시지를 보냈으면 작성했던 내용과 첨부 파일 지우기
        $('#txtMessageToSend').val('');//.css('height', 'var(--f7-messagebar-textarea-height)');
        setFiles([]);
        setFileListOpen(false);

        return false;
    }

    return (
        <div id="container">
            <Header />
            <div id="talk_chat" className="sub mypage">
                <Navbar title={getLang() === 'ko' ? talk.consultantName : talk.consultantEnglishName} />

                <div className="talk_chat_wrap">
                    <div className="talk_chat_keyword">
                        {categories && categories.length ? (
                            <div className="wrapper">
                                <ul className="i-col-0 talk_chat_keyword_ul">
                                    {categories.map((c) => (
                                        <li key={c}><span>{L(c)}</span></li>
                                    ))}
                                </ul>
                            </div>
                        ) : ''}
                        <div className="talk_chat_msg_btn">
                            <div className="wrapper" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <button className="btn02 language popover-open" data-popover="#language-popover">{L('Language')}</button>
                                {hasButtons && categories && (
                                    <button className="btn02 ask_price popover-open" data-popover="#ask-price-popover">{L('Ask Price')}</button>
                                )}
                                {hasButtons && !talk.closed && (
                                    <button className="btn02 leave_talk popup-open" data-popup=".popup-leave">{L('Leave Talk')}</button>
                                )}
                            </div>
                        </div>
                    </div>

                    <MessageList
                        messages={messages}
                        user={state.user}
                        language={language}
                    />

                    <div className={'talk_chat_msg_write ' + (fileListOpen ? 'active' : '')}>
                        {talk.closed
                            ? <div style={{margin:'16px', textAlign: 'center'}}>{L('This talk is closed')}</div>
                            : (
                            <div className="wrapper">
                                <a
                                    href="#more"
                                    className="chat_more_btn"
                                    onClick={() => {
                                        if (!fileListOpen)
                                            $('#fileFiles').trigger('click');
                                        setFileListOpen(!fileListOpen);
                                    }}
                                >+</a>
                                <input id="fileFiles" type="file" className="file-photos" accept=".jpeg,.jpg,.png,.gif" multiple 
                                        style={{display: 'none'}} onChange={handleChangeFile} />
                                <form id="" name="" action="" className="talk_chat_msg_write_f" onSubmit={send}>
                                    <textarea
                                        id="txtMessageToSend"
                                        className="inpt01 resizable"
                                        style={{maxHeight: '160px'}}
                                        placeholder={L("Write a comment")}
                                    />
                                    <button type="submit" className="btn02">{L('Send')}</button>
                                </form>
                            </div>
                        )}
                        <div className="wrapper messagebar-sheet" style={{display: fileListOpen ? '' : 'none'}}>
                        {files.map((f, idx) => (
                            <div key={f.name} className="messagebar-sheet-item" onClick={removeFile}>
                                <canvas alt={f.name} className={'message-photo message-photo-' + idx} />
                                <span className="messagebar-attachment-delete"></span>
                            </div>
                        ))}
                        </div>
                    </div>

                    <Popover id="language-popover">
                        <List>
                            <ListItem><h3>{L('Preferred Language')}</h3></ListItem>
                            <ListItem link="#original" popoverClose title={L('Original message') + (language === 'original' ? ' √' : '')} onClick={() => setLanguage('original')} />
                            <ListItem link="#ko" popoverClose title={L('Korean language') + (language === 'ko' ? ' √' : '')} onClick={() => setLanguage('ko')} />
                            <ListItem link="#vi" popoverClose title={L('Vietnamese language') + (language === 'vi' ? ' √' : '')} onClick={() => setLanguage('vi')} />
                            <ListItem link="#en" popoverClose title={L('English language') + (language === 'en' ? ' √' : '')} onClick={() => setLanguage('en')} />
                        </List>
                    </Popover>
                    {hasButtons && categories && (
                        <AskPricePopover categories={categories} askQuote={askQuote}/>
                    )}

                    <div className="popup popup-leave"style={{padding: '0 15px'}}>
                        <div className="block text-align-center D_text_title" >
                        {
                            L('Do you want to leave this talk? '
                            + 'You will not receive message from this consultant unless you send '
                            + 'new message again.')
                        }
                        </div>
                        <div className="list">
                            <ul>
                                <li>
                                    <label className="item-radio item-content">
                                        <input type="radio" name="leave-talk" value="Expensive" defaultChecked />
                                        <i className="icon icon-radio"></i>
                                        <div className="item-inner">
                                        <div className="item-title">{L('Expensive')}</div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <label className="item-radio item-content">
                                        <input type="radio" name="leave-talk" value="Not interested anymore"/>
                                        <i className="icon icon-radio"></i>
                                        <div className="item-inner">
                                        <div className="item-title">{L('Not interested anymore')}</div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <label className="item-radio item-content">
                                        <input type="radio" name="leave-talk" value="I am not sure yet"/>
                                        <i className="icon icon-radio"></i>
                                        <div className="item-inner">
                                        <div className="item-title">{L('I am not sure yet')}</div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div className="consulting-buttons">
                            <button className="btn02 popup-close" style={{width: '140px'}} onClick={leaveTalk}>{L('Ok, leave')}</button>
                            <button className="btn02 popup-close" style={{width: '80px'}}>{L('Cancel')}</button>
                        </div>
                    </div>

                </div>
            </div>

            <Footer index="0" upButtonClass="hidden" />
        </div>

    );
}

const AskPricePopover = ({categories, askQuote}) => {
    return (
        <div id="ask-price-popover" className="popover">
            <div className="popover-angle"></div>
            <div className="popover-inner">
                <div className="list">
                    <ul>
                        <li className="item-content"><h3>{L('Choose category')}</h3></li>
                    {
                        categories.map(c => (
                            <li key={c}>
                                <label className="item-checkbox item-content">
                                    <input type="checkbox" name="category" value={c} />
                                    <i className="icon icon-checkbox"></i>
                                    <div className="item-inner">
                                        <div className="item-title">{L(c)}</div>
                                    </div>
                                </label>
                            </li>
                        ))
                    }
                    </ul>
                </div>
                <div className="block" style={{margin: '15px 0'}}>
                    <div className="row">
                        <button className="btn02 popover-close" onClick={askQuote}>{L('Send')}</button>
                        <button className="btn02 popover-close">{L('Cancel')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function MessageList({ messages, onSelectDate, user, language }) {
    let prevDay;
    let messagesElements = [];

    const booking = (m, data) => {
        // global.app.actions.create({
        //     buttons: [
        //         {
        //             text: L('Book Available'),
        //             label: true
        //         },
        //         ...data.dates.map(d => ({
        //             text: formatDate(d),
        //             onClick: () => onSelectDate(m, d)
        //         }))
        //     ],
        //     on: {
        //         closed: ac => ac.destroy()
        //     }
        // }).open();
    };

    const requestTranslation = id => {
        confirm(L('Request O\'YOUNG to translate this message?'), () => {
            let formData = new FormData();
            formData.append("messageId", id);
    
            fetchPost("/customer/translationRequest.do", formData)
                .then(result => {
                    if (result.errors)
                        throw result;
                    alert(L('Your translation request has been sent.'));
                })
                .catch(handleFetchError);
        });
    }

    messages.forEach(m => {
        let day = formatDate(m.createdAt);
        if (day !== prevDay) {
            prevDay = day;
            messagesElements.push(<div key={day} className="talk_chat_msg_date">{day}</div>);
        }
        let data = m.jsonData ? JSON.parse(m.jsonData) : {};

        if (!m.i18n)
            m.i18n = m.i18nMessage ? JSON.parse(m.i18nMessage) : {};
        let msg = language === 'original' ? m.message : (m.i18n[language] || m.message || m.i18n.en);

        let photo = (m.talkerType === 'manager' || m.talkerType === 'admin') ? '/upload/admin/ask-oyoung.png'
            : (
                m.talkerId === user.id ? '' : (
                    !m.talkerPicture
                        ? talk_chat_msg_receive_img
                        : '/upload/' + (m.talkerPicture.thumbnail || m.talkerPicture.path)
                )
            );

        if (photo)
            photo = <Link to={`/doctors/${m.talkerId}`} className="f7-ignore"><img src={photo} alt={m.talkerName} /></Link>;

        let items = [];

        if (m.messageType === 'START' && data.bookDate)
            items.push(
                <div key="1" className="message-text" style={{ marginBottom: '1em' }}>
                    {
                        L('Booked video consultation at {}',
                            new Date(data.bookDate).toLocaleString(getLang(), {
                                year: 'numeric', month: getLang() === 'ko' ? 'numeric' : 'short', day: 'numeric',
                                hour: 'numeric', minute: '2-digit', weekday: 'short', timeZoneName: 'long'
                            }))
                    }
                </div>
            );

        items.push(<div key="2" className="message-text" dangerouslySetInnerHTML={{ __html: msg }} />);

        if (m.messageType === 'ESTIMATE' || m.messageType === 'DECIDE' || m.messageType === 'REJECT')
            items.push(<div key="3" className="message-text">{PriceTable(m, user)}</div>);

        if (m.uploads) {
            items = items.concat(
                m.uploads.map((u, idx) => {
                    if (u.ext === 'jpg' || u.ext === 'jpeg' || u.ext === 'png' || u.ext === 'gif')
                        return (
                            <div key={u.id} className="message-image" onClick={() => fileViewer(m, m.uploads, idx)}>
                                <img src={'/upload/' + (u.thumbnail || u.path)} alt={u.name} />
                            </div>
                        );
                    else
                        return (
                            <div key={u.id} className="message-image file" onClick={() => fileViewer(m, m.uploads, idx)}>
                                <img src={fileIcon} alt={u.name} width="90" className="file_icon" />
                                <div style={{marginTop: '1em'}}>{u.name}.{u.ext}</div>
                            </div>
                        );
                })
            );
        }

        if (m.messageType === 'PRODUCT')
            items.push(
                <Link key={data.id} to={`/product?category=${data.productCategory}&product=${data.id}`} className="message-image f7-ignore">
                    <img
                        src={'/upload/' + (data.banners[0].thumbnail || data.banners[0].path)}
                        alt={data.banners[0].name}
                        onLoad={() => scrollToBottom()}
                    />
                    <div>{JSON.parse(data.i18nProductName)[getLang()]}</div>
                </Link>
            );

        let button;
        if (m.messageType === 'QUOTE')
            button = <Link to="/price-box" className="btn02" key="price">{L('Open Now')}</Link>;
        else if (m.messageType === 'AVAILABILITY') {
            if (data.selectedDate) {
                button = <ul key={`available-${data.selectedDate}`}>{data.dates.map((d, i) => {
                    try { d = formatDate(d); } catch (e) { /* 무시 */ }
                    return (
                        <li key={i}>{d}</li>
                    );
                })}</ul>;
            } else
                button = <button type="button" onClick={() => booking(m, data)} className="btn02" key="booking">{L('Book Available')}</button>;
        } else if (m.messageType === 'ASK_SCHEDULE')
            button = <Link to="/my-schedule" className="btn02" key="schedule">{L('Check Schedule')}</Link>;
        else if (m.messageType === 'HEALTH_QUESTIONNAIRE')
            button = <Link to="/health-questionnaire" className="btn02" key="questionnaire">{L('Patient Health Questionnaire')}</Link>;
        else if (m.messageType === 'PRICE_CONFIRMED' || m.messageType === 'PAYMENT_END' || m.messageType === 'ORDER_STATUS_CHANGED')
            button = null;//<Link to='#order-detail' onClick={() => openOrderDetail(data.orderId)} className="btn02" key="payment">{L('Order Detail')}</Link>;
        // else if (m.messageType === 'PAYMENT_END')
        //     button = <Link to="/receipt-list" className="btn02" key="payment-end">{L('View Receipt')}</Link>;
        else if (m.messageType === 'GO_SERVICE')
            button = <Link to="/services" className="btn02" key="go-services">{L('Services')}</Link>;

        if (button)
            items.push(button);

        const time = <time dateTime={m.createdAt} onClick={() => alert(formatDateTime(m.createdAt))}>{formatRelativeTime(m.createdAt)}</time>;

        if (m.talkerId === user.id) {
            messagesElements.push(
                <div id={'msg' + m.id} key={m.id} className="talk_chat_msg_send">
                    <span className="talk_chat_msg_send_time">{time}</span>
                    <div className="talk_chat_msg_send_text">
                        {items}
                    </div>
                </div>
            );

        } else {
            messagesElements.push(
                <div id={'msg' + m.id} key={m.id} className="talk_chat_msg_receive">
                    <div className="talk_chat_msg_receive_img">{photo}</div>
                    <div className="talk_chat_msg_receive_text">
                        {items}
                    </div>
                    <div className="msg_translate" onClick={() => requestTranslation(m.id)}>
                        <img src={translate_icon} width="30" alt="translate" />
                    </div>
                    <span className="talk_chat_msg_receive_time">{time}</span>
                </div>
            );
        }
    });

    return (
        <div className="talk_chat_msg_cont">
            {messagesElements}
        </div>
    );
}

const PriceTable = (msg, user) => {
    let items;

    try {
        items = JSON.parse(msg.jsonData);
    } catch (e) {
        console.log(e);
        return;
    }

    const now = formatYmd(new Date());
    let customerCurrency = user.currency || (getLang() === 'vi' ? 'VND' : 'USD');
    let customerSum = 0;

    return (
        <div className="price-table">
            <table>
                <thead>
                    <tr>
                        <td>{L('Category')}</td>
                        <td>{L('Estimate')}</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map((d, idx) => {
                            const currency = d.currency || 'KRW';
                            let amount = +(d.amount || d.price);
                            let customerAmount = FX(amount, currency);
                            customerSum += customerAmount;

                            return (
                                <tr key={idx}>
                                    <td>{L(d.category)}</td>
                                    <td>
                                        {C(d.amount || d.price, currency)}<br />
                                        {customerAmount
                                            && now <= d.untilYmd
                                            && <div>{C(customerAmount)}</div>
                                        }
                                    </td>
                                </tr>
                            );
                        })
                    }
                    {
                        items.length > 1 && (
                            <tr>
                                <td className="sum">{L('Sum in {}', customerCurrency)}</td>
                                <td>
                                    {customerSum ? C(customerSum) : ''}
                                </td>
                            </tr>
                        )
                    }
                </tbody>
                {
                    items.length > 0 && (
                        <tfoot>
                            <tr>
                                <td>{L('Expires')}</td>
                                <td>{formatDate(items[0].untilYmd)}</td>
                            </tr>
                        </tfoot>
                    )
                }
            </table>
        </div>
    );
}

// let _lastScrollTop;

const scrollToBottom = (animate) => {
    let el = document.documentElement;
    if (el) {
        if (animate) {
            $(el).stop().animate({ scrollTop: el.scrollHeight }, 600, () => {
                // _lastScrollTop = el.scrollTop;
            });
        } else {
            el.scrollTop = el.scrollHeight;
            // _lastScrollTop = el.scrollTop;
        }
    }
}

const fileViewer = (msg, files, idx) => {
    if (!msg || !files || !files.length)
        return;

    let browser = msg.photoBrowser;
    if (!browser)
        browser = msg.photoBrowser = f7.photoBrowser.create({
            photos: files.map(f => {
                if (f.ext === 'jpeg' || f.ext === 'jpg' || f.ext === 'png' || f.ext === 'gif')
                    return '/upload/' + f.path;
                else
                    return {
                        html: '<iframe src="https://docs.google.com/viewer?embedded=true&url='
                            + global.location.origin + '/upload/' + f.path
                            + '" style="height:' + (global.innerHeight - 130)
                            + 'px;" frameborder="0"></iframe>'
                    };
            })
        });
    browser.open(idx);
}

