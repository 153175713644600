const convertEnglishLower = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
}

export const allSearchAl = (query, type, list, consultantLikes) => {
    query = query || '';

    let arrayQuery = query.split(' ')
    let isMatchedQuery = [...arrayQuery].fill(false);
    let queryLength = arrayQuery.length;
    let results = [];

       
    /*
    * 검색 점수가 높은 순서로 정렬
    * 모든 검색어가 정확히 일치하면 3점 x 개수
    * 영어로 변환 후 일치하면 일치하면 1점 x 개수
    */
    const makeScore = (eachList) => {

        let exactlyEqualNum = 0;
        let similarEqualNum = 0;
        
        arrayQuery.forEach( (q, i) => {
            
            // 한 단어가 정확하게 일치하는 경우
            // accents가 붙어있는 경우 정규식에서 \b를 사용해도 동작 안함
            // let exactlyWord = new RegExp(`\\b${q}\\b`);
            let exactlyWord = new RegExp(`^${q}|^${q}$|\\s${q}\\s|\\s${q}$`);
            if (eachList._search.search(exactlyWord) >= 0){
                isMatchedQuery[i] = true;
                exactlyEqualNum++;
            
            // 부분 일치하거나 영어로 변환 후 일치하는 경우
            }else if(eachList._search.search(q) >= 0) {
                similarEqualNum++;
            }else if (convertEnglishLower(eachList._search).indexOf( convertEnglishLower(q) ) >= 0) {
                similarEqualNum++;
            }
        });

        if(exactlyEqualNum > 0 || similarEqualNum > 0) {
            eachList.score = (exactlyEqualNum * 3) + similarEqualNum;
            return true;
        }else {
            return false;
        }

    }

    results = list.filter(i => {
        if (type === 'partner' || type === 'partner-member') {
            return i.userType === type && queryLength > 0 && makeScore(i);
        } else if (type === 'trend') {
            return typeof i.content !== 'undefined' && queryLength > 0 && makeScore(i);
        } else if(type === 'favorite') {
            return consultantLikes.some(l => l.id === i.id)
                && (queryLength === 0 || makeScore(i));
        } else {
            return queryLength > 0 && makeScore(i);
        }
    });

    results.keyword = query;

    // 정확히 일치하지 않은 keyword들
    results.noMatchKeyword = isMatchedQuery.reduce((acc, cur, i) => {
        if(cur === false){
            return acc + ", \"" + arrayQuery[i]+"\"";
        }
        return acc + "";
    },"").slice(2,);
    results = results.sort(function(a,b){
        return a.score < b.score ? 1 : a.score > b.score ? -1 : 0;
    });
    return results;
}