import { f7 } from 'framework7-react';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import camera_icon01 from '../images/camera_icon01.png';
import question_mark_icon01 from '../images/question_mark_icon01.png';
import right_arrow from '../images/arrow_r_icon03.png';

import { confirm, getLang, L } from '../utils';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import PictureForm from '../components/PictureForm';
import { store } from '../Store';
import { Link } from 'react-router-dom';
import { handleStartTalk } from '../Services';

export default function TalkRequestPage() {
    const { state } = useContext(store);
    const loc = useLocation();
    const history = useHistory();
    const [consultant, setConsultant] = useState({});
    const [form, setForm] = useState({
        question: '',
        pictures: [],
    });

    useEffect(() => {
        const params = f7.utils.parseUrlQuery(loc.search);
        const id = params.consultant;
        const c = state.consultants.find((c) => c.id === id) || {};
        setConsultant(c);

        if (!c.id) 
            alert(L('Consultant not found. Please select another consultant'));

        if (params.category) {
            form.category = params.category;
            setForm({...form});
        }
            
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loc.search, state.consultants]);


    const send = e => {
        e.preventDefault();

        if (!form.category || !form.question) {
            document.newMessageForm.checkValidity();
            alert(L('Please fill out required fields.'));
            return;
        }

        confirm(
            L(
                'Send a message to {} {}?',
                L(consultant.field),
                getLang() === 'ko' ? consultant.userName : consultant.englishName
            ),
            () => {
                handleStartTalk([consultant.id], form, loc.state, form.pictures).then((result) => {
                    let url;
                    if (result.length > 1) {
                        url = loc.state ? "/consultations" : "/talks";
                    } else {
                        url = loc.state ? '/consultations/' + result[0].id : '/talk-chat?id=' + result[0].id;
                    }
                    history.replace(url);
                });
            }
        );

        return false;
    }


    return (
        <div id="container">
            <Header />

            <div id="book_consult_write" className="sub mypage">
                <Navbar title={L('New message')} />

                <div className="write_box bk_write_box">
                    <form name="newMessageForm" action="">
                        <div className="wBox">
                            <div className="wrapper">
                                <label htmlFor="bk_w_doctor" className="wBox_title">{L('Doctor')}</label>
                                <Link
                                    to={`/doctors/${consultant.id}`}
                                    className="f7-ignore"
                                    style={{ display: 'block', lineHeight: '50px', padding: '0 1.5rem' }}
                                >
                                    {L(consultant.field)} {getLang() === 'ko' ? consultant.userName : consultant.englishName}
                                    <img src={right_arrow} alt="right arrow" width="16" style={{ marginLeft: '12px', verticalAlign: 'inherit' }} />
                                </Link>
                                <ul className="i-col-0 keyword-b rv_w_keyword"></ul>
                            </div>
                        </div>
                        <div className="wBox">
                            <div className="wrapper item-input">
                                <label htmlFor="bk_w_category" className="wBox_title">{L('Category')}<span className="req"></span></label>
                                <select
                                    name="bk_w_category" id="bk_w_category" className="selectbox selectbox_full"
                                    required validate="true"
                                    value={form.category || ''}
                                    onChange={(e) => {
                                        form.category = e.target.value;
                                        setForm({...form});
                                    }}
                                >
                                    <option value="">{L('Select')}</option>
                                    {consultant.categories && consultant.categories.map((c) => (
                                        <option key={c} value={c}>{L(c)}</option>
                                    ))}
                                </select>
                                <ul className="i-col-0 keyword-b rv_w_keyword"></ul>
                            </div>
                        </div>
                        <div className="wBox">
                            <div className="wrapper item-input">
                                <label htmlFor="bk_w_question" className="wBox_title">{L('My question')}<span className="req"></span></label>
                                <div className="textar_length">
                                    <textarea
                                        name="bk_w_question"
                                        id="bk_w_question"
                                        className="textar01 textar_full"
                                        cols="20"
                                        rows="7"
                                        placeholder={L('Please write down...')}
                                        required validate="true"
                                        value={form.question}
                                        onChange={(e) => {
                                            form.question = e.target.value;
                                            setForm({ ...form });
                                        }}
                                        maxLength="5000"
                                    ></textarea>
                                    <span className="textar_length_num">{form.question.length}/5000</span>
                                </div>
                                <p className="bk_w_cf_txt">* {L('Please write down in detail what you are concerned about')}</p>
                            </div>
                        </div>

                        <div className="wBox rv_w_filebox">
                            <div className="wrapper">
                                <p className="wBox_title"><img src={camera_icon01} alt="" />{L('Attach Pictures')}</p>
                                <PictureForm
                                    pictures={form.pictures}
                                    setPictures={(pictures) => {
                                        form.pictures = pictures;
                                        setForm({...form});
                                    }}
                                />

                                <p className="bk_w_cf_txt">* {L('Please attach the photo of where you want to do plastic surgery')}</p>
                            </div>
                        </div>

                        <div className="wBox_more">
                            <div className="wrapper">
                                <div className="wBox_more_sec wBox_more_notice">
                                    <p className="wBox_more_notice_title">{L('More detail')}</p>
                                    <p className="wBox_more_notice_text">
                                        <img src={question_mark_icon01} alt="question mark" />{L('Make it clear for consultants to understand your case')}
                                    </p>
                                </div>

                                <div className="wBox_more_sec wBox_more_inf">
                                    <p className="wBox_more_inf_title">01.</p>
                                    <p className="wBox_more_inf_text">
                                        {L('Did you do surgery on it before? If you want to do rhinoplasty, please count including filler.')}
                                    </p>
                                    <select
                                        name="bk_w_addinf01" id="bk_w_addinf01" className="selectbox selectbox_full"
                                        value={form.surgeries || ''}
                                        onChange={(e) => {
                                            form.surgeries = e.target.value;
                                            setForm({...form});
                                        }}
                                    >
                                        <option value="">{L('Select')}</option>
                                        <option value="0">{L('없음')}</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">{L('4 or more')}</option>
                                    </select>
                                </div>

                                <div className="wBox_more_sec wBox_more_inf">
                                    <p className="wBox_more_inf_title">02.</p>
                                    <p className="wBox_more_inf_text">
                                        {L('In case of re-surgery, how long has it been since the last time of your surgery so far?')}
                                        <span>({L('For example: 8 years')})</span>
                                    </p>
                                    <input
                                        type="text" name="bk_w_addinf02" id="bk_w_addinf02" className="inpt02 inpt_full"
                                        value={form.lastSurgery || ''}
                                        onChange={(e) => {
                                            form.lastSurgery = e.target.value;
                                            setForm({...form});
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="rv_w_btnbox">
                            <div className="wrapper">
                                <ul className="i-col-2 rv_w_btn_ul">
                                    {/* <li><a href="./review.html" className="btn01_w bk_w_delete">Delete</a></li> */}
                                    <li><input type="submit" value={L('Send')} className="btn01 bk_w_submit" onClick={send} /></li>
                                </ul>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
