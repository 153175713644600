import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { store } from "../Store";
import { Swiper, SwiperSlide, PhotoBrowser } from 'framework7-react';
import { Link } from "react-router-dom";

import view_icon01 from '../images/view_icon01.png';
import heart_icon01_ov from '../images/heart_icon01_ov.png';
import heart_icon01 from '../images/heart_icon01.png';
import share_icon01 from '../images/share_icon01.png';

import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { fetchGet, formatDate, getLang, L, loadLazyImages, loadLazyImagesOnScroll, PlaceholderImage } from "../utils";
import { exeShare } from '../nativeBridge';
import LikeButton from "../components/LikeButton";
import { handleTrendLike } from "../Services";

export default function TrendReadPage() {
    const { id } = useParams();
    const { state, dispatch } = useContext(store);
    const photoBrowser = useRef(null);
    let [trend, setTrend] = useState();

    useEffect(() => {
        fetchGet("/api/trends/" + id + '?view=true').then(trend => {
            trend.titles = JSON.parse(trend.i18nTitle);
            setTrend(trend);

            for (let i = 0; i < state.trends.length; ++i) {
                if (state.trends[i].id === trend.id) {
                    state.trends[i] = trend;
                    dispatch({ type: 'SET_TRENDS', payload: [...state.trends] });
                }
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id]);

    useEffect(() => {
        loadLazyImages('.trend_rel_img img');
        return loadLazyImagesOnScroll('.trend_rel_img img', document);
    }, [trend]);

    if (!trend) return <div id="container" />;

    let date = formatDate(trend.createdAt);
    let title = trend.titles[getLang()] || trend.titles.en;
    let content = JSON.parse(trend.i18nContent || '{}');
    content = content[getLang()] || content.en;
    let tags = JSON.parse(trend.i18nTags || '{}');
    tags = JSON.parse(tags[getLang()] || tags.en || '[]');
    const relatedTrends = (state.trends.length)
        ? state.trends.filter(t => t.id !== trend.id && (t.category === trend.category || t.writerId === trend.writer.id))
        : [];

    const share = () => trend.id && exeShare(
        // L("Share trend from O'YOUNG"),
        // "O'YOUNG Trend",
        global.window.location.origin + "/guest/shareTrend.html?id=" + trend.id
    );

    return (
        <div id="container">
            <Header />

            <div id="trend_detail" className="sub">

                <Navbar title={L('Trend')} />

                <div className="trend_detail">

                    <div className="trend_detail_wrap">
                        <div className="trend_detail_box">
                            <div className="wrapper">
                                <div className="trend_card-b">
                                    <div className="trend_card_top">
                                        <div className="trend_dt_inf cf">
                                            <div className="trend_dt_title_box">
                                                <p className="trend_dt_title">{title}</p>
                                            </div>
                                            <p className="trend_dt_date">{date}</p>
                                            <span className="trend_dt_view"><img src={view_icon01} alt="View count" /><b>{trend.views}</b></span>
                                        </div>
                                    </div>
                                    <ul className="i-col-0 keyword-b">
                                        {
                                            tags.map(t => <li key={t.value}>#{t.value}</li>)
                                        }
                                    </ul>

                                    <PhotoBrowser photos={trend.slides.map((s) => `/upload/${s.path}`)} ref={photoBrowser} />

                                    {trend.slides.length && (
                                        <Swiper
                                            loop={true}
                                            className="trend_dt_img_sl"
                                            pagination={{ el: '.trend_dt_img_sl .swiper-pagination' }}
                                        >
                                            {trend.slides.map((s, idx) => (
                                                <SwiperSlide key={s.id} onClick={() => photoBrowser.current.open(idx)}>
                                                    <img
                                                        src={'/upload/' + (s.thumbnail || s.path)}
                                                        alt={s.name}
                                                    />
                                                </SwiperSlide>
                                            ))}

                                            <div className="swiper-pagination"></div>
                                        </Swiper>
                                    )}

                                    <ul className="i-col-2 doctor_dt_btn">
                                        <li>
                                            <LikeButton
                                                onClickLike={() => handleTrendLike(trend, state, dispatch)}
                                                target={trend}
                                                targetLike={state.trendLikes}
                                                className="doctor_dt_like_btn"
                                            />
                                        </li>
                                        <li>
                                            <button className="doctor_dt_share_btn" onClick={share}>
                                                <img src={share_icon01} alt="Share trend" />{L('Share')}
                                            </button>
                                        </li>
                                    </ul>
                                    <div className="trend_dt_expl" dangerouslySetInnerHTML={{ __html: content }}></div>
                                </div>
                            </div>
                        </div>

                        <div className="trend_rel_box">
                            <div className="trend_rel_title">
                                <p className="wrapper">{L('Related Trends')}</p>
                            </div>
                            <div className="trend_rel_list">
                                <div className="wrapper">
                                    {relatedTrends.map((t) => (
                                        <div className="trend_rel_card-a" key={t.id}>
                                            <div className="i-col-0 trend_rel_inner">
                                                <Link to={`/trends/${t.id}`} className="trend_rel_img f7-ignore">
                                                    <img data-src={t.banner ? '/upload/' + (t.banner.thumbnail || t.banner.path) : PlaceholderImage} alt={t.name} />
                                                </Link>
                                                <div className="trend_rel_cont">
                                                    <p className="trend_rel_subj">{t.titles[getLang()] || t.titles.en}</p>
                                                    <ul className="i-col-0 keyword-b">
                                                        <li><a href="#category">{L(t.category)}</a></li>
                                                    </ul>
                                                    <div className="trend_rel_like">
                                                        <img
                                                            src={
                                                                Array.isArray(state.trendLikes) &&
                                                                    state.trendLikes.find(l => l.id === t.id)
                                                                    ? heart_icon01_ov : heart_icon01
                                                            }
                                                            alt="heart"
                                                        />
                                                        <span className="trend_rel_like_num">{t.likes}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer index={3} />
        </div>
    );
}