import { createContext, useReducer } from "react";
import { setLang, storage } from "./utils";

/*
 * 컨텍스트를 사용한 전역 상태 관리
 * https://reactjs.org/docs/hooks-reference.html#usecontext
 * https://tyrannosaurustech.com/blog/global-state-management-with-react-hooks-and-context/
 */

const _initialLanguage = global.navigator.language.slice(0, 2);
setLang(_initialLanguage);

const _initialState = {
    language: _initialLanguage,
    user: undefined,
    consultants: [],
    partners: [],
    trends: [],
    trendLikes: [],
    consultantLikes: [],
    talkMessages: [],
    rememberLogin: true,
    products: [],
};

export const store = createContext(_initialState);
const { Provider } = store;

export const StateProvider = ({children}) => {
    const [state, dispatch] = useReducer((state, action) => {
        const currentState = { ...state };
        switch (action.type) {
            case 'SET_LANGUAGE':
                setLang(currentState.language = action.payload);
                break;
            case 'SET_USER':
                currentState.user = action.payload;
                break;
            case 'SET_CONSULTANTS':
                currentState.consultants = action.payload;
                break;
            case 'SET_PARTNERS':
                currentState.partners = action.payload;
                break;
            case 'SET_TRENDS':
                currentState.trends = action.payload;
                break;
            case 'SET_TREND_LIKES':
                currentState.trendLikes = action.payload;
                break;
            case 'SET_CONSULTANT_LIKES':
                currentState.consultantLikes = action.payload;
                break;
            case 'SET_TALK_MESSAGES':
                currentState.talkMessages = action.payload;
                break;
            case 'SET_REMEMBER_LOGIN':
                currentState.rememberLogin = action.payload;
                storage('rememberLogin', action.payload);
                break;
            case 'SET_PRODUCTS':
                currentState.products = action.payload;
                break;
            default:
                throw new Error(`Unknown action "${action.type}"`);
        }

        return currentState;
    }, _initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
}
