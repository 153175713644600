import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

import Header from "../components/Header";
import { store } from "../Store";
import { fetchGet, formatDateTime, formatRelativeTime, getLang, L } from "../utils";

export default function ConsultationListPage() {
    const { state } = useContext(store);
    const [consultations, setConsultations] = useState([]);

    useEffect(() => {
        fetchGet('/customer/api/consultations').then(result => {
            result.sort((a, b) => {
                return a.bookDate < b.bookDate ? 1 : (a.bookDate > b.bookDate ? -1 : 0);
            });
            setConsultations(result);
        });

    }, []);

    const now = new Date();

    return (
        <div id="container" className="gray">
            <Header />

            <div id="my_consult" className="sub mypage">

                <div className="menu_2dp">
                    <div className="wrapper">
                        <ul className="i-col-2 menu_2dp_ul">
                            <li><Link to="/consultation-booking" className="f7-ignore">{L('Book a Consultation')}</Link></li>
                            <li className="active"><Link to="/consultations" className="f7-ignore">{L('My Consultations')}</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="myconsult_list">
                    {consultations.length ? consultations.map((c) => {
                        const consultant = state.consultants.find((cc) => cc.id === c.talk.consultantId);
                        if (!consultant)
                            return null;

                        const bookDate = new Date(c.bookDate);
                        let startsIn;
                        if (c.status === 'CONFIRMED' && now < bookDate)
                            startsIn = (
                                <span> ({L('Starts {}', <time key="1" dateTime={c.bookDate}>{formatRelativeTime(bookDate)}</time>)})</span>
                            );


                        return (
                            <div key={c.id} className="wrapper myconsult_list_wrap" style={{ marginBottom: '14px'}}>
                                <Link key={c.id} to={`/consultations/${c.id}`} className="f7-ignore">
                                    <div className="i-col-0 doctor_card-b like_doctor">
                                        <div className="doctor_img">
                                            <img src={'/upload/' + (c.talk.consultantPicture.thumbnail || c.talk.consultantPicture.path)} alt={consultant.englishName} />
                                        </div>
                                        <div className="doctor_inf">
                                            <div className="doctor_name_box cf">
                                                <span className="doctor_name">
                                                    {getLang() === 'ko' ? c.talk.consultantName : c.talk.consultantEnglishName}
                                                </span>
                                                <span className="myconsult_state going">{L(c.status)}</span>
                                            </div>
                                            <ul className="i-col-0 doctor_keyword">
                                                <li key={c.category}><span className="category">{L(c.category)}</span></li>
                                            </ul>
                                            <p className="myconsult_date">
                                                {formatDateTime(bookDate)} {startsIn}
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        );
                    }) : <div style={{margin: '1em'}}>{L('No reservations')}</div>}
                </div>
            </div>

            <Footer index="3" />
        </div>
    );
}
