import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { PhotoBrowser, Swiper, SwiperSlide } from "framework7-react";
import $ from 'jquery';

import view_icon01 from '../images/view_icon01.png';
import share_icon01 from '../images/share_icon01.png';

import Header from '../components/Header';
import Navbar from "../components/Navbar";
import { fetchGet, getLang, L, loadLazyImages, loadLazyImagesOnScroll } from "../utils";
import LikeButton from '../components/LikeButton';
import { handleConsultantLike } from '../Services';
import { store } from '../Store';
import { exeShare } from '../nativeBridge';
import Footer from '../components/Footer';
import DoctorCard from '../components/DoctorCard';
import ReviewList from '../components/ReviewList';

export default function ClinicDetailPage() {
    const { id } = useParams();
    const { state, dispatch } = useContext(store);
    const photoBrowser = useRef(null);
    const [partner, setPartner] = useState();
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        // 탭 전환 처리
        const f = function () {
            const tabBtn_idx = $(this).index();

            $(this).addClass('active').siblings().removeClass('active');
            $(".doctor_dt_indt_ct").eq(tabBtn_idx).fadeIn(200).siblings().hide();
        };

        $(document).on('click', '.doctor_dt_indt_tab_ul li', f);
        return () => $(document).off('click', '.doctor_dt_indt_tab_ul li', f);
    }, []);

    useEffect(() => {
        Promise.all([
            fetchGet('/customer/api/partners/' + id + '?view=true&doctors=true'),
            fetchGet('/api/customerReviews?partnerId=' + id),
        ]).then(result => {
            result[0].members && result[0].members.forEach((m) => {
                m.categories = m.categories ? m.categories.split(';') : [];
                m.specialties = m.specialties ? m.specialties.split(';') : [];
            });
            setPartner(result[0]);
            setReviews(result[1].content);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id]);

    useEffect(() => {
        loadLazyImages('.doctor_img img');
        return loadLazyImagesOnScroll('.doctor_img img', document);
    }, [partner]);

    if (!partner) return <div id="container" />;

    const share = () => partner.id && exeShare(
        // L("Share consultant from O'YOUNG"),
        // "O'YOUNG Doctor",
        global.window.location.origin + "/guest/shareClinic.html?id=" + partner.id
    );

    let info = partner.i18nInfo ? JSON.parse(partner.i18nInfo) : {};
    info = info[getLang()] || info.en;

    let news = partner.i18nNews ? JSON.parse(partner.i18nNews) : {};
    news = news[getLang()] || news.en;

    let categories = partner.categories ? partner.categories.split(/;/) : [];
    if (!categories.length && partner.field)
        categories = [partner.field];

    let specialties = partner.specialties ? partner.specialties.split(/;/) : [];

    return (
        <div id="container" className="gray">
            <Header />

            <div id="clinic_detail" className="sub">
                <Navbar title={getLang() === 'ko' ? partner.userName : partner.englishName} />

                <div className="doctor_dt_wrap">
                    <div className="doctor_dt_inf">
                        <div className="wrapper">

                            <div className="clinic_dt_view_box">
                                <span className="clinic_dt_view"><img src={view_icon01} alt="# views" /><b>{partner.views}</b></span>
                            </div>

                            {partner.banner && (
                                <PhotoBrowser photos={[`/upload/${partner.banner.path}`]} ref={photoBrowser} />
                            )}

                            <Swiper
                                loop={true}
                                className="clinic_dt_img_sl"
                                pagination={{ el: '.clinic_dt_img_sl .swiper-pagination' }}
                            >
                                {partner.slides.map((s) => (
                                    <SwiperSlide key={s.id}>
                                        <img src={`/upload/${s.thumbnail}`} alt={s.name} />
                                    </SwiperSlide>
                                ))}
                                <div className="swiper-pagination"></div>
                            </Swiper>

                            <ul className="i-col-2 doctor_dt_btn">
                                <li>
                                    <LikeButton
                                        target={partner}
                                        targetLike={state.consultantLikes}
                                        onClickLike={() => handleConsultantLike(partner, setPartner, state, dispatch)}
                                    />
                                </li>
                                <li>
                                    <button className="doctor_dt_share_btn" onClick={share}>
                                        <img src={share_icon01} alt="Share" />{L('Share')}
                                    </button>
                                </li>
                            </ul>

                        </div>
                    </div>

                    <div className="doctor_dt_indt">
                        <div className="doctor_dt_indt_tab">
                            <ul className="i-col-3 doctor_dt_indt_tab_ul">
                                <li className="active"><span>{L('Information')}</span></li>
                                <li><span>{L('Related News')}</span></li>
                                <li><span>{L('Reviews')}</span></li>
                            </ul>
                        </div>

                        <div className="doctor_dt_indt_ct_wrap">
                            <div className="wrapper">
                                <div className="doctor_dt_indt_ct information">
                                    <div className="doctor_dt_indt_ct_inner">
                                        <div dangerouslySetInnerHTML={{ __html: info }} />
                                    </div>
                                    <div className="doctor_dt_indt_ct_addinf">
                                        <ul className="doctor_dt_indt_ct_addinf_ul">
                                            <li>
                                                <p className="subtitle_st01 dot">{L('Service Type')}</p>
                                                <p className="doctor_dt_indt_ct_addinf_txt">{L(partner.service)}</p>
                                            </li>
                                            {categories.length > 0 && (
                                                <li>
                                                    <p className="subtitle_st01 dot">{L('Categories')}</p>
                                                    <ul className="i-col-0 doctor_keyword">
                                                        {categories.map((c) => (
                                                            <li key={c}><span className="category">{L(c)}</span></li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            )}
                                            {specialties.length > 0 && (
                                                <li>
                                                    <p className="subtitle_st01 dot">{L('Specialties')}</p>
                                                    <ul className="i-col-0 doctor_keyword">
                                                        {specialties.map((c) => (
                                                            <li key={c}><span className="category">{L(c)}</span></li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            )}
                                            {/* <li>
                                                <p className="subtitle_st01 dot">O’Young Talk Time</p>
                                                <p className="doctor_dt_indt_ct_addinf_txt">In your timezone, 10:00 AM ~ 6:00 PM, Weekday</p>
                                            </li> */}
                                        </ul>
                                    </div>

                                    <div className="doctor_list_wrap">
                                        {partner
                                            .members
                                            .filter((m) => m.publishingRequest === 'Approved' && m.inApp)
                                            .map((d) => <DoctorCard key={d.id} doctor={d} />
                                        )}
                                    </div>
                                </div>

                                <div className="doctor_dt_indt_ct news">
                                    <div className="doctor_dt_indt_ct_inner">
                                        {news ? <div dangerouslySetInnerHTML={{ __html: news }} /> : <div>{L('No news yet')}</div>}
                                    </div>
                                </div>

                                <div className="doctor_dt_indt_ct review">
                                    <div className="doctor_dt_review_list_wrap">
                                        <ReviewList reviews={reviews} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <TalkButton consultant={partner} /> */}
                </div>
            </div>

            <Footer index={partner.partnerType === 'CLINIC' ? 2 : 3} />

        </div>

    );
}
