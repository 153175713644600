/**
 * 사용자 언어
 */
export const LANGUAGES = ['ko', 'vi', 'en'];
export const LANGUAGE_NAMES = ['한국어', 'Tiếng Việt', 'English'];

/**
 * 뷰티 카테고리
 */
export const CATEGORIES = [
    'Eye', 'Nose', 'Face', 'Breast', 'Body', 'Anti-aging', 'Petite', 'Others'
];

/**
 * 상담 메시지에서 업로드하는 이미지의 리샘플링 크기
 */
export const PHOTO_SIZE = 960;

/**
 * 상담 메시지에서 업로드하는 이미지의 리샘플링 품질
 */
export const PHOTO_QUALITY = .9;

/**
 * 상담 메시지에서 업로드하는 이미지의 작게보기 크기
 */
export const THUMBNAIL_SIZE = 240;

/**
 * 상담 메시지에서 업로드하는 이미지의 작게보기 품질
 */
export const THUMBNAIL_QUALITY = .92;

/**
 * All SEARCH에서 HOT KEYWORD LIST
 */
export const HOT_KEYWORD_LIST = ['lifting', 'hàm', 'tái phẫu thuật mũi', 'mũi', 'hút mỡ', 'nâng ngực', 'mỡ bọng mắt', 'nâng mông', 'trị liệu da', '5 star hotel'];

/**
 * input box error message
 */
export const INPUTBOX_ERROR_MESSAGE = 'Please fill out this';

/**
 * 병의원 구분
 */
export const ORGANIZATION_TYPE = ["Plastic Surgery", "Dentistry - Odontologist", "Dermatologics", "Heathcare Checkup Center"];

/**
 * 응대가능 시간
 */
export const CLINIC_AVAILABLE_TIME = ["365days", "Weekday", "Excluding weekends and holidays"];

/**
 * deposit(결제 기본금)
 * 10000000
 */
export const DEPOSIT_AMOUNT = 10000000;

/**
 * pdf 다운로드 경로
 */
// export const PDF_DOWNLOAD_PATH = 'https://beauty.oyoung.co.kr/download/data/app/';
export const PDF_DOWNLOAD_PATH = 'https://beauty.oyoung.co.kr/media/pdfs/';

/**
 * pdf 약관 
 */
export const GENERAL_TERMS =  PDF_DOWNLOAD_PATH + 'GENERAL_TERMS.pdf';
export const FAQ =  PDF_DOWNLOAD_PATH + 'FAQ.pdf';

/**
 * GOOGLE_PLAY_STORE
 */
export const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=kr.co.oyoung.beauty';

/**
 * APP_SOTRE
 */
export const APP_STORE_URL = 'https://apps.apple.com/us/app/oyoung/id1475871343';

/**
 * FACEBOOK_URL
 */
export const FACEBOOK_URL = 'https://m.facebook.com/oyoungbeautyvn';

/**
 * YOUTUBE_URL
 */
export const YOUTUBE_URL = 'https://www.youtube.com/channel/UCZtAQ7DobYrwW-NTEIql-cA?view_as=subscriber';

/**
 * INSTAGRAM_URL
 */
export const INSTAGRAM_URL = 'https://www.instagram.com/oyoungbeauty/';

/**
 * 상담시간 DB 기준
 */
export const OYOUNG_TALK_TIME_DB = ['Everyday', 'Weekday', 'Saturday', 'NONE'];

/**
 * 상담시간 화면
 */
export const OYOUNG_TALK_TIME_VIEW = ['Everyday', 'Weekday', 'Weekdays and Saturdays', 'NONE'];

export const PRODUCT_CATEGORIES = [
    'SHOP_HEALTH',
    'SHOP_BEAUTY',
    'VISA',
    'FLIGHT',
    'ACCOMODATION',
    'AIRPORT_PICKUP',
    'MOBILITY',
    'INTERPRETATION',
    'LOCAL_TOUR',
    'HEALTH_CHECK',
];

export const FACEBOOK_APP_ID = '634127833618277';
