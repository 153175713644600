import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide, PhotoBrowser } from 'framework7-react';
import $ from 'jquery';

import talk_chat_msg_receive_img from '../images/talk_chat_msg_receive_img.png';
import view_icon01 from '../images/view_icon01.png';
import comment_icon01 from '../images/comment_icon01.png';

import { store } from "../Store";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { confirm, fetchGet, fetchPost, formatDate, formatRelativeTime, getLang, L } from "../utils";
import LikeButton from "../components/LikeButton";
import { handleReviewLike } from "../Services";

export default function ReviewReadPage() {
    const { state } = useContext(store);
    const loc = useLocation();
    const history = useHistory();
    const [review, setReview] = useState();
    const [reviewLikes, setReviewLikes] = useState([]);
    const { id } = useParams();
    const photoBrowser = useRef(null);
    const [comments, setComments] = useState([]);

    useEffect(() => {
        if (loc.state && loc.state.review && state.user) {
            loc.state.review.views++;
            setReview(loc.state.review);
            fetchPost('/customer/customerReviewRead.do?id=' + id);
        } else {
            fetchGet('/api/customerReviews/' + id).then((result) => {
                setReview(result);
            });
        }

        if (state.user) {
            fetchGet('/customer/favorites.do?type=customer_review').then(result => {
                setReviewLikes(result.map((a) => ({ id: a[0] })));
            });

            fetchGet('/customer/comments.do?subjectId=' + id).then((result) => setComments(result));
        }

    }, [id, loc, state.user]);

    if (!review) return <div id="container" />;

    const categories = review.categories.split(';');
    const doctor = (state.consultants && state.consultants.find((c) => c.id === review.consultantId)) || {};

    const postComment = (e) => {
        e.preventDefault();

        const comment = $('#txtComment').val().trim();
        if (!comment) return;

        const data = new FormData();
        data.append('comment', comment);
        data.append('subjectId', id);
        $('#txtComment').val('');

        fetchPost('/customer/commentSave.do', data).then((result) => {
            result.comment.createdAt = new Date().toISOString();
            comments.unshift(result.comment);
            setComments([...comments]);
            review.comments++;
            setReview({...review});
        });
    };

    const deleteComment = (id, idx) => {
        confirm(L('Do you want to delete your comment?'), () => {
            const data = new FormData();
            data.append('id', id);
    
            fetchPost('/customer/commentDelete.do', data).then(() => {
                $('#comment-' + id).slideUp('fast', () => {
                    comments.splice(idx, 1);
                    setComments([...comments]);
                    review.comments--;
                    setReview({...review});
                });
            });
    
        });
    };

    return (
        <div id="container">
            <Header />

            <div id="review_detail" className="sub">
                <Navbar title={L('Review')} />

                <div className="review_list">
                    <div className="review_list_wrap">
                        <div className="review_list_box">
                            <div className="wrapper">
                                <div className="i-col-0 review_card_top">
                                    <div className="review_img">
                                        <img src={talk_chat_msg_receive_img} alt={review.customerName} />
                                    </div>
                                    <div className="review_inf cf">
                                        {state.user && review.customerId === state.user.id && (
                                            <div className="review_mn_btnbox">
                                                <ul className="i-col-0 review_edit_btnbox">
                                                    <li>
                                                        <button
                                                            className="review_edit_btn"
                                                            onClick={() => history.push('/review-write?id=' + review.id)}
                                                        >
                                                            {L('Edit')}
                                                        </button>
                                                    </li>
                                                    {/* <li><button className="review_del_btn">{L('Delete')}</button></li> */}
                                                </ul>
                                            </div>
                                        )}
                                        <div className="review_name_box">
                                            <a href="#self" className="review_name">
                                                {review.customerName}
                                            </a>
                                        </div>
                                        <p className="review_date">{formatRelativeTime(review.createdAt)}</p>
                                        <span className="review_view"><img src={view_icon01} alt="Number of views" /><b>{review.views}</b></span>
                                    </div>
                                    <a href="#self" className="review_subj">
                                        {review.subject}
                                    </a>
                                    <ul className="i-col-0 review_keyword">
                                        {categories.map((c) => (
                                            <li key={c}><a href={`#${c}`}>{L(c)}</a></li>
                                        ))}
                                    </ul>
                                </div>

                                <PhotoBrowser photos={review.photos.map((p) => `/upload/${p.path}`)} ref={photoBrowser} />

                                <div className="review_card_cont">
                                    {review.photos.length > 0
                                    ? <Swiper
                                        loop={true}
                                        className="review_dt_img_sl"
                                        pagination={{ el: '.review_dt_img_sl .swiper-pagination' }}
                                    >
                                        {review.photos.map((p, idx) => (
                                            <SwiperSlide key={p.id} onClick={() => photoBrowser.current.open(idx)}>
                                                <img src={`/upload/${p.thumbnail}`} alt={p.name} />
                                            </SwiperSlide>
                                        ))}
                                        <div className="swiper-pagination"></div>
                                    </Swiper>
                                    : ''}
                                    <div className="review_cont_txt01">
                                        {review.content.trim().split(/\n/).map((c) => <p key={c}>{c}&nbsp;</p>)}
                                    </div>
                                    <div className="prod_dt_summary_tb_wrap">
                                        <table className="prod_dt_summary_tb">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <p className="subtitle_st01 dot">{L('Surgery date')}</p>
                                                    </th>
                                                    <td>{formatDate(review.surgeryYmd)}</td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="subtitle_st01 dot">{L('Clinic')}</p>
                                                    </th>
                                                    <td>
                                                        {review.partnerId && (
                                                            <Link to={`/clinics/${review.partnerId}`} className="f7-ignore">
                                                                {doctor && (getLang() === 'ko' ? doctor.partnerUserName : doctor.partnerEnglishName)}
                                                            </Link>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <p className="subtitle_st01 dot">{L('Doctor')}</p>
                                                    </th>
                                                    <td>
                                                        <Link to={`/doctors/${doctor.id}`} className="f7-ignore">
                                                            {doctor && (getLang() === 'ko' ? doctor.userName : doctor.englishName)}
                                                        </Link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <ul className="i-col-2 doctor_dt_btn">
                                    <li>
                                        <LikeButton
                                            target={review}
                                            targetLike={reviewLikes}
                                            onClickLike={() => handleReviewLike(
                                                review,
                                                () => setReview({ ...review }),
                                                state.user,
                                                reviewLikes,
                                                setReviewLikes
                                            )}
                                        />
                                    </li>
                                    <li>
                                        <button className="doctor_dt_comment_btn">
                                            <img src={comment_icon01} alt="" />
                                            {L('Comments')} {review.comments ? <b>({review.comments})</b> : ''}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="comment_box">
                    <div className="comment_title">
                        <p className="wrapper">{L('Comments')}</p>
                    </div>
                    <div className="comment_write">
                        <div className="wrapper">
                            <form id="" name="" action="" className="comment_write_f">
                                <input type="text" name="" id="txtComment" className="inpt01" placeholder="Write a comment" />
                                <button className="btn02" onClick={postComment}>{L('Post')}</button>
                            </form>
                        </div>
                    </div>
                    <div className="comment_list">
                        <div className="wrapper">
                            {comments.map((c, idx) => (
                                <div key={c.id} id={`comment-${c.id}`} className="comment_card-a">
                                    <div className="i-col-0 comment_inner">
                                        <div className="comment_img">
                                            <img src={talk_chat_msg_receive_img} alt={c.customerName} />
                                        </div>
                                        <div className="comment_cont">
                                            <p className="comment_writer">{c.customerName}</p>
                                            <div className="comment_text">{c.comment}</div>
                                            <p className="comment_date">{formatRelativeTime(c.createdAt)}</p>
                                            {state.user && c.customerId === state.user.id && (
                                                <button
                                                    type="button"
                                                    className="review_del_btn"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '.6rem',
                                                        right: '1rem',
                                                        height: '1.5rem',
                                                        background: '#ff4d90',
                                                        color: '#fff', 
                                                        border: 0, 
                                                        padding: '0 0.5rem',
                                                        fontSize: '0.7rem',
                                                    }}
                                                    onClick={() => deleteComment(c.id, idx)}
                                                >
                                                    {L('Delete')}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* <div className="review_dt_consult">
                    <div className="wrapper">
                        <Link to={`/talk-request?consultant=${doctor.id}`} className="btn03 f7-ignore">{L('Have a consultation now!')}</Link>
                    </div>
                </div> */}
            </div>

            <Footer index="2" />
        </div>
    );
}
