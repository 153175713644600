import { L } from "../utils";

import heart_icon01 from '../images/heart_icon01.png';
import heart_icon01_ov from '../images/heart_icon01_ov.png';

export default function LikeButton({onClickLike, target, targetLike}) {
    const likeCount =
        target.likes === 0 ? "" : target.likes > 999 ? "999+" : target.likes;

    const icon = Array.isArray(targetLike) &&
    targetLike.find(l => l.id === target.id)
        ? heart_icon01_ov
        : heart_icon01;

    return (
        <button className="doctor_dt_like_btn" onClick={onClickLike}>
            <img src={icon} alt="" />{L('Like')} {likeCount ? <b>({likeCount})</b> : ''}
        </button>
    );
}
