import { Link } from "react-router-dom";

import myhealth_icon01 from '../images/myhealth_icon01.png';
import myhealth_icon02 from '../images/myhealth_icon02.png';
import myhealth_icon03 from '../images/myhealth_icon03.png';
import myhealth_icon04 from '../images/myhealth_icon04.png';
import myhealth_icon05 from '../images/myhealth_icon05.png';

import Header from "../components/Header";
import { L } from "../utils";

export default function MyHealthPage() {
    return (
        <div id="container" className="gray">
            <Header />

            <div id="myhealth" className="sub mypage">

                <div className="myhealth_nav">
                    <div className="wrapper">
                        <ul className="myhealth_nav_ul">
                            <li>
                                <Link to="/my-health-schedule" className="myhealth_nav_inner f7-ignore">
                                    <div className="i-col-0">
                                        <div className="myhealth_nav_icon"><img src={myhealth_icon01} alt="schedule" /></div>
                                        <span className="myhealth_nav_text">{L('Treatment Schedule')}</span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/my-health-vital" className="myhealth_nav_inner f7-ignore">
                                    <div className="i-col-0">
                                        <div className="myhealth_nav_icon"><img src={myhealth_icon02} alt="vitals" /></div>
                                        <span className="myhealth_nav_text">{L('Smart Vital')}</span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/my-health-suggestions" className="myhealth_nav_inner f7-ignore">
                                    <div className="i-col-0">
                                        <div className="myhealth_nav_icon"><img src={myhealth_icon03} alt="suggestions" /></div>
                                        <span className="myhealth_nav_text">{L('Treatment Suggestion')}</span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/my-health-checkups" className="myhealth_nav_inner f7-ignore">
                                    <div className="i-col-0">
                                        <div className="myhealth_nav_icon"><img src={myhealth_icon04} alt="checkups" /></div>
                                        <span className="myhealth_nav_text">{L('Checkups')}</span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/my-health-cells" className="myhealth_nav_inner f7-ignore">
                                    <div className="i-col-0">
                                        <div className="myhealth_nav_icon"><img src={myhealth_icon05} alt="" /></div>
                                        <span className="myhealth_nav_text">{L('Cell Culture')}</span>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}