import { L } from "../utils";

export const ORDER_KEYS = [
    {
        label: 'New',
        sorter: (a, b) => a.createdAt > b.createdAt ? -1 : (a.createdAt < b.createdAt ? 1 : 0),
    }, 
    {
        label: 'Views',
        sorter: (a, b) => a.views > b.views ? -1 : (a.views < b.views ? 1 : 0),
    }, 
    {
        label: 'Likes',
        sorter: (a, b) => a.likes > b.likes ? -1 : (a.likes < b.likes ? 1 : 0),
    }, 
    {
        label: 'Recommended',
        sorter: (a, b) => a.recommended === b.recommended ? 0 : (a.recommended && !b.recommended ? -1 : 1),
    }, 
];

export default function SortKeysBar({orderKey, setOrderKey, includeRecommended}) {
    const keys = includeRecommended ? ORDER_KEYS : ORDER_KEYS.filter((k) => k.label !== 'Recommended');

    return (
        <div className="list_sort_box">
            <div className="wrapper">
                <ul className="i-col-0 list_sort_ul">
                    {keys.map((k) => (
                        <li key={k.label} className={k.label === orderKey.label ? 'active' : ''}>
                            <a href={`#${k.label}`} onClick={() => setOrderKey(k)}>{L(k.label)}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}