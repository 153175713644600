import { CATEGORIES } from "../const";
import { L } from "../utils";

export default function CategoriesBar({category, setCategory}) {
    return (
        <div className="category_box">
            <div className="wrapper">
                <ul className="i-col-0 category_ul">
                    <li className={category ? '' : 'active'}>
                        <a href="#category" onClick={() => setCategory(null)}>
                            <span className="dt_category_icon all"></span>
                            <span className="dt_category_txt">{L('All')}</span>
                        </a>
                    </li>
                    {CATEGORIES.map((c) => (
                        <li key={c} className={c === category ? 'active' : ''}>
                            <a href={`#${c}`} onClick={() => setCategory(c)}>
                                <span className={`dt_category_icon ${c.toLowerCase()}`}></span>
                                <span className="dt_category_txt">{L(c)}</span>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}