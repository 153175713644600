import { f7 } from "framework7-react";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import $ from 'jquery';

import Footer from "../components/Footer";
import Header from "../components/Header";
import { store } from "../Store";
import { fetchGet, formatDate, getLang, L, localTimeDuration, returnConsultantTime, TIMEZONE_DIFFS } from "../utils";
import HOLIDAYS from '../data/holidays.json';

export default function ConsultationBookingPage() {
    const loc = useLocation();
    const history = useHistory();
    const { state } = useContext(store);
    const [consultants, setConsultants] = useState([]);
    const [categories, setCategories] = useState([]);
    const [form, setForm] = useState({});
    const [datePicker, setDatePicker] = useState();

    useEffect(() => {
        if (!state.consultants) return;

        // 카테고리와 의사 초기화
        let doctors = state.consultants.filter(d => d.recommended);
        const categories = [];
        doctors.forEach(d => {
            if (d.categories) {
                for (let i = 0; i < d.categories.length; ++i)
                    if (categories.indexOf(d.categories[i]) < 0)
                        categories.push(d.categories[i]);
            }
        });
        categories.sort();

        setConsultants(doctors);
        setCategories(categories);

        // 폼 데이터 초기화
        const params = f7.utils.parseUrlQuery(loc.search);
        if (params.consultant) {
            form.consultant = params.consultant;
            const consultant = doctors.find((d) => d.id === params.consultant);
            if (consultant)
                form.category = consultant.categories ? consultant.categories[0] : null;
        } else {
            form.category = categories[0];
        }
        setForm({...form});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loc, state.consultants]);

    useEffect(() => {
        // 날짜 선택 컴포넌트 초기화
        f7.calendar.destroy(datePicker);

        const now = new Date();
        const min = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        const max = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 90);
        const cal = f7.calendar.create({
            inputEl: '#txtChatDate',
            locale: getLang(),
            dateFormat: {
                year:'numeric', month:getLang() === 'ko' ? 'numeric' : 'short', day: 'numeric', weekday: 'short'
            },
            closeOnSelect: true,
            firstDay: 7,
            disabled: d => {
                if (d.getDay() === 0 || d < min || d > max)
                    return true;

                const consultant = consultants.find((d) => d.id === form.consultant);
                if (consultant) {
                    if (d.getDay() === 6 && consultant.availability !== 'Saturday')
                        return true;

                    const mm = d.getMonth() + 1;
                    const dd = d.getDate();
                    const ymd = d.getFullYear() + '-' + (mm < 10 ? '0' : '') + mm + '-' + (dd < 10 ? '0' : '') + dd;
                    return HOLIDAYS[
                        (consultant.partner && consultant.partner.country) || 'KR'
                    ][ymd];
                }

                return false;
            },
            on: {
                dayClick: (_calendar, _dayEl, year, month, day) => {
                    const val = new Date(year, month, day);
                    form.chatDate = val;
                    setForm({...form});
                }
            }
        });
        setDatePicker(cal);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consultants, form.consultant]);

    const filtered = consultants.filter((c) => c.categories && c.categories.indexOf(form.category) >= 0);
    const selectedConsultant = filtered.find((d) => d.id === form.consultant);

    let startTime = 0;
    let endTime = 0;
    const times = [];

    if (selectedConsultant) {
        startTime = _getTime(selectedConsultant.startHour);
        endTime = _getTime(selectedConsultant.endHour);
        for (let i = startTime; i < endTime; i += 20) { // 20분 간격 예약 가능 시간
            times.push(i);
        }
    }

    const handleNext = (e) => {
        e.preventDefault();

        if (!document.bookingForm.reportValidity())
            return false;

        const time = $('#selChatTime').val();
        fetchGet('/customer/checkDuplicateConsultation.do?time=' + time).then(result => {
            if (result.exists) {
                alert(L('You already have an appointment around this time.'));
                return;
            }

            const data = [];
            data.push({key: 'bookDate', value: time});
            data.push({key: 'timezone', value: new Date().getTimezoneOffset()});
            data.push({key: 'timezoneName', value: Intl.DateTimeFormat().resolvedOptions().timeZone});
            data.push({key: 'rescheduleDateOk', value: document.bookingForm.rescheduleDateOk.checked});
            data.push({key: 'rescheduleTimeOk', value: document.bookingForm.rescheduleTimeOk.checked});
            console.log(data);

            history.push('/talk-request?consultant=' + form.consultant + '&category=' + form.category, data);
        });

        return false;

    };

    return (
        <div id="container">
            <Header />

            <div id="book_consult_write" className="sub mypage">

                <div className="menu_2dp">
                    <div className="wrapper">
                        <ul className="i-col-2 menu_2dp_ul">
                            <li className="active"><Link to="/consultation-booking" className="f7-ignore">{L('Book a Consultation')}</Link></li>
                            <li><Link to="/consultations" className="f7-ignore">{L('My Consultations')}</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="write_box bk_write_box">
                    <form name="bookingForm" action="">
                        <div className="wBox">
                            <div className="wrapper item-input">
                                <label htmlFor="bk_w_category" className="wBox_title">{L('Category')}<span className="req"></span></label>
                                <select
                                    name="bk_w_category"
                                    id="bk_w_category"
                                    className="selectbox selectbox_full"
                                    required validate="true"
                                    value={form.category || ''}
                                    onChange={(e) => {
                                        form.category = e.target.value;
                                        form.consultant = '';
                                        setForm({...form});
                                    }}
                                >
                                    <option value="">{L('Select')}</option>
                                    {categories.map((c) => (
                                        <option key={c} value={c}>{L(c)}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="wBox">
                            <div className="wrapper item-input">
                                <label htmlFor="bk_w_doctor" className="wBox_title">{L('Doctor')} / {L('Consultant')}<span className="req"></span></label>
                                <select
                                    name="bk_w_doctor" id="bk_w_doctor" className="selectbox selectbox_full"
                                    required validate="true"
                                    value={form.consultant || ''}
                                    onChange={(e) => {
                                        form.consultant = e.target.value;
                                        form.chatDate = '';
                                        setForm({...form});
                                    }}
                                >
                                    <option value="">{L('Select')}</option>
                                    {filtered.map((c) => (
                                        <option key={c.id} value={c.id}>{getLang() === 'ko' ? c.userName : c.englishName}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="wBox">
                            <div className="wrapper">
                                <label className="wBox_title">{L('Availability')}</label>
                                <div style={{lineHeight: '40px', padding: '0 10px'}}>
                                    {selectedConsultant && (
                                        localTimeDuration(
                                            (selectedConsultant.partner && selectedConsultant.partner.country) || 'KR',
                                            selectedConsultant.startHour,
                                            selectedConsultant.endHour
                                        )
                                        + ', '
                                        + L(returnConsultantTime(selectedConsultant.availability) || "Weekday")
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="wBox">
                            <div className="wrapper item-input">
                                <label htmlFor="txtChatDate" className="wBox_title">{L('Date')}<span className="req"></span></label>
                                <input
                                    type="text"
                                    name="chatDate"
                                    id="txtChatDate"
                                    className="inpt02 inpt_full"
                                    required validate="true"
                                    readOnly
                                    placeholder={L('Select date')}
                                    value={form.chatDate ? formatDate(form.chatDate) : ''}
                                />
                            </div>
                        </div>
                        <div className="wBox">
                            <div className="wrapper item-input">
                                <label htmlFor="selChatTime" className="wBox_title">{L('Time')}<span className="req"></span></label>
                                <select
                                    id="selChatTime"
                                    className="selectbox selectbox_full"
                                    required validate="true"
                                >
                                    {form.chatDate && times.map(t => {
                                        let h = Math.floor(t / 60);
                                        let m = t % 60;
                                        let hm = (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m;
                                        let d = new Date(form.chatDate);
                                        if (d) {
                                            d.setHours(h);
                                            d.setMinutes(
                                                m - d.getTimezoneOffset() + TIMEZONE_DIFFS[
                                                    (selectedConsultant.partner && selectedConsultant.partner.country) || 'KR'
                                                ]
                                            );
                                            let text = d.toLocaleString(getLang(), {
                                                month:getLang() === 'ko' ? 'numeric' : 'short', day: 'numeric',
                                                hour:'numeric', minute:'2-digit'
                                            }) + ' (' + L('{}, consultant\'s time', hm) + ')';

                                            return (
                                                <option key={t} value={d && d.toJSON()}>{text}</option>
                                            );
                                        }

                                        return null;
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="wBox">
                            <div className="wrapper">
                                <label className="wBox_title">{L('In case the consultant is not available, you are')}</label>
                                <div className="i-col-0 profile_form_agree_chkbox" style={{padding: '3px 10px'}}>
                                    <input
                                        id="chk_1"
                                        name="rescheduleDateOk"
                                        type="checkbox"
                                        checked={!(form.rescheduleDateOk === 'false')}
                                        onChange={(e) => {
                                            form.rescheduleDateOk = String(e.target.checked);
                                            setForm({...form});
                                        }}
                                    />
                                    <label htmlFor="chk_1">
                                        {L('Willing to reschedule the date')}
                                    </label>
                                </div>
                                <div className="i-col-0 profile_form_agree_chkbox" style={{padding: '3px 10px'}}>
                                    <input
                                        id="chk_2"
                                        name="rescheduleTimeOk"
                                        type="checkbox"
                                        checked={!(form.rescheduleTimeOk === 'false')}
                                        onChange={(e) => {
                                            form.rescheduleTimeOk = String(e.target.checked);
                                            setForm({...form});
                                        }}
                                    />
                                    <label htmlFor="chk_2">
                                        {L('Willing to reschedule the time')}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="rv_w_btnbox">
                            <div className="wrapper">
                                <ul className="i-col-2 rv_w_btn_ul">
                                    <li><input type="submit" value={L('Next')} className="btn01 bk_w_submit" onClick={handleNext} /></li>
                                </ul>
                            </div>
                        </div>
                    </form>
                </div>
                <Footer index="3" />
            </div>
        </div>
    );
}

function _getTime(s) {
    if (s) {
        let idx = s.indexOf(':');
        if (idx > 0) {
            let h = +s.slice(0, idx);
            let m = +s.slice(idx + 1);
            return h * 60 + m;
        }
    }

    return 0;
}
