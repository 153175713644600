import { Link } from 'react-router-dom';

import talk_chat_msg_receive_img from '../images/talk_chat_msg_receive_img.png';
import { formatDate, L } from '../utils';

export default function ReviewList({ reviews }) {
    return (
        reviews.length
            ? reviews.map((r) => {
                const content = r.content.trim();
                let excerpt = content.slice(0, 100);
                if (excerpt.length < content.length)
                    excerpt += '...';

                return (
                    <div key={r.id} className="i-col-0 review_card-a">
                        <div className="review_img">
                            <img src={talk_chat_msg_receive_img} alt="" />
                        </div>
                        <div className="review_inf">
                            <div className="review_name_box">
                                <Link to={`/reviews/${r.id}`} className="review_name f7-ignore">{r.customerName}</Link>
                                {/* <div className="review_delete">
                                <button className="review_delete_btn"><img src="../../images/delete_icon01.png" alt="Delete" /></button>
                            </div> */}
                            </div>
                            <div className="review_cont">
                                {excerpt}
                            </div>
                            <p className="review_date">{formatDate(r.createdAt)}</p>
                        </div>
                    </div>
                );
            })
            : <div style={{ marginTop: '1.5em' }}>{L('No review yet')}</div>
    )
}