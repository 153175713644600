import { Link, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { f7 } from "framework7-react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import CategoriesBar from "../components/CategoriesBar";
import SortKeysBar, { ORDER_KEYS } from "../components/SortKeysBar";
import { fetchGet, L } from "../utils";
import PartnerList from "../components/PartnerList";
import { store } from "../Store";

export default function ClinicsPage() {
    const { state, dispatch } = useContext(store);
    const [category, setCategory] = useState();
    const [orderKey, setOrderKey] = useState(ORDER_KEYS[0]);
    const [clinics, setClinics] = useState([]);
    const loc = useLocation();
    const [onlyMine, setOnlyMine] = useState();

    useEffect(() => {
        if (!state.partners.length) {
            fetchGet('/api/partners').then((result) => {
                result.forEach((c) => {
                    c.categories = c.categories ? c.categories.split(';') : [];
                    c.specialties = c.specialties ? c.specialties.split(';') : [];
                });
                dispatch({type: 'SET_PARTNERS', payload: result});
            });
        }
    }, [dispatch, state.partners]);

    useEffect(() => {
        const params = f7.utils.parseUrlQuery(loc.search);
        setOnlyMine(params.mine);
    }, [loc.search])

    useEffect(() => {
        const filtered = state.partners.filter((c) => (
            c.partnerType === 'CLINIC'
            && (!category || c.categories.indexOf(category) >= 0)
            && (!onlyMine || (state.consultantLikes && state.consultantLikes.find((l) => l.id === c.id)))
        ));
        filtered.sort(orderKey.sorter);
        setClinics(filtered);
    }, [category, onlyMine, orderKey.sorter, state.consultantLikes, state.partners]);

    return (
        <div id="container" className="gray">
            <Header />

            <div id="clinic" className="sub">

                {onlyMine ? (
                    <div className="menu_2dp">
                        <div className="wrapper">
                            <ul className="i-col-2 menu_2dp_ul">
                                <li><Link to="/doctors?mine=true" className="f7-ignore">{L('Favorite Doctors')}</Link></li>
                                <li className="active"><Link to="/clinics?mine=true" className="f7-ignore">{L('Favorite Clinics')}</Link></li>
                            </ul>
                        </div>
                    </div>
                ) : (
                    <div className="menu_2dp">
                        <div className="wrapper">
                            <ul className="i-col-3 menu_2dp_ul">
                                <li><Link to="/doctors" className="f7-ignore">{L('Doctors')}</Link></li>
                                <li className="active"><Link to="/clinics" className="f7-ignore">{L('Clinics')}</Link></li>
                                <li><Link to="/reviews" className="f7-ignore">{L('Reviews')}</Link></li>
                            </ul>
                        </div>
                    </div>
                )}

                <CategoriesBar category={category} setCategory={setCategory} />

                <div className="clinic_list">

                    <SortKeysBar orderKey={orderKey} setOrderKey={setOrderKey} />

                    <div className="clinic_list_wrap">
                        <PartnerList partners={clinics} />
                    </div>
                </div>
            </div>

            <Footer index="2" />
        </div >
    );
}
