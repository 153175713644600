/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useHistory } from 'react-router-dom';
import { useContext } from 'react';
import $ from 'jquery';
import { f7 } from 'framework7-react';

import hd_logo from '../images/hd_logo.png';
import close_icon01 from '../images/close_icon01.png';
import search_icon01 from '../images/search_icon01.png';
import mypage_nav_icon01 from '../images/mypage_nav_icon01.png';
import mypage_nav_icon02 from '../images/mypage_nav_icon02.png';
import mypage_nav_icon03 from '../images/mypage_nav_icon03.png';
import mypage_nav_icon04 from '../images/mypage_nav_icon04.png';
import mypage_nav_icon05 from '../images/mypage_nav_icon05.png';
import mypage_nav_icon06 from '../images/mypage_nav_icon06.png';
import mypage_nav_icon07 from '../images/mypage_nav_icon07.png';
import mypage_nav_icon08 from '../images/mypage_nav_icon08.png';

import { store } from '../Store';
import { confirm, L } from '../utils';
import { handleLogout } from '../Services';

export default function Header() {
    const { state, dispatch } = useContext(store);
    const history = useHistory();

    return (
        <>
            {/*Header {*/}
            <div id="header">
                <div className="wrapper">
                    <h1 id="hd_logo">
                        <Link to="/" className="f7-ignore">
                            <img src={hd_logo} alt="OYOUNG" className="resize_img" />
                        </Link>
                    </h1>
                    <div id="hd_menu_wrap">
                        <ul className="i-col-0 hd_menu_ul">
                            {/* <li><a href=""><img src={alarm_icon01} alt="alarm" className="resize_img" /></a></li> */}
                            <li><Link to="/search" className="f7-ignore"><img src={search_icon01} alt="search" className="resize_img" /></Link></li>
                            <li className="hd_menu_btn_li">
                                <div id="hd_menu_btn" onClick={() => $('#account').fadeIn('fast')}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/*menu over {*/}
            <div id="account">
                <div className="account_top">
                    <div className="wrapper">
                        <span className="account_close" onClick={() => $('#account').fadeOut('fast')}><img src={close_icon01} alt="Menu Close" /></span>
                        <span className="account_hd_title">{L('My Page')}</span>
                        <div className="accout_tnb_wrap">
                            <ul className="i-col-0 accout_tnb_ul">
                                {/* <li className="account_setting_btn"><a href=""><img src={setting_icon} alt="alarm" className="resize_img" /></a></li> */}
                                {/* <li className="account_home_btn"><a href="./main.html"><img src={home_icon} alt="search" className="resize_img" /></a></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="account_inf">
                    {state.user ? (
                        <div className="wrapper">
                            <div className="account_title cf">
                                <p className="account_title_text">{L('Account')}</p>
                                <div className="account_edit_box">
                                    <button
                                        id="account_edit_btn"
                                        title="menu"
                                        onClick={(e) => f7.popover.open('#userMenu', e.target)}
                                    >
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>
                                </div>
                            </div>
                            <div className="popover popover-links" id="userMenu">
                                <div className="popover-inner">
                                    <div className="list">
                                        <ul>
                                            <li><a href="#profile" className="list-button item-link popover-close" onClick={() => history.push('/profile')}>{L('Edit profile')}</a></li>
                                            <li><a href="#password" className="list-button item-link popover-close" onClick={() => history.push('/password')}>{L('Change password')}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="account_inf_tb_wrap">
                                <table className="account_inf_tb">
                                    <tbody>
                                        <tr>
                                            <th>
                                                <p className="subtitle_st01 dot">{L('ID')}</p>
                                            </th>
                                            <td>{state.user.id}</td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p className="subtitle_st01 dot">{L('Name')}</p>
                                            </th>
                                            <td>{state.user.userName}</td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p className="subtitle_st01 dot">{L('E-mail')}</p>
                                            </th>
                                            <td>{state.user.email}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <div className="wrapper">
                            <Link to="/sign" className="btn03 f7-ignore">{L('Sign In')}</Link>
                        </div>
                    )}
                </div>

                <div className="mypage_nav">
                    <ul className="mypage_nav_ul">
                        <li>
                            <Link to="/talks" className="mypage_nav_inner f7-ignore">
                                <div className="i-col-0 wrapper">
                                    <div className="mypage_nav_icon"><img src={mypage_nav_icon01} alt="Oyoung talk" /></div>
                                    <span className="mypage_nav_text">{L('O\'YOUNG Talk')}</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/consultation-booking" className="mypage_nav_inner f7-ignore">
                                <div className="i-col-0 wrapper">
                                    <div className="mypage_nav_icon"><img src={mypage_nav_icon02} alt="Video consultation" /></div>
                                    <span className="mypage_nav_text">{L('Video Consultation')}</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/reviews?mine=true" className="mypage_nav_inner f7-ignore">
                                <div className="i-col-0 wrapper">
                                    <div className="mypage_nav_icon"><img src={mypage_nav_icon03} alt="" /></div>
                                    <span className="mypage_nav_text">{L('My Reviews')}</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/doctors?mine=true" className="mypage_nav_inner f7-ignore">
                                <div className="i-col-0 wrapper">
                                    <div className="mypage_nav_icon"><img src={mypage_nav_icon04} alt="" /></div>
                                    <span className="mypage_nav_text">{L('My Bookmarks')}</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/my-health" className="mypage_nav_inner f7-ignore">
                                <div className="i-col-0 wrapper">
                                    <div className="mypage_nav_icon"><img src={mypage_nav_icon05} alt="" /></div>
                                    <span className="mypage_nav_text">{L('My Health')}</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/qna" className="mypage_nav_inner f7-ignore">
                                <div className="i-col-0 wrapper">
                                    <div className="mypage_nav_icon"><img src={mypage_nav_icon06} alt="" /></div>
                                    <span className="mypage_nav_text">{L('Q&A')}</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/about" className="mypage_nav_inner f7-ignore">
                                <div className="i-col-0 wrapper">
                                    <div className="mypage_nav_icon"><img src={mypage_nav_icon07} alt="" /></div>
                                    <span className="mypage_nav_text">{L('About O\'YOUNG')}</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/policies" className="mypage_nav_inner f7-ignore">
                                <div className="i-col-0 wrapper">
                                    <div className="mypage_nav_icon"><img src={mypage_nav_icon08} alt="" /></div>
                                    <span className="mypage_nav_text">{L('O\'YOUNG Policies')}</span>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="account_signout">
                    {state.user && (
                        <div className="wrapper">
                            <a
                                href="#signOut"
                                className="btn03"
                                onClick={() => confirm(
                                    L("Do you want to logout from O'YOUNG?"),
                                    () => handleLogout(dispatch, history),
                                )}
                            >
                                {L('Sign out')}
                            </a>
                        </div>
                    )}
                </div>
            </div>
            {/*} menu over*/}
            {/*} Header*/}
        </>
    );
}
