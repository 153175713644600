import $ from 'jquery';
import { LANGUAGES, OYOUNG_TALK_TIME_DB, OYOUNG_TALK_TIME_VIEW } from './const';
import { f7 } from 'framework7-react';

export function alert(...args) {
    let [text, title, callbackOk] = args;
    if (args.length === 2 && typeof args[1] === 'function') {
        [text, callbackOk, title] = args;
    }
    return f7.dialog.create({
        title: typeof title === 'undefined' ? f7.name : title,
        text,
        buttons: [
            {
                text: L('Ok'),
                bold: true,
                onClick: callbackOk,
                keyCodes: [13, 27],
            },
        ],
        destroyOnClose: true,
    }).open();
}
global.alert = alert;

export function confirm(...args) {
    let [text, title, callbackOk, callbackCancel] = args;
    if (typeof args[1] === 'function') {
        [text, callbackOk, callbackCancel, title] = args;
    }
    return f7.dialog.create({
        title: typeof title === 'undefined' ? f7.name : title,
        text,
        buttons: [
            {
                text: L('Cancel'),
                onClick: callbackCancel,
                keyCodes: [27],
            },
            {
                text: L('Ok'),
                bold: true,
                onClick: callbackOk,
                keyCodes: [13],
            },
        ],
        destroyOnClose: true,
    }).open();
}
global.confirm = confirm;

let _language = global.navigator.language.slice(0, 2);
let _dateFormat;
let _dateFormatShort;
let _dateTimeFormat;
let _dateTimeFormatShort;

export const setLang = lang => {
    delete global.translation;

    storage('_language', lang);
    _language = lang;
    
    _dateFormat = new Intl.DateTimeFormat(_language, {
        year:'numeric', month:_language === 'ko' ? 'numeric' : 'short', day: 'numeric', weekday: 'short'
    });
    _dateFormatShort = new Intl.DateTimeFormat(_language, {
        month:_language === 'ko' ? 'numeric' : 'short', day: 'numeric'
    });
    _dateTimeFormat = new Intl.DateTimeFormat(_language, {
        year:'numeric', month:_language === 'ko' ? 'numeric' : 'short', day: 'numeric',
        hour:'numeric', minute:'2-digit', weekday: 'short'
    });
    _dateTimeFormatShort = new Intl.DateTimeFormat(_language, {
         month:_language === 'ko' ? 'numeric' : 'short', day: 'numeric',
        hour:'numeric', minute:'2-digit'
    });
}

export const getLang = () => _language;

let _currency = 'VND';
let _exchangeRates;

export const setCurrency = (currency, exchangeRates) => {
    if (!currency)
        currency = _language === 'en' ? 'USD' : _language === 'ko' ? 'KRW' : 'VND'

    storage('currency', currency);
    _currency = currency;

    if (exchangeRates)
        _exchangeRates = exchangeRates;
}

export const getCurrency = () => _currency;

export const fetchGet = (url, options) => {
    options = Object.assign({
        credentials: 'include',
        redirect: 'manual'
    }, options);

    if (!options.headers)
        options.headers = {};
    
    if (sessionStorage.csrfToken)
        options.headers[sessionStorage.csrfHeaderName] = sessionStorage.csrfToken;

    return fetch(url, options).then(resp => {
        if (resp.ok)
            return resp.text().then(text => text ? JSON.parse(text) : null);
        throw resp;
    });
}

export const fetchPost = (url, data, options) => {
    options = Object.assign({
        credentials: 'include',
        redirect: 'manual',
        method: 'POST',
        body: data
    }, options);

    if (!options.headers)
        options.headers = {};
    
    if (sessionStorage.csrfToken)
        options.headers[sessionStorage.csrfHeaderName] = sessionStorage.csrfToken;

    return fetch(url, options).then(resp => {
        if (resp.ok)
            return resp.text().then(text => text ? JSON.parse(text) : null);
        throw resp;
    });
}

export const fetchPostJson = (url, data) => {
    return fetchPost(url, JSON.stringify(data), {
        headers: {
            'content-type': 'application/json'
        }
    });
}

export const handleFetchError = err => {
    console.log(err);
    f7.preloader.hide();
    if (err.url && err.json) {
        // err 개체가 HttpResponse 형식인 경우
        let response = err;
        if (response.type === 'opaqueredirect' && response.status === 0) {
            // 로그아웃되어 리디렉트가 발생한 경우
            needLogin();
        } else {
            // 본문에서 Spring 오류 개체 도출
            response.json()
                .then(json => {
                    if (json.error === 'Internal Server Error')
                        json.error = 'We\'re experiencing some problem now. Please try again later.';
        
                    alert(L(json.error || json.message))
                })
                .catch(e => {
                    console.log(e);
                    alert(L('Server down. Please try again later.'));
                });
        }
    } else {
        if (err.message || err.error || err.errors)
            err = 'Check one or more problems:<br><br><ul class="errors"><li>'
                + (err.message || err.error || err.errors.join('</li><li>')) + '</li></ul>';
        alert(L(err));
    }
};

export const needLogin = (redirect, history) => {
    confirm(L('Please login to use our service.'), () => {
        history.push('/sign?redirect=' + encodeURIComponent(redirect || ''));
    });
}

export const PlaceholderImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEXCwsK592mkAAAACklEQVQI12NgAAAAAgAB4iG8MwAAAABJRU5ErkJggg==';

/**
 * 화면에 현재 보이는 이미지만 로딩하도록 한다. img 개체에 lazy 클래스가 붙었으며
 * data-src 값을 설정한 경우만 대상으로 한다.
 * @param {string} images 이미지 선택자
 */
export const loadLazyImages = images => {
    const $images = $(images);
    if (!$images.length)
        return;

    const winHeight = $(window).height();

    for (let i = 0, count = $images.length; i < count; ++i) {
        const img = $images[i];
        let box = img.getBoundingClientRect();
        if (box.top + img.offsetHeight > 0 && box.top < winHeight * 1.2) {
            if (!img.src) {
                img.src = $(img).attr('data-src');
                img.onload = img.onerror = function () { this.classList.remove('lazy'); };
            }
        } else if (box.top > winHeight) {
            break;
        }
    }
};

/**
 * 스크롤 이벤트가 발생했을 때 화면에 보이는 lazy 이미지를 표시하도록 한다.
 * @param {string} images 이미지 선택자
 * @param {string} scroller 스크롤 컨테이너 선택자
 */
export const loadLazyImagesOnScroll = (images, scroller) => {
    let updateScheduled;
    const f = () => {
        if (updateScheduled)
            return;

        updateScheduled = true;
        setTimeout(() => {
            loadLazyImages(images);
            updateScheduled = false;
        }, 200);
    };
    $(scroller).on('scroll', f);

    return () => $(scroller).off('scroll', f);
};

export const formatYmd = t => {
    if (!t)
        return '';

    if (typeof t === 'string')
        t = new Date(t);

    let y = t.getFullYear();
    let m = t.getMonth() + 1;
    let d = t.getDate();

    return y + '-' + (m < 10 ? '0' : '') + m + '-' + (d < 10 ? '0' : '') + d;
};

export const formatDate = t => {
    if (!t)
        return '';

    if (typeof t === 'string')
        t = new Date(t);

    return _dateFormat.format(t);
}

export const formatDateShort = t => {
    if (!t)
        return '';

    if (typeof t === 'string')
        t = new Date(t);

    return _dateFormatShort.format(t);
}

export const formatDateTime = t => {
    if (!t)
        return '';

    if (typeof t === 'string')
        t = new Date(t);

    return _dateTimeFormat.format(t);
}

export const formatDateTimeShort = t => {
    if (!t)
        return '';

    if (typeof t === 'string')
        t = new Date(t);

    return _dateTimeFormatShort.format(t);
}

export const formatRelativeTime = isoStr => {
    let diff = Math.floor((Date.now() - new Date(isoStr).getTime()) / 1000);
    if (Math.abs(diff) < 60)
        return L('Just now');

    const REL_TIME = global.Intl && Intl.RelativeTimeFormat
                && new Intl.RelativeTimeFormat(_language, {numeric: 'auto'});

    diff = Math.floor(diff / 60);
    if (Math.abs(diff) < 60)
        return REL_TIME ? REL_TIME.format(-diff, 'minute') : L('{} minutes', diff);

    diff = Math.floor(diff / 60);
    if (Math.abs(diff) < 48)
        return REL_TIME ? REL_TIME.format(-diff, 'hour') : L('{} hours', diff);

    diff = Math.floor(diff / 24);
    if (Math.abs(diff) < 30)
        return REL_TIME ? REL_TIME.format(-diff, 'day') : L('{} days', diff);

    diff = Math.floor(diff / 30);
    if (Math.abs(diff) < 12)
        return REL_TIME ? REL_TIME.format(-diff, 'month') : L('{} months', diff);

    diff = Math.floor(diff / 12);
    return REL_TIME ? REL_TIME.format(-diff, 'year') : L('{} years', diff);
}

/**
 * 신용카드 번호를 서식화하고 마스킹한다.
 * @param {string} no 
 */
// export const formatCreditCard = no => {
//     no = no ? no.replace(/\D/g, '') : '';
//     if (no.length <= 4)
//         return no;

//     let arr = [];
//     let re = /\d{1,4}/g;
//     let result;
//     while ((result = re.exec(no)))
//         arr.push(result[0]);

//     return '**** '.repeat(arr.length - 1) + arr[arr.length - 1];
// }

const EmailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validEmail = email => {
    return email && email.search(EmailPattern) === 0;
}

/**
 * 이미지를 캔버스에 그린다. canvas의 논리적 크기가 최대 size 값으로 맞춰진다.
 * 특히 이미지의 방향을 EXIF 정보로 판단하여 표시함으로써 웹브라우저의 기능을 보완한다.
 * @param {HTMLImageElement} img 
 * @param {Number} orientation 
 * @param {HTMLCanvasElement} canvas 
 * @param {Number} size 가로나 세로의 최대 크기
 */
export const drawImageToCanvas = (img, canvas, size) => {
    if (!canvas)
        return;

    let width = img.width;
    let height = img.height;
    if (width > size || height > size) {
        if (width > height) {
            width = size;
            height = size * img.height / img.width;
        } else {
            height = size;
            width = size * img.width / img.height;
        }
    }

    canvas.width = width;
    canvas.height = height;

    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);
}

/**
 * 사용자가 선택한 파일을 이미지로 로딩한다.
 * @param {File} file 사용자가 선택한 파일
 * @returns Promise 이미지 데이터 URL
 */
export const loadImageFile = (file) => {
    return new Promise((resolve) => {
        const r = new FileReader();
        r.readAsDataURL(file);
        r.addEventListener('load', () => {
            resolve(r.result);
        });
    });
}

/**
 * UI 번역. 사용자의 언어 선택에 따라 `languages/XX.json` 파일을 바탕으로 번역 결과를 만든다.
 * 예) L('{} items', 3) -> "3 items"와 같이 결과를 만들어냄
 * @param {String} text 번역할 문자열의 키. 번역 데이터가 없으면 이 문자열을 결과로 반환한다.
 * @param  {...any} params 번역할 문자열에 {}가 있으면 넣을 매개변수 배열.
 */
export const L = (text, ...params) => {
    if (!global.translation) {
        let lang = getLang();
        if (LANGUAGES.indexOf(lang) < 0)
            lang = 'en';

        // 어찌됐든 아래는 동기적으로 잘 동작한다!
        global.translation = require('./data/' + lang + '.json');
    }

    let template = global.translation[text] || text;
    if (!params.length || !template)
        return template;

    let arr = template.split('{}');
    let hasObject = false;

    for (let i = params.length - 1; i >= 0; --i) {
        arr.splice(i + 1, 0, params[i]);
        if (typeof params[i] === 'object')
            hasObject = true;
    }

    return hasObject ? arr : arr.join('');
};

/**
 * 수치를 금액으로 보고 고객의 언어에 맞춰 서식화한다
 * @param {number} amount 금액
 * @param {string} currency 통화. 생략할 경우 고객의 통화로 지정된다.
 */
export const C = (amount, currency) => {
    return (+amount).toLocaleString(_language, { style: 'currency', currency: currency || _currency });
};

/**
 * 어떤 통화의 금액을 지정한 통화로 환산한다
 * @param {number} amount 금액
 * @param {string} currency 금액의 통화
 * @param {string} targetCurrency 변환 결과 통화. 미지정이면 고객 통화
 */
export const FX = (amount, currency, targetCurrency) => {
    if (!targetCurrency)
        targetCurrency = _currency;

    if (currency === targetCurrency)
        return amount;

    const rate = _exchangeRates[currency + '_' + targetCurrency]
        || 1 / _exchangeRates[targetCurrency + '_' + currency] || 0;
    return amount * rate;
};

export const returnConsultantTime = (time) => {
    return OYOUNG_TALK_TIME_VIEW[ OYOUNG_TALK_TIME_DB.findIndex(t=>t===time) ];
}

/**
 * 영구 저장소 처리 함수. 세 가지 동작을 한다.
 * 1. key 값이 없으면 저장소를 클리어한다.
 * 2. val이 undefined면 key에 해당하는 값을 구해 Promise를 반환한다
 * 3. 그 외에는 key에 val 값을 설정한다.
 * @param {string} key
 * @param {string} val
 */
export const storage = window.OYoung
    // 네이티브 스토리지
    ? (key, val) => {
        if (!key) { // 아무 인자가 없으면 초기화
            window.OYoung.NA_clearPValues();
        } else if (val === undefined) { // 값이 없으면 조회로서 promise 반환
            return new Promise(resolve => {
                const funcName = '_getPvalueCallback' + Math.floor(Math.random() * 100000000000);
                global[funcName] = result => {
                    if (result) {
                        try {
                            result = JSON.parse(decodeURI(result));
                        } catch (e) {
                            console.log('저장값 파싱 오류', key, '=', result);
                            result = null;
                        }
                    }
                    resolve(result);
                    delete global[funcName]
                };
                window.OYoung.NA_GetPValue(funcName, key);
            });
        } else { // 인자가 다 있으면 설정
            window.OYoung.NA_SetPValue(key, encodeURI(JSON.stringify(val)));
        }
    }
    // 웹뷰 로컬 스토리지
    : (key, val) => {
        if (!key) {
            localStorage.clear();
        } else if (val === undefined) {
            val = localStorage[key];
            if (val)
                try {
                    val = JSON.parse(val);
                } catch (e) {
                    console.log('JSON 파싱 오류, key = ', key, ', val = ', val, e);
                    val = null;
                }
            return Promise.resolve(val);
        } else {
            localStorage[key] = JSON.stringify(val);
        }
    }

/**
 * 비밀번호 강도를 0 - 4 척도로 반환. 3이상이어야 안전한 것으로 한다.
 * qwertyuiopasdfg(15자)는 3으로 나오고 bgt5^YHN는 2로 나온다.
 * @param {string} pass 
 */
export const passwordScore = pass => {
    // https://stackoverflow.com/a/11268104/122681
    let score = 0;
    if (!pass)
        return score;

    // award every unique letter until 5 repetitions
    let letters = {};
    for (let i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    let variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
    }

    let variationCount = 0;
    for (let check in variations) {
        variationCount += (variations[check] === true) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    score = Math.ceil(score / 36);
    if (score > 4)
        score = 4;

    return score;
}

let _browserCache = {};

export const openPdf = pdf => {
    let browser = _browserCache[pdf];

    if (!browser) {
        _browserCache[pdf] = browser = global.app.photoBrowser.create({
            photos: [{
                html: '<iframe src="https://docs.google.com/viewer?embedded=true&url='
                    + pdf + '" style="height:' + (global.innerHeight - 130)
                    + 'px;" frameborder="0"></iframe>'
            }]
        });
    }

    browser.open();
};

function getTime(s) {
    let idx = s.indexOf(':');
    let h = +s.slice(0, idx);
    let m = +s.slice(idx + 1);
    return h * 60 + m;
}

function toStr(t) {
    if (t === null)
        return '';

    let h = (Math.floor(t / 60) + 24) % 24;
    let m = t % 60;
    
    return new Date(2020, 0, 1, h, m, 0).toLocaleTimeString(getLang(), {hour: 'numeric', minute: 'numeric'});
}

export const TIMEZONE_DIFFS = {
    KR: -540,
    VN: -420,
}

const localTime = (sourceCountry, hour) => {
    const sourceDiff = TIMEZONE_DIFFS[sourceCountry] || 0;
    const targetDiff = new Date().getTimezoneOffset();
    return toStr(hour ? getTime(hour) + sourceDiff - targetDiff : null);
};

export const localTimeDuration = (sourceCountry, startHour, endHour) => {
    if ((sourceCountry !== 'KR' && sourceCountry !== 'VN') || (!startHour && !endHour)) {
        return (startHour || '00:00') + ' ~ ' + (endHour || '00:00');
    }

    return L('In your timezone, ') + localTime(sourceCountry, startHour) + ' ~ ' + localTime(sourceCountry, endHour);
};

/*
export function calcPrice(product, optionValues, count) {
    let price = product.baseSalePrice * count;
    let normalPrice = (product.baseListPrice || product.baseSalePrice) * count;
    
    const optionPrice = product.options.reduce((sum, opt, idx) => (
        sum + (
            (opt.type === 'SELECT'
            && opt.items[optionValues[idx]]
            && opt.items[optionValues[idx]].price)
            || 0
        ) * count
    ), 0);

    price += optionPrice;
    normalPrice += optionPrice;

    if (product.discountRates) {
        let discountRate = 0;
        product.discountRates.forEach((rate) => {
            if (count >= rate.lowCount)
                discountRate = rate.rate;
        });
        price = price * (1 - discountRate / 100);
    }

    return { price, discountRate: Math.floor((normalPrice - price) / normalPrice * 100), normalPrice };
}

export function calcShipping(product, count, country, area) {
    if (product.service
            || 'COD' === product.shippingChargeType
            || 'FREE' === product.shippingChargeType
            || !product.shippingChargeType)
        return 0;
    
    if ('PER_ITEM' === product.shippingChargeType)
        return Math.floor(
                (count + product.flatShippingUnits - 1)
                / product.flatShippingUnits
            ) * product.flatShippingCharge;
    
    if ('PER_SHIPMENT' === product.shippingChargeType)
        return product.flatShippingCharge;
    
    if (!product.shippingRates)
        return 0;
    
    const charges = product.shippingRates.charges;
    if (!charges || !charges.length || !country || !area)
        return 0;
    
    let price = 0;
    
    for (let i = 0; i < charges.length; ++i) {
        const c = charges[i];
        if ((country === c.country || '*' === c.country)
            && (area === c.area || '*' === c.area)
            && count >= c.lowCount)
            price = c.charge;
    }

    return price;
}

export function isPromotionOn(promotion, product) {
    const jsExpr = promotion.productFilter
        .replace(/=/g, '==')
        .replace(/\band\b/g, '&&')
        .replace(/\bor\b/g, '||')
        .replace(/\b([a-z][\w]+)\b/g, 'product.$1');
    const funcBody = `"use strict"\nreturn ${jsExpr};`

    // eslint-disable-next-line no-new-func
    return (new Function('product', funcBody))(product);
}

//
// 상품에 적용되는 프로모션을 찾아낸다
// @param {array} promotions 
// @param {object} product 
//
export function filterPromotions(promotions, product) {
    if (!promotions || !product) return [];

    return promotions.filter(p => isPromotionOn(p, product) || p.benefitProductIds.indexOf(product.id) >= 0);
}
*/
