import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import $ from 'jquery';
import { App as F7App, f7 } from 'framework7-react';

import { fetchPost, getLang, L } from './utils';
import { store } from './Store';

import _notificationAudioFile from './message.mp3';

import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ProductsPage from './pages/ProductsPage';
import ProductDetailPage from './pages/ProductDetailPage';
import TrendReadPage from './pages/TrendReadPage';
import HealthPage from './pages/HealthPage';
import Health1Page from './pages/Health1Page';
import Health2Page from './pages/Health2Page';
import Health3Page from './pages/Health3Page';
import Health4Page from './pages/Health4Page';
import DoctorsPage from './pages/DoctorsPage';
import DoctorDetailPage from './pages/DoctorDetailPage';
import SignPage from './pages/SignPage';
import { handleLogin } from './Services';
import ProfilePage from './pages/ProfilePage';
import ReviewsPage from './pages/ReviewsPage';
import ReviewWritePage from './pages/ReviewWritePage';
import ReviewReadPage from './pages/ReviewReadPage';
import TrendsPage from './pages/TrendsPage';
import PartnersPage from './pages/PartnersPage';
import PartnerDetailPage from './pages/PartnerDetailPage';
import ClinicsPage from './pages/ClinicsPage';
import TalksPage from './pages/TalksPage';
import TalkChatPage from './pages/TalkChatPage';
import PrivateRoute from './components/PrivateRoute';
import TalkRequestPage from './pages/TalkRequestPage';
import MyHealthPage from './pages/MyHealthPage';
import MyHealthCheckupsPage from './pages/MyHealthCheckupsPage';
import MyHealthSchedulePage from './pages/MyHealthSchedulePage';
import MyHealthVitalPage from './pages/MyHealthVitalPage';
import MyHealthSuggestionsPage from './pages/MyHealthSuggestionsPage';
import MyHealthCellsPage from './pages/MyHealthCellsPage';
import ConsultationBookingPage from './pages/ConsultationBookingPage';
import ConsultationListPage from './pages/ConsultationListPage';
import ConsultationDetailPage from './pages/ConsultationDetailPage';
import ConsultationRoomPage from './pages/ConsultationRoomPage';
import PasswordPage from './pages/PasswordPage';
import PoliciesPage from './pages/PoliciesPage';
import PolicyGeneralPage from './pages/PolicyGeneralPage';
import PolicyPrivacyPage from './pages/PolicyPrivacyPage';
import SearchPage from './pages/SearchPage';
import QnaPage from './pages/QnaPage';

const f7params = {
    name: "O'YOUNG",
    id: "oyoung.mobile",
    smartSelect: {
        closeOnSelect: true
    },
    photoBrowser: {
        theme: 'dark'
    },
    theme: "ios",
    clicks: {
        externalLinks: '.f7-ignore',
    },
};

function App() {
    const loc = useLocation();
    const history = useHistory();
    const { state, dispatch } = useContext(store);

    useEffect(() => {
        _initApp(dispatch, history);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        _initEvents(history);
    }, [history]);

    global.window.onNotification = function (payload) {
        _onNotification(payload, loc, history);
    };

    global.window.onerror = function () {
        let url = state.user ? '/customer/api/logException' : '/api/logException';
        if (global.location.origin.indexOf('localhost') < 0)
            fetchPost(url, { url: global.location.href, exception: arguments[4].stack });
    };

    return (
        <F7App { ...f7params }>
            <TransitionGroup>
                <CSSTransition
                    key={loc.key}
                    classNames="page"
                    timeout={300}
                >
                    <Switch location={loc}>
                        <Route path="/home"><HomePage /></Route>
                        <Route path="/about"><AboutPage /></Route>
                        <Route path="/qna"><QnaPage /></Route>
                        <Route path="/policies"><PoliciesPage /></Route>
                        <Route path="/policy-general"><PolicyGeneralPage /></Route>
                        <Route path="/policy-privacy"><PolicyPrivacyPage /></Route>
                        <Route path="/products" exact><ProductsPage /></Route>
                        <Route path="/products/:id"><ProductDetailPage /></Route>
                        <Route path="/trends" exact><TrendsPage /></Route>
                        <Route path="/trends/:id"><TrendReadPage /></Route>
                        <Route path="/health"><HealthPage /></Route>
                        <Route path="/health1"><Health1Page /></Route>
                        <Route path="/health2"><Health2Page /></Route>
                        <Route path="/health3"><Health3Page /></Route>
                        <Route path="/health4"><Health4Page /></Route>
                        <Route path="/doctors" exact><DoctorsPage /></Route>
                        <Route path="/doctors/:id"><DoctorDetailPage /></Route>
                        <Route path="/clinics" exact><ClinicsPage /></Route>
                        <Route path="/reviews" exact><ReviewsPage /></Route>
                        <Route path="/reviews/:id"><ReviewReadPage /></Route>
                        <PrivateRoute path="/review-write"><ReviewWritePage /></PrivateRoute>
                        <Route path="/partners" exact><PartnersPage /></Route>
                        <Route path="/partners/:id"><PartnerDetailPage /></Route>
                        <Route path="/sign"><SignPage /></Route>
                        <Route path="/search"><SearchPage /></Route>
                        <PrivateRoute path="/profile"><ProfilePage /></PrivateRoute>
                        <PrivateRoute path="/password"><PasswordPage /></PrivateRoute>
                        <PrivateRoute path="/talks"><TalksPage /></PrivateRoute>
                        <PrivateRoute path="/talk-chat"><TalkChatPage /></PrivateRoute>
                        <PrivateRoute path="/talk-request"><TalkRequestPage /></PrivateRoute>
                        <PrivateRoute path="/consultation-booking"><ConsultationBookingPage /></PrivateRoute>
                        <PrivateRoute path="/consultations" exact><ConsultationListPage /></PrivateRoute>
                        <PrivateRoute path="/consultations/:id"><ConsultationDetailPage /></PrivateRoute>
                        <PrivateRoute path="/consultation-room/:id"><ConsultationRoomPage /></PrivateRoute>
                        <PrivateRoute path="/my-health"><MyHealthPage /></PrivateRoute>
                        <PrivateRoute path="/my-health-schedule"><MyHealthSchedulePage /></PrivateRoute>
                        <PrivateRoute path="/my-health-vital"><MyHealthVitalPage /></PrivateRoute>
                        <PrivateRoute path="/my-health-suggestions"><MyHealthSuggestionsPage /></PrivateRoute>
                        <PrivateRoute path="/my-health-checkups"><MyHealthCheckupsPage /></PrivateRoute>
                        <PrivateRoute path="/my-health-cells"><MyHealthCellsPage /></PrivateRoute>
                        <Route path="*">
                            <Redirect to="/home" />
                        </Route>
                    </Switch>
                </CSSTransition>

            </TransitionGroup>
        </F7App>
    );
}

export default App;

function _initApp(dispatch, history) {
    // storage('isNotAppFirst').then(runBefore => {
    //     if (!runBefore) {
    //         let popup = global.app.popup.open('.access-authority-popup');
    //         popup.on('close', () => this.handleLogin());
    //     } else {
    //         // 자동 로그인 처리
    //         this.handleLogin();
    //     }
    // });

    handleLogin(null, dispatch, history);
}

function _initEvents(history) {
    // 뒤로 가기 키 처리
    global.window.onBackPressed = () => {
        if ($("#divMenuPanel").hasClass("panel-active")) {
            // panel.close();
            return false;
        } else if ($(".modal-in").length > 0) {
            // dialog.close();
            // popup.close();
            // actions.close();
            // smartSelect.close();
            // sheet.close();
            return false;
            // } else if (views.main.router.currentRoute.path === "/home") {
            // dialog.confirm(L('Are you sure you want to exit?'), function() {
            //     if (window.OyoungApp)
            //         window.OyoungApp.exitApplication();
            // });
        } else {
            history.goBack();
        }
    };
}

let _notificationAudio = new Audio(_notificationAudioFile);

function _onNotification(payload, loc, history) {
    try {
        _notificationAudio.pause();
        _notificationAudio.currentTime = 0;
        _notificationAudio.play();
    } catch (e) {
        console.log('알림음 재생 중 오류', e);
    }

    console.log('푸시 알림 받음');
    if (payload['oyoung.type'] === 'new-message') {
        let message = payload['oyoung.message'];
        if (!message) {
            console.log('푸시 알림 데이터에 oyoung.message 항목이 없음');
            return;
        }

        message = JSON.parse(message);
        let page = loc.pathname;

        if (page === "/talks") {
            global.loadTalks();
        } else {
            const params = f7.utils.parseUrlQuery(loc.search);
            if (page === "/talk-chat" && params.id === message.talk.id) {
                global.loadTalkChat(params.id);
            } else {
                let text;
                try {
                    text = JSON.parse(message.i18nMessage);
                    text = $('<div>' + (text[getLang()] || message.message) + '</div>').text();
                } catch (e) {
                    console.log(e);
                }

                f7.notification.create({
                    icon: '<i class="material-icons">mail_outline</i>',
                    title: L('Notification'),
                    subtitle: L("New message from {}", getLang() === 'ko' ? message.talkerName : message.talkerEnglishName),
                    text: text.length < 120 ? text : text.slice(0, 118) + '...',
                    closeTimeout: 10000,
                    closeButton: true,
                    closeOnClick: true,
                    on: {
                        click: () => {
                            history.push('/talk-chat?id=' + message.talk.id);
                        }
                    },
                }).open();
            }
        }
    }
}
