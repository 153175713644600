import { f7 } from "framework7-react";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { callOutBrowser } from "../nativeBridge";
import { store } from "../Store";
import { confirm, fetchGet, fetchPost, formatRelativeTime, getLang, handleFetchError, L } from "../utils";

export default function ConsultationDetailPage() {
    const { state } = useContext(store);
    const history = useHistory();
    const { id } = useParams();
    const [consultation, setConsultation] = useState();

    useEffect(() => {
        const url = id ? '/customer/api/consultations/' + id : '/customer/lastConsultation.do'
        fetchGet(url).then(result => setConsultation(result.consultation || result));

    }, [id]);

    const now = new Date();
    const bookDate = consultation ? new Date(consultation.bookDate) : null;

    let statusDesc;
    if (consultation && consultation.status === 'CONFIRMED') {
        if (now < bookDate)
            statusDesc = (
                <span> ({L('Starts {}', <time key="1" dateTime={consultation.bookDate}>{formatRelativeTime(bookDate)}</time>)})</span>
            );
        else if (bookDate.getTime() + 120 * 60 * 1000)
            statusDesc = ' (' + L('Overdue') + ')';
    }

    const cancelBooking = () => {
        confirm(L('Do you want to cancel this consultation?'), () => {
            let data = new FormData();
            data.append('id', consultation.id);

            fetchPost('/customer/cancelConsultation.do', data).then(result => {
                if (result.errors)
                    throw result;

                if (result.consultation)
                    setConsultation(result.consultation);

                history.push('/consultations');
            }).catch(handleFetchError);
        });
    };

    const openRoom = e => {
        if (f7.device.ios) {
            const c = consultation;
            callOutBrowser(global.location.origin + '/guest/videoTalk.html?id='
                + c.id + '&uuid=' + c.uuid + '&key=' + c.guestKey
                + '&identity=' + state.user.id + '&as=customer', e);
        } else {
            history.push('/consultation-room/' + consultation.id);
        }
    }

    return (
        <div id="container">
            <Header />

            <div id="consult_detail" className="sub mypage">

                <Navbar title={L('Reservation Detail')} />

                <div className="consult_dt_inf">
                    {consultation && (
                        <div className="wrapper">
                            <p className="consult_dt_date">
                                {bookDate.toLocaleString(getLang(), {
                                    year:'numeric', month: getLang() === 'ko' ? 'numeric' : 'short', day: 'numeric',
                                    hour:'numeric', minute:'2-digit', weekday: 'short', timeZoneName: 'long'
                                })}
                            </p>
                            <div className="consult_rsv_tb_wrap">
                                <table className="consult_rsv_tb">
                                    <tbody>
                                        <tr>
                                            <th><p className="subtitle_st01 dot">{L('Status')}</p></th>
                                            <td>{L(consultation.status)} {statusDesc}</td>
                                        </tr>
                                        <tr>
                                            <th><p className="subtitle_st01 dot">{L('Category')}</p></th>
                                            <td>{L(consultation.category)}</td>
                                        </tr>
                                        <tr>
                                            <th><p className="subtitle_st01 dot">{L('Consultant')}</p></th>
                                            <td>
                                                <Link to={`/doctors/${consultation.talk.consultantId}`} className="f7-ignore">
                                                    {consultation.talk && (getLang() === 'ko'
                                                        ? consultation.talk.consultantName
                                                        : consultation.talk.consultantEnglishName
                                                    )}
                                                </Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {consultation.status === 'BOOKED' && now < bookDate && (
                                <a
                                    href="#cancel"
                                    className="btn03 consult_dt_btn"
                                    onClick={cancelBooking}
                                >
                                    {L('Cancel Booking')}
                                </a>
                            )}

                            {consultation.status === 'CONFIRMED' && now.getTime() + 15 * 60 * 1000 > bookDate.getTime()
                                && now.getTime() < bookDate.getTime() + 120 * 60 * 1000 && (
                                <a
                                    href="#open"
                                    className="btn03 consult_dt_btn"
                                    onClick={openRoom}
                                >
                                    {L('Enter Room')}
                                </a>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <Footer index="3" />
        </div>
    );
}
