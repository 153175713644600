import { useEffect, useState } from 'react';
import $ from 'jquery';
// import { f7 } from 'framework7-react';

import search_icon02 from '../images/search_icon02.png';
import talk_chat_msg_receive_img from '../images/talk_chat_msg_receive_img.png';
// import plus_icon from '../images/plus_icon.png';
// import quick_search_icon from '../images/quick_search_icon.png';

import Header from "../components/Header";
import Footer from "../components/Footer";
import { fetchGet, formatRelativeTime, getLang, L } from "../utils";
import { Link } from 'react-router-dom';

export default function TalksPage() {
    const [messages, setMessages] = useState([]);
    const [keyword, setKeyword] = useState();
    // const [fabActive, setFabActive] = useState();

    global.loadTalks = () => {
        fetchGet('/customer/api/talkConsultants?customer=').then((result) => {
            result.sort((a, b) => {
                if (a.talk.consultantType === 'manager')
                    return -1;
                if (b.talk.consultantType === 'manager')
                    return 1;
                if (a.createdAt > b.createdAt)
                    return -1;
                if (a.createdAt < b.createdAt)
                    return 1;
                return 0;
            });
    
            setMessages(result);
        });
    };

    useEffect(() => {
        global.loadTalks();

        // storage('openTalkHelp').then((val) => {
        //     if (val) return;

        //     let popover = f7.popover.create({el: '#talkHelpPopover', targetEl: '.quick_btn.quick_btn01'});
        //     popover.open(false);
        //     let targetBox = $('.quick_btn.quick_btn01')[0].getBoundingClientRect();
        //     $('#talkHelpPopover').css({
        //         top: (targetBox.top - $('#talkHelpPopover')[0].getBoundingClientRect().height) + 'px'
        //     }).on('touchstart', () => popover.close(false));
        //     storage('openTalkHelp', true);
        // });

    }, []);

    return (
        <div id="container" className="gray">
            <Header />

            <div id="talk_list" className="sub mypage">

                <div className="search_box talk_list_sch_box">
                    <div className="wrapper">
                        <form action="" name="talk_sch_f" id="talk_sch_f">
                            <div className="search_inner">
                                <input
                                    type="text"
                                    className="inpt01 inpt_full search_inpt"
                                    placeholder={L('Search for consultant')}
                                    value={keyword || ''}
                                    onChange={(e) => setKeyword(e.target.value)}
                                />
                                <button className="search_btn talk_sch_btn"><img src={search_icon02} alt="search" /></button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="talk_list">
                    <div className="wrapper talk_list_wrap">
                        {messages.map((m) => {
                            let msg = m.i18nMessage ? JSON.parse(m.i18nMessage) : {};
                            msg = msg[getLang()] || msg.en || m.message;
                            let excerpt = msg ? $('<div>' + msg + '</div>').text() : '';
                            if (excerpt.length > 70)
                                excerpt = excerpt.slice(0, 70) + '...';
                            const match = !keyword || m.talk.consultantEnglishName.toLowerCase().indexOf(keyword) >= 0
                                || (msg && msg.toLowerCase().indexOf(keyword) >= 0);
                            let photo;
                            if (m.talk.consultantType === 'manager')
                                photo = '/upload/admin/ask-oyoung.png';
                            else if (m.talk.consultantPicture)
                                photo = '/upload/' + (m.talk.consultantPicture.thumbnail || m.talk.consultantPicture.path);
                            else
                                photo = talk_chat_msg_receive_img;

                            return (
                                <div key={m.id} className="i-col-0 talk_card-a" style={{display: match ? '' : 'none'}}>
                                    <div className="profile_img">
                                        <img src={photo} alt={m.talk.consultantEnglishName} />
                                    </div>
                                    <div className="talk_inf">
                                        <div className="talk_inf_top cf">
                                            <Link to={`/talk-chat?id=${m.talk.id}`} className="talk_subj f7-ignore">
                                                {m.talk.consultantType !== 'manager'
                                                    ? (getLang() === 'ko' || !m.talk.consultantEnglishName ? m.talk.consultantName : m.talk.consultantEnglishName)
                                                    : 'O\'YOUNG'}   
                                                {m.talk.closed ? <span> - {L('closed')}</span> : ''}
                                            </Link>
                                            {m.unread > 0 && <span className="talk_num">{m.unread}</span>}
                                        </div>
                                        <p className="talk_list_date">{formatRelativeTime(m.createdAt)}</p>
                                        <div className="talk_list_expl">{excerpt}</div>
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                </div>

                <div id="talkHelpPopover" className="popover">
                    <div className="popover-angle"></div>

                    <div className="popover-inner">
                        <div style={{margin: '15px'}} dangerouslySetInnerHTML={{__html: L('talk-help-popover-text')}}/>
                    </div>
                </div>

                {/* <div className={`quick_wrap ${fabActive ? 'active' : ''}`}>
                    <button type="button" className="quick_btn quick_btn01" onClick={() => setFabActive(!fabActive)}>
                        <img src={plus_icon} alt="plus button" />
                    </button>
                    <div className="quick_hid_menu quick_hid_menu01">
                        <div className="quick_hid_inner">
                            <a href="" className="quick_hid_btn quick_book">
                                <img src={quick_search_icon} alt="search icon" />
                            </a>
                            Search
                        </div>
                    </div>
                    <div className="quick_dark_back"></div>
                </div> */}
            </div>

            <Footer index="2" />

        </div>
    );

    /* <script>
        function answerPrice(){
          var $chatArea = $(".talk_chat_msg_cont"),
              answerHtml = '<div className="talk_chat_msg_receive answer"><div className="talk_chat_msg_receive_img"><img src="../../images/talk_chat_msg_receive_img.png" alt=""></div><div className="talk_chat_msg_receive_text">견적문의를 해주셔서 감사합니다. 빠른 시일 내에 답변드리도록 하겠습니다.</div><span className="talk_chat_msg_receive_time">AM 11:00</span></div>';
          $chatArea.append(answerHtml);
        };
        </script> */

}
