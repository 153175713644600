import { useContext } from "react";
import { Link } from "react-router-dom";

import heart_icon01 from '../images/heart_icon01.png';
import heart_icon01_ov from '../images/heart_icon01_ov.png';

import { store } from "../Store";
import { getLang, L } from "../utils";

export default function DoctorCard({doctor}) {
    let { state } = useContext(store);

    let categories = doctor.categories || (doctor.field ? [doctor.field] : []);

    return (
        <div className={`i-col-0 doctor_card-b ${doctor.recommended ? 'like' : ''}`}>
            <div className="doctor_img">
                <Link to={`/doctors/${doctor.id}`} className="f7-ignore">
                    <img data-src={`/upload/${doctor.photo.thumbnail || doctor.photo.path}`} alt={doctor.photo.name} />
                </Link>
            </div>
            <div className="doctor_inf">
                <div className="doctor_name_box cf">
                    <sub className="doctor_job">{L(doctor.field || doctor.userType)}</sub>
                    <Link to={`/doctors/${doctor.id}`} className="doctor_name f7-ignore">
                        {getLang() === 'ko' ? doctor.userName : doctor.englishName}
                    </Link>
                    <div className="doctor_like">
                        <button className="doctor_like_btn">
                            <img
                                src={
                                    Array.isArray(state.consultantLikes) &&
                                        state.consultantLikes.find(l => l.id === doctor.id)
                                        ? heart_icon01_ov : heart_icon01
                                }
                                alt="heart"
                            />
                        </button>
                        <span className="doctor_like_num">{doctor.likes}</span>
                    </div>
                </div>
                <ul className="i-col-0 doctor_keyword">
                    {doctor.recommended && (
                        <li className="color">
                            <span className="category">{L('Recommended')}</span>
                        </li>
                    )}
                    {categories.map((c) => (
                        <li key={c}><span className="category">{L(c)}</span></li>
                    ))}
                </ul>
            </div>
        </div>
    );
}