import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import CategoriesBar from "../components/CategoriesBar";
import SortKeysBar, { ORDER_KEYS } from "../components/SortKeysBar";
import { fetchGet, L } from "../utils";
import PartnerList from "../components/PartnerList";
import { store } from "../Store";

export default function PartnersPage() {
    const { state, dispatch } = useContext(store);
    const [category, setCategory] = useState();
    const [orderKey, setOrderKey] = useState(ORDER_KEYS[0]);
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        if (!state.partners.length) {
            fetchGet('/api/partners').then((result) => {
                result.forEach((c) => {
                    c.categories = c.categories ? c.categories.split(';') : [];
                    c.specialties = c.specialties ? c.specialties.split(';') : [];
                });
                dispatch({type: 'SET_PARTNERS', payload: result});
            });
        }
    }, [dispatch, state.partners]);

    useEffect(() => {
        const filtered = state.partners.filter((c) => (
            (c.partnerType === 'CHECKUP' || c.partnerType === 'COLDCHAIN'
                || c.partnerType === 'HOMECARE' || c.partnerType === 'LAB'
                || c.partnerType === 'BUSINESS')
            && (!category || c.categories.indexOf(category) >= 0)
        ));
        filtered.sort(orderKey.sorter);
        setPartners(filtered);
    }, [category, orderKey.sorter, state.partners]);

    return (
        <div id="container" className="gray">

            <Header />

            <div id="partner" className="sub">

                <div className="menu_2dp">
                    <div className="wrapper">
                        <ul className="i-col-2 menu_2dp_ul">
                            <li><Link to="/trends" className="f7-ignore">{L('Trends')}</Link></li>
                            <li className="active"><Link to="/partners" className="f7-ignore">{L('Partners')}</Link></li>
                        </ul>
                    </div>
                </div>

                <CategoriesBar category={category} setCategory={setCategory} />

                <div className="clinic_list">

                    <SortKeysBar orderKey={orderKey} setOrderKey={setOrderKey} />

                    <div className="clinic_list_wrap">
                        <PartnerList partners={partners} />
                    </div>
                </div>
            </div>

            <Footer index="3" />
        </div>

    );
}
