import { L } from '../utils';

export default function PictureForm({pictures, setPictures}) {
    const arr = pictures ? [...pictures] : [];

    return (
        <ul className="i-col-4 rv_w_file_ul">
            {arr && arr.map((p, idx) => (
                <li key={p.id || p.name}>
                    <div className="filebox preview-image">
                        <div className="upload-display">
                            <div className="upload-thumb-wrap">
                                <img
                                    src={p.thumbnail ? `/upload/${p.thumbnail}` : p.src}
                                    className="upload-thumb"
                                    alt={p.name}
                                />
                            </div>
                        </div>
                        <label />
                        <div className="filebox_delete">
                            <button
                                type="button"
                                onClick={() => {
                                    arr.splice(idx, 1);
                                    setPictures(arr);
                                }}
                            >
                                {L('Remove')}
                            </button>
                        </div>
                    </div>
                </li>
            ))}
            <li>
                <div className="filebox preview-image">
                    <div className="upload-display"><div className="upload-thumb-wrap"></div></div>
                    <label htmlFor="input_file" className="def">
                        <span className="rv_w_file_txt">{L('Add')}</span>
                    </label>
                    <input
                        type="file"
                        multiple
                        id="input_file"
                        className="upload-hidden"
                        onChange={(e) => {
                            for (let i = 0; i < e.target.files.length; ++i) {
                                (function(f) {
                                    const r = new FileReader();
                                    r.readAsDataURL(f);
                                    r.addEventListener('load', () => {
                                        arr.push({
                                            src: r.result,
                                            name: f.name,
                                            file: f,
                                        });

                                        setPictures(arr);
                                    });
                                })(e.target.files[i]);
                            }
                        }}
                    />
                </div>
            </li>
        </ul>
    );
}