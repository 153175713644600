import { L } from "./utils"
const ERROR_MESSAGE = "Sorry, this function is only supported in the mobile app.";

/**
 * 콜백 호출 사용법 
 * ex) getConfigPush('callbackFn');
 * const callbackFn(isEnable) {
 *     isEnable 사용한 로직
 * }
 */


/**
 * native 함수 호출 가능 여부
 */
export const isNative = !!window.OYoung;

/**
 * native 캐시 데이터 삭제
 */
export const clearCaches = () => {
    if(isNative) {
        window.OYoung.NA_ClearCaches();
    }else {
        alert(L(ERROR_MESSAGE));
    }
}

/**
 * 공유하기(기기 내 다른 App에 공유)
 */
export const exeShare = url => {
    if(isNative) {
        window.OYoung.NA_ExeShare(url);
    }else {
        alert(L(ERROR_MESSAGE), () => {
            window.open(url, '_blank');
        });
    }
}

/**
 * push 수신 여부
 * @param isEnable('Y' or 'N')
 */
export const setConfigPush = (isEnable='N') => {
    if(isNative) {
        window.OYoung.NA_SetConfigPush(isEnable);
    } 
}

/**
 * push 수신 여부 확인
 * @param callback 콜백함수
 * @callbackParam configPushCallBack(Y or N)
 */
export const getConfigPush = () => {
    if(isNative) {
        window.OYoung.NA_GetConfigPush('configPushCallBack');
    }
}

// /**
//  * 자동 로그인 설정 여부 앱에 전송
//  * @param isEnable('Y' or 'N')
//  */
// export const setAutoLogin = isEnable => {
//     if(isNative) {
//         window.OYoung.NA_SetAutoLogin(isEnable);
//     }
// }

// /**
//  * 자동 로그인 설정 여부 확인
//  * @param callback 콜백함수
//  * @callbackParam String(Y or N)
//  */
// export const getAutoLogin = () => {
//     if(isNative) {
//         window.OYoung.NA_GetAutoLogin('getAutoLoginCallBack');
//     }
// }

/**
 * 로그인 성공 후 호출
 * @param loginName
 * @param authToken token 값
 */
export const setLoginInfo = (loginName, token) => {
    if(isNative) {
        window.OYoung.NA_SetLoginInfo(loginName, token);
    }
}

/**
 * service 화면 노출 후 호출
 */
export const refreshLayout = () => {
    if(isNative) {
        window.OYoung.NA_RefreshLayout();
    }
}


/**
 * 약관 동의 여부 저장
 * @param isEnable (Y or N)
 */
export const setTerms = isEnable => {
    if(isNative) {
        window.OYoung.NA_SetTerms(isEnable);
    }
}

/**
 * 약관 동의 여부 확인
 * @param callback 콜백함수
 * @callbackParam configTermsCallback(Y or N)
 */
export const getTerms = () => {
    if(isNative) {
        window.OYoung.NA_GetTerms('configTermsCallback');
    }
}

/**
 * 인증 token 확인
 * @param callback 콜백함수
 */
export const getDeviceToken = callback => {
    if(isNative) {
        window._deviceTokenCallback = callback;
        window.OYoung.NA_GetAuthToken('_deviceTokenCallback');
    }
}

/**
 * 결제 버튼 클릭 시 별도 웹 뷰 호출
 * @param url 결제를 위한 full url
 */
export const openNewWindow = url => {
    if(isNative) {
        window.OYoung.NA_CallPayment(url);
    }else {
        window.open(url, '_blank');
    }
}

/**
 * 결제 완료 후 웹뷰 닫기
 */
export const closeWindow = () => {
    if(isNative) {
        window.OYoung.NA_SetCompletePay();
    }
}

/**
 * 디바이스이름, os이름 및 버전, 앱버전
 */
export const getAppInfo = () => {
    if(isNative) {
        window.OYoung.NA_GetAppInfo('configAppInfoCallback');
    }
}

/**
 * 외부 브라우저를 통한 pdf 뷰
 * @param url
 */
export const callOutBrowser = (url, e) => {
    e.preventDefault();
    
    if(isNative) {
        window.OYoung.NA_CallOutBrowser(url);
    } else {
        window.open(url);
    }

    return false;
}

/**
 * 쿠키 데이터 저장
 * @param key 
 * @param value 
 * @returns {Promise}
 */
export const setCookie = (key, value) => {
    return new Promise(resolve => {
        // TODO ios 소스 테스트 플라이트에 올리게 되면 아이폰일 경우도 콜백 메서드 써줘야 함
        if(isNative) {
            window._setCookieCallback = resolve;

            const os = navigator.userAgent.toLocaleLowerCase();
            const isAndroid = os.indexOf("android") >= 0;

            if(isAndroid) {
                window.OYoung.NA_SetCookieValue_android('_setCookieCallback', key, value);
            } else {
                window.OYoung.NA_SetCookieValue(key, value);
                resolve();
                // window.OYoung.NA_SetCookieValue('_setCookieCallback', key, value);
            }
        } else {
            document.cookie = key + '=' + encodeURIComponent(value);
            resolve();
        }
    });
}

/**
 * 쿠키 데이터 가져오기
 * @param key 
 * @returns {Promise}
 */
export const getCookie = (key) => {
    return new Promise(resolve => {
        if(isNative) {
            window._getCookieCallback = (key, value) => resolve(value);
            window.OYoung.NA_GetCookieValue('_getCookieCallback', key);
        } else {
            let re = new RegExp('\\b' + key + '=(.+?)(\\b|;)');
            resolve(document.cookie.search(re) >= 0 ? RegExp.$1 : null);
        }
    });
}

/**
 * IOS 설정 열기
 * @param key 
 */
export const showAppConfig = () => {
    if(isNative) {
        window.OYoung.NA_ShowAppConfig();
    }
}
