import { useEffect } from "react";
import $ from 'jquery';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { fetchGet, getLang, L } from "../utils";

export default function PolicyGeneralPage() {
    useEffect(() => {
        fetchGet('/api/contents/TERMS_OF_USE').then(result => {
            let content = JSON.parse(result.i18nContent);
            content = $(content[getLang()] || content.en);

            let arr = [];
            let group;
        
            $(content).each((_idx, el) => {
                if (el.tagName === 'H1' || el.tagName === 'H2' || el.tagName === 'H3'
                    || el.tagName === 'H4' || el.tagName === 'H5' || el.tagName === 'H6') {
                    group = {
                        title: el,
                        paragraphs: []
                    };
                    arr.push(group);
                } else if (group) {
                    group.paragraphs.push(el);
                }
            });
        
            let $ul = $('<ul class="terms_cont_ul"></ul>');
            arr.forEach(group => {
                let $li = $(`<li class="terms_cont_inner">
                    <div class="terms_cont_title"></div>
                    <div class="terms_cont_text"></div>
                </li>`);
                $li.find('.terms_cont_title').append(group.title.innerText);
                group.paragraphs.forEach(p => $li.find('.terms_cont_text').append(p));
                $ul.append($li);
            });

            $ul.find('.terms_cont_title').on('click', function() {
                $(this).parent().toggleClass('active').children('.terms_cont_text')
                    .slideToggle();
            });

            $('#terms .terms_cont').empty().append($ul);
        });
    }, []);

    return (
        <div id="container">
            <Header />

            <div id="terms" className="sub">
                <div className="terms_wrap">
                    <div className="wrapper">

                        <p className="terms_title">{L('General Terms and Conditions')}</p>
                        <div className="terms_cont" />
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}