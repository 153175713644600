import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReactGA from "react-ga";

export default function LocationHandler() {
    const history = useHistory();
    const loc = useLocation();

    // Google Analytics
    useEffect(() => ReactGA.initialize("UA-13042476-10"), []);

    useEffect(() => {
        if (history.action === 'PUSH')
            setTimeout(() => window.scrollTo(0, 0), 0);

        if (global.location.hostname !== 'localhost') ReactGA.pageview(loc.pathname);
    }, [loc, history.action]);

    return null;
}
