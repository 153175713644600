import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import plus_icon from '../images/plus_icon.png';
import quick_book_icon from '../images/quick_book_icon.png';
import quick_talk_icon from '../images/quick_talk_icon.png';
import { fetchGet, fetchPost, handleFetchError, L } from "../utils";

export default function TalkButton({consultant}) {
    const history = useHistory();
    const [showButton, setShowButton] = useState(false);

    const talkRequest = () => {
        fetchGet('/customer/api/talkConsultants?customer=').then((result) => {
            const prevMessage = result.find(
                m => m.talk.consultantId === consultant.id || (
                    m.talk.consultantType === 'manager' && consultant.userType === 'manager'
                )
            );
    
            if (prevMessage)  { // 과거에 대화한 적이 있으면 해당 대화방 열기
                history.push('/talk-chat?id=' + prevMessage.talk.id);
            } else if (consultant.userType === 'partner'
                || consultant.userType === 'partner-member'
                || consultant.userType === 'coordinator') {
                history.push('/talk-request?consultant=' + consultant.id);
            } else {
                const d = new FormData();
                d.append('partner', consultant.id);
    
                return fetchPost('/customer/talkStartRandom.do', d)
                    .then((talk) => history.push('/talk-chat?id=' + talk.id))
                    .catch(handleFetchError);
            }
        });
    };

    return (
        <div className={`quick_wrap ${showButton ? 'active' : ''}`}>
            <button className="quick_btn quick_btn01" onClick={() => setShowButton(!showButton)}><img src={plus_icon} alt="plus_icon" /></button>
            <div className="quick_hid_menu">
                {consultant.recommended && (
                    <Link to={`/consultation-booking?consultant=${consultant.id}`} className="quick_hid_inner f7-ignore">
                        <div className="quick_hid_btn quick_book">
                            <img src={quick_book_icon} alt="quick_book_icon" />
                        </div>
                        {L('Book')}
                    </Link>
                )}
                <a href="#talkRequest" className="quick_hid_inner" onClick={talkRequest}>
                    <div className="quick_hid_btn quick_talk">
                        <img src={quick_talk_icon} alt="quick_talk_icon" />
                    </div>
                    {L('Talk')}
                </a>
            </div>
            <div className="quick_dark_back"></div>
        </div>
    );
}