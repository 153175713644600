import { useHistory } from 'react-router';
import arrow_l_icon01 from '../images/arrow_l_icon01.png';

export default function Navbar({ title }) {
    const history = useHistory();

    return (
        <div className="detail_nav">
            <div className="wrapper">
                <div className="detail_nav_inner">
                    <a
                        href="#back"
                        className="detail_nav_backBtn"
                        onClick={() => history.goBack()}
                    >
                        <img src={arrow_l_icon01} alt="Back" />
                    </a>
                    <p className="detail_nav_tit">{title}</p>
                </div>
            </div>
        </div>
    );
}
