import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from 'jquery';

import Header from "../components/Header";
import CategoriesBar from "../components/CategoriesBar";
import SortKeysBar, { ORDER_KEYS } from "../components/SortKeysBar";
import Footer from "../components/Footer";
import { getLang, L, loadLazyImages, loadLazyImagesOnScroll } from "../utils";
import LikeButton from "../components/LikeButton";
import { handleTrendLike } from "../Services";
import { store } from "../Store";

export default function TrendsPage() {
    const { state, dispatch } = useContext(store);
    const [category, setCategory] = useState();
    const [orderKey, setOrderKey] = useState(ORDER_KEYS[0]);
    const [numVisible, setNumVisible] = useState(5);
    const [trends, setTrends] = useState([]);

    useEffect(() => {
        setNumVisible(5);

        let filtered;
        if (state.trends) {
            filtered = state.trends.filter((r) => !category || r.category === category);
            filtered.sort(orderKey.sorter);
        }
        setTrends(filtered || []);
    }, [state.trends, category, orderKey]);

    useEffect(() => {
        let h;
        const _updateVisible = () => {
            clearTimeout(h);
            h = setTimeout(() => {
                const scrollTop = document.documentElement.scrollTop;
                const viewHeight = $(window).innerHeight();
                const docHeight = document.documentElement.offsetHeight;

                if (scrollTop + viewHeight >= docHeight - 100 && numVisible < trends.length) {
                    setNumVisible(numVisible + 5);
                }
            }, 50);
        };

        $(window).on('scroll', _updateVisible);

        loadLazyImages('.trend_img img');
        const clear1 = loadLazyImagesOnScroll('.trend_img img', document);

        return () => {
            clear1();
            $(window).off('scroll', _updateVisible)
        };

    }, [trends, numVisible]);

    return (
        <div id="container" className="gray">
            <Header />

            <div id="trend" className="sub">

                <div className="menu_2dp">
                    <div className="wrapper">
                        <ul className="i-col-2 menu_2dp_ul">
                            <li className="active"><Link to="/trends" className="f7-ignore">{L('Trends')}</Link></li>
                            <li><Link to="/partners" className="f7-ignore">{L('Partners')}</Link></li>
                        </ul>
                    </div>
                </div>

                <CategoriesBar category={category} setCategory={setCategory} />

                <div className="trend_list">

                    <SortKeysBar orderKey={orderKey} setOrderKey={setOrderKey} />

                    <div className="trend_list_wrap">
                        {state.trends.length
                            ? (
                                trends.length
                                ? trends.filter((_t, idx) => idx < numVisible).map((t) => {
                                    let title = t.titles[getLang()] || t.titles.en;
                                    // let content = JSON.parse(t.i18nContent || '{}');
                                    // content = content[getLang()] || content.en;

                                    return (
                                        <div key={t.id} className="trend_list_box">
                                            <div className="wrapper">
                                                <div className="trend_card-b">
                                                    <Link to={`/trends/${t.id}`} className="trend_title f7-ignore">{title}</Link>
                                                    <Link to={`/trends/${t.id}`} className="trend_img f7-ignore">
                                                        <img data-src={`/upload/${t.banner.thumbnail}`} alt={t.banner.name} />
                                                    </Link>
                                                    <ul className="i-col-0 keyword-b">
                                                        <li><a href="#category">{L(t.category)}</a></li>
                                                    </ul>
                                                    {/* <div className="trend_expl"></div> */}
                                                    <ul className="i-col-1 doctor_dt_btn">
                                                        <li>
                                                            <LikeButton
                                                                onClickLike={() => handleTrendLike(t, state, dispatch)}
                                                                target={t}
                                                                targetLike={state.trendLikes}
                                                                className="doctor_dt_like_btn"
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                                : <div style={{ margin: '1em', textAlign: 'center' }}>{L('Such empty!')}</div>
                            )
                            : ''}
                    </div>
                </div>
            </div>

            <Footer index="3" />

        </div>
    );
}
