import { useEffect, useState } from "react";
import { f7 } from "framework7-react";
import { useLocation } from "react-router-dom";

import introduce_mission_img from '../images/introduce_mission_img.jpg';
import googleplay_icon from '../images/googleplay_icon.png';
import appstore_icon from '../images/appstore_icon.png';
import facebook_icon from '../images/facebook_icon.png';
import youtube_icon from '../images/youtube_icon.png';
import instagram_icon from '../images/instagram_icon.png';

import Header from '../components/Header';
import Footer from '../components/Footer';
import { fetchGet, getLang, L } from "../utils";
import { callOutBrowser } from "../nativeBridge";
import { APP_STORE_URL, FACEBOOK_URL, GOOGLE_PLAY_URL, INSTAGRAM_URL, YOUTUBE_URL } from "../const";

export default function AboutPage() {
    const loc = useLocation();
    const [tab, setTab] = useState();
    const [contents, setContents] = useState({});

    useEffect(() => {
        fetchGet('/api/contents?keys=OYOUNG_INTRO,OYOUNG_WHY,OYOUNG_HOW').then((result) => {
            const contents = {};
            result.forEach((c) => {
                contents[c.contentKey] = JSON.parse(c.i18nContent);
            });
            setContents(contents);
        });

        const params = f7.utils.parseUrlQuery(loc.search);
        const tab = params.tab;
        setTab(tab);
    }, [loc.search]);

    return (
        <div id="container">
            <Header />

            <div id="introduce" className="sub">

                <div className="menu_2dp">
                    <div className="wrapper">
                        <ul className="i-col-3 menu_2dp_ul">
                            <li className={tab === '1' || tab === '2' ? '' : 'active'}><a href="/about" onClick={() => setTab('')}>{L('About')}</a></li>
                            <li className={tab === '1' ? 'active' : ''}><a href="/about?tab=1" onClick={() => setTab('1')}>{L('Why')}</a></li>
                            <li className={tab === '2' ? 'active' : ''}><a href="/about?tab=2" onClick={() => setTab('2')}>{L('How')}</a></li>
                        </ul>
                    </div>
                </div>

                {tab !== '1' && tab !== '2' && (
                    <div className="introduce_wrap">
                        <div className="wrapper">
                            <div className="introduce_txtbox01">
                                <div className="introduce_txt01" dangerouslySetInnerHTML={{
                                    __html: contents.OYOUNG_INTRO ? (contents.OYOUNG_INTRO[getLang()] || contents.OYOUNG_INTRO.en) : ''
                                }} />
                            </div>

                            <div className="introduce_mission">
                                <p className="title_st01 dot">Mission</p>
                                <div className="introduce_mission_img"><img src={introduce_mission_img} alt="mission" /></div>
                                <p className="introduce_mission_title">O'YOUNG Beauty Live in Your Life</p>
                                <p className="introduce_mission_text">Help Improve, have Confidence, and Stay Healthy</p>
                            </div>

                            <div className="introduce_app">
                                <ul className="i-col-2 introduce_app_ul">
                                    <li>
                                        <a href="#google" className="introduce_app_inner" onClick={e => callOutBrowser(GOOGLE_PLAY_URL, e)}>
                                            <img src={googleplay_icon} alt="google play" className="introduce_app_googleplay" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#appstore" className="introduce_app_inner" onClick={e => callOutBrowser(APP_STORE_URL, e)}>
                                            <img src={appstore_icon} alt="app store" className="introduce_app_appstore" />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="introduce_sns">
                                <ul className="i-col-3 introduce_sns_ul">
                                    <li>
                                        <a href="#facebook" className="introduce_sns_inner" onClick={e => callOutBrowser(FACEBOOK_URL, e)}>
                                            <img src={facebook_icon} alt="facebook" />
                                            Facebook
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#youtube" className="introduce_sns_inner" onClick={e => callOutBrowser(YOUTUBE_URL, e)}>
                                            <img src={youtube_icon} alt="youtube" />
                                            Youtube
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#instagram" className="introduce_sns_inner" onClick={e => callOutBrowser(INSTAGRAM_URL, e)}>
                                            <img src={instagram_icon} alt="instagram" />
                                            Instagram
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}

                {tab === '1' && (
                    <div className="why_wrap">
                        <div className="wrapper">
                            <p className="title_st01 dot">Why O'YOUNG</p>

                            <div dangerouslySetInnerHTML={{
                                __html: contents.OYOUNG_WHY ? (contents.OYOUNG_WHY[getLang()] || contents.OYOUNG_WHY.en) : ''
                            }} />
                        </div>
                    </div>

                )}

                {tab === '2' && (
                    <div className="how_wrap">
                        <div className="wrapper">
                            <p className="title_st01 dot">How it Works</p>

                            <div dangerouslySetInnerHTML={{
                                __html: contents.OYOUNG_HOW ? (contents.OYOUNG_HOW[getLang()] || contents.OYOUNG_HOW.en) : ''
                            }} />
                        </div>
                    </div>
                )}

            </div>

            <Footer />
        </div>
    );
}
